import React, { useState } from 'react'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import ServiceForm from '../ServiceForm'
import { createService } from '../../../services/api/apiCalls'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { toServiceValues } from '../../../services/utils/helpers/toServiceValues'
import {
  selectPetshopMenuState,
  setMenuState
} from '../../../features/petshop/petshopSlice'

export default function AddServicePage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const menuState = useSelector(selectPetshopMenuState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const handleAddService = async data => {
    const { name, description, steps, serviceDuration, servicePrices } = data
    const serviceInfo = { name, description }
    const serviceSteps = steps.map((step, index) => ({
      name: step.name,
      rankNumber: index + 2
    }))
    const serviceValues = toServiceValues(serviceDuration, servicePrices)

    try {
      setIsLoading(true)
      await createService(authToken, {
        serviceInfo,
        serviceSteps,
        serviceValues
      })
      toast.success('Serviço criado com sucesso!')
      if (!menuState || menuState?.index < 2) {
        dispatch(setMenuState({ menuState: { state: 'agenda', index: 2 } }))
      }

      navigate(`/meus-servicos`)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='add-service-page-container'>
      <div className='add-service-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Adicionar Serviço'
          buttonText='Voltar'
          navigateTo='meus-servicos'
        />
        <div className='add-service-content'>
          <ServiceForm
            formSubmitFunction={data => handleAddService(data)}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
