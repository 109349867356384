import React from 'react'

import './style.css'
import { ReactComponent as AlertIcon } from '../../../../assets/images/icons/exclamation-triangle.svg'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'

export default function WorkerRemoveAccessPopup ({
  workerName,
  closeFunction,
  removeFucntion,
  ...props
}) {
  return (
    <Popup closeFunction={closeFunction} className='remove-access-popup popup'>
      <h3 className='remove-access-popup-title'>
        <strong>Tem certeza</strong> que deseja{' '}
        <strong>remover o accesso</strong> do(a) colaborador(a),{' '}
        <strong>{workerName}</strong>, ao petshop?
      </h3>
      <br />
      <p className='remove-access-popup-content'>
        Ele(a) não poderá accessar nenhum dado do petshop (agendamentos,
        horários de atendimento, etc).
        <br /> <br />
        Todos os atendimentos que estão com ele(a) atualmente só poderão ser
        accessados pelo responsável pelo petshop.
      </p>
      <br />
      <div className='remove-access-buttons-container'>
        <Button
          text='Cancelar'
          classes='primary-button'
          onClick={closeFunction}
        />
        <Button text='Apagar' classes='alert-button'>
          <AlertIcon />
        </Button>
      </div>
    </Popup>
  )
}
