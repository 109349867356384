import React, { useEffect, useMemo, useState } from 'react'
import { bool, object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentToken } from '../../features/auth/authSlice'
import {
  getPetshopConfiguration,
  updatePetshopConfiguration
} from '../../services/api/apiCalls'
import { toast } from 'react-toastify'
import { renderComponentNTimes } from '../../services/utils/helpers/renderComponentNTimes'
import { openWhatsappSupport } from '../../services/utils/helpers/createWhatsappLink'
import { setPetshopConfiguration } from '../../features/petshop/petshopSlice'

import './style.css'
import BooleanCheckbox from '../../components/BooleanCheckbox'
import MenuHeader from '../../components/MenuHeader'
import Input from '../../components/Input'
import { normalizePercentage } from '../../services/utils/masks'
import Button from '../../components/Button'
import FeesPopup from '../../components/FeesPopup'
import SkeletonText from '../../skeletonComponents/Text'
import SkeletonButton from '../../skeletonComponents/Button'
import ConfirmationPopup from '../../components/ConfirmationPopup'

const validationSchema = object({
  paymentThroughApp: bool()
    .required()
    .default(false),
  paymentOutsideApp: bool()
    .required()
    .default(false),
  cancellationFee: string()
    .required('Informe a taxa de cancelamento.')
    .default('0%')
})

export default function GeneralSettingsPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const dispatch = useDispatch()

  const [settings, setSettings] = useState()
  const [showFeesPopup, setShowFeesPopup] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [isPageLoading, setIsPageLoading] = useState(true)

  const [showContactTeamPopup, setShowContactTeamPopup] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return settings
      }, [settings])
    }
  })

  useEffect(() => {
    async function getConfiguration () {
      if (!authToken) return

      setIsPageLoading(true)

      try {
        const response = await getPetshopConfiguration(authToken)
        const {
          cancellationFee,
          paymentOutsideApp,
          paymentThroughApp
        } = response.data
        setSettings({
          cancellationFee: String(cancellationFee),
          paymentOutsideApp: paymentOutsideApp ? true : false,
          paymentThroughApp: paymentThroughApp ? true : false
        })
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getConfiguration()
  }, [authToken])

  //does not update cancellation fee due to hardcoded data
  useEffect(() => {
    reset({ ...settings })
  }, [reset, settings])

  const cancellationFee = watch('cancellationFee')

  useEffect(() => {
    setValue('cancellationFee', normalizePercentage(cancellationFee))
  }, [cancellationFee, setValue])

  const onBackspace = event => {
    if (event.key === 'Backspace') {
      let value = event.target.value
      value = value.replace(/[\D]/g, '').replace(/.$/, '')
      setValue('cancellationFee', normalizePercentage(value))
    }
  }

  const saveEditions = async data => {
    setIsLoading(true)

    try {
      const { cancellationFee, paymentOutsideApp, paymentThroughApp } = data
      const configuration = {
        paymentOutsideApp,
        paymentThroughApp,
        cancellationFee: parseInt(cancellationFee.replace(/[\D]/g, ''))
      }
      const response = await updatePetshopConfiguration(
        authToken,
        configuration
      )
      dispatch(setPetshopConfiguration(response.data))
      toast.success('Configurações atualizadas com sucesso!')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderContactTeamPopup = () => {
    return (
      <ConfirmationPopup
        closeFunction={() => setShowContactTeamPopup(false)}
        defaultButtons={false}
      >
        <div className='change-popup-title'>
          <p className='exit-popup-title'>
            Deseja permitir o pagamento pelo App?
          </p>
          <span>
            Clique no botão abaixo para contatar nossa equipe via Whatsapp para
            que possamos configurar seu app para receber pagamentos dos seus
            clientes!
          </span>
        </div>
        <div className='change-page-popup-buttons exit-popup-buttons'>
          <Button
            classes='alert-button btn-with-icon'
            text='Cancelar'
            onClick={() => setShowContactTeamPopup(false)}
          />
          <Button
            classes='primary-button btn-sm green-cancel-button'
            text='Contatar equipe'
            onClick={() => openWhatsappSupport()}
          />
        </div>
      </ConfirmationPopup>
    )
  }

  const generalSettingsForm = () => (
    <form
      className='general-settings-content'
      onSubmit={handleSubmit(saveEditions)}
    >
      <div className='general-settings-input-container'>
        <label className='general-settings-label'>
          Permitir pagamento pelo App{' '}
          <span>
            <u onClick={() => setShowFeesPopup(true)}>(ver taxas)</u> - Os seus
            clientes poderão agendar e realizar o pagamento direto pelo app.
          </span>
        </label>
        <BooleanCheckbox
          id='general-settings-app-payment'
          className='general-settings-checkbox'
          firstOption='Não'
          secondOption='Sim'
          secondOptionAction={() => setShowContactTeamPopup(true)}
          defaultValue={settings?.paymentThroughApp}
          setValue={setValue}
          field={'paymentThroughApp'}
        />
      </div>
      <div className='general-settings-input-container'>
        <label className='general-settings-label'>
          Permitir agendamento pelo App sem precisar pagar -{' '}
          <span>
            Os seus clientes podem agendar e pagar na hora do serviço ou por
            outro meio que não o próprio aplicativo. As taxas de cancelamento
            não são aplicáveis nese caso.
          </span>
        </label>
        <BooleanCheckbox
          id='general-settings-app-scheduling'
          className='general-settings-checkbox'
          firstOption='Não'
          secondOption='Sim'
          defaultValue={settings?.paymentOutsideApp}
          setValue={setValue}
          field={'paymentOutsideApp'}
        />
      </div>
      <div className='general-settings-input-container'>
        <label className='general-settings-label'>
          Taxa de cancelamento -{' '}
          <span>
            {' '}
            em percentual (%). Os seus clientes deverão pagar essa taxa do
            serviço caso tenham pago pelo app e queiram cancelar. Você pode
            deixar em branco para que o cancelamento seja gratuito. Só é
            aplicável se o "pagamento pelo App" estiver ativo.
          </span>
        </label>
        <Input
          name='general-settings-cancellation-fee'
          type='text'
          placeholder='0%'
          onKeyUp={e => onBackspace(e)}
          {...register('cancellationFee')}
        />
        <span
          className={
            errors?.cancellationFee?.message ? 'add-package-field-error' : ''
          }
        >
          {errors?.cancellationFee?.message}
        </span>
      </div>
      <Button
        text='Salvar'
        classes='primary-button general-settings-button'
        isLoading={isLoading}
      />
    </form>
  )

  const renderSkeleton = () => {
    const InputSkeleton = () => (
      <div className='general-settings-input-container'>
        <SkeletonText
          lines={2}
          height='19px'
          rx='8'
          ry='8'
          width='100%'
          style={{ borderRadius: '8px' }}
        />
        <div
          className='general-settings-checkbox boolean-checkbox'
          style={{ maxWidth: '100px' }}
        >
          {renderComponentNTimes(2, SkeletonText, {
            rx: '8',
            ry: '8',
            height: '15px',
            svgWidth: '40px',
            width: '100%',
            style: { borderRadius: '8px' }
          })}
        </div>
      </div>
    )

    return (
      <div className='general-settings-content'>
        {renderComponentNTimes(2, InputSkeleton, {})}
        <div className='general-settings-input-container'>
          <SkeletonText
            width='100%'
            height={'17px'}
            lines={3}
            rx='8'
            ry='8'
            style={{ borderRadius: '8px' }}
          />
          <SkeletonButton height={'35px'} style={{ borderRadius: '30px' }} />
        </div>
        <div className='general-settings-button'>
          <SkeletonButton height={'47px'} style={{ borderRadius: '25px' }} />
        </div>
      </div>
    )
  }

  return (
    <div className='general-settings-page-container'>
      <div className='general-settings-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Configurações Gerais'
          buttonText='Menu'
          navigateTo='menu'
        />
        {isPageLoading ? renderSkeleton() : generalSettingsForm()}
      </div>
      {showFeesPopup && (
        <FeesPopup closeFunction={() => setShowFeesPopup(false)} />
      )}
      {showContactTeamPopup && renderContactTeamPopup()}
    </div>
  )
}
