import React, { useEffect, useState } from 'react'
import { add, format } from 'date-fns'
import { useLocation } from 'react-router-dom'

import './style.css'
import { ReactComponent as BackwardIcon } from '../../assets/images/icons/backward.svg'
import { ReactComponent as ForwardIcon } from '../../assets/images/icons/forward.svg'
import AppointmentDropdown from '../../components/AppointmentDropdown'
import RadioInput from '../../components/RadioInput'
import MenuHeader from '../../components/MenuHeader'
import { dayENToPT } from '../../services/utils/helpers/dayOfTheWeek'
import {
  dateToString,
  fullDateToString
} from '../../services/utils/helpers/dateToString'

import { useSelector } from 'react-redux'
import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../features/auth/authSlice'
import {
  getPetshopNextAppointments,
  getPetshopNextAppointmentsForWorker
} from '../../services/api/apiCalls'
import { weeksBuilder } from '../../services/utils/helpers/weeksBuilder'
import SkeletonText from '../../skeletonComponents/Text'
import { renderComponentNTimes } from '../../services/utils/helpers/renderComponentNTimes'
import SkeletonCard from '../../skeletonComponents/Card'
import { isPetshop } from '../../services/utils/helpers/authHelper'
import { selectWorkerInfo } from '../../features/worker/workerSlice'

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const maxNumberOfWeeks = 9

export default function NextAppointmentsPage (props) {
  const authToken = useSelector(selectCurrentToken)
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const [appointments, setAppointments] = useState()

  const weeks = weeksBuilder(maxNumberOfWeeks)
  const [currentWeek, setCurrentWeek] = useState(0)
  const [selectedDay, setSelectedDay] = useState()
  const [refreshNextAppointments, setRefreshNextAppointments] = useState(true)

  const [isPageLoading, setIsPageLoading] = useState(true)

  const location = useLocation()

  useEffect(() => {
    async function getNextAppointments () {
      if (!authToken) return

      setIsPageLoading(true)
      try {
        const response = isPetshop(auth)
          ? await getPetshopNextAppointments(authToken, {
              allServices: false,
              considerAllWorkers: true
            })
          : await getPetshopNextAppointmentsForWorker(authToken, {
              allServices: false,
              petshopId: workerInfo?.petshops[0]?.id
            })
        const data = response.data
        const nextAppointments = data
          .filter(appointment => {
            const currentDate = new Date()
            const appointmentDate = new Date(
              appointment.selectedDay.slice(0, 10).replace(/-/g, '/')
            )
            return (
              appointmentDate > currentDate ||
              fullDateToString(appointmentDate) ===
                fullDateToString(currentDate)
            )
          })
          .map(appointment => {
            const appointmentDate = new Date(
              appointment.selectedDay.slice(0, 10).replace(/-/g, '/')
            )
            return { ...appointment, selectedDay: appointmentDate }
          })
        setAppointments(nextAppointments)
        setSelectedDay(current => {
          const day = new Date().getDay()
          if (day === 0) return 6
          return day - 1
        })
        setRefreshNextAppointments(false)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    if (refreshNextAppointments) {
      getNextAppointments()
    }
  }, [authToken, workerInfo, auth, refreshNextAppointments])

  useEffect(() => {
    if (location.state) {
      setCurrentWeek(_ => {
        setSelectedDay(location.state.selectedDay)
        return location.state.selectedWeek
      })
    }
  }, [location])

  const renderNextAppointments = () => {
    const { startOfWeek, endOfWeek } = weeks[currentWeek]
    const appointmentsOfTheWeek = appointments?.filter(appointment => {
      const appointmentDate = appointment.selectedDay
      return (
        (appointmentDate > startOfWeek && appointmentDate < endOfWeek) ||
        fullDateToString(appointmentDate) === fullDateToString(startOfWeek) ||
        fullDateToString(appointmentDate) === fullDateToString(endOfWeek)
      )
    })
    return (
      <div className='next-appointments-inner-content'>
        <div style={{ marginBottom: 16 }}>
          <div className='free-schedule-pagination'>
            {currentWeek !== 0 && (
              <BackwardIcon
                onClick={() => {
                  setCurrentWeek(current => current - 1)
                  setSelectedDay(current => {
                    if (currentWeek === 0) {
                      const day = new Date().getDay()
                      if (day === 0) return 6
                      return day - 1
                    }
                    return 0
                  })
                }}
              />
            )}
            <span>
              Semana {dateToString(weeks[currentWeek].startOfWeek)} -{' '}
              {dateToString(weeks[currentWeek].endOfWeek)}
            </span>
            {currentWeek !== maxNumberOfWeeks - 1 && (
              <ForwardIcon
                onClick={() => {
                  setCurrentWeek(current => {
                    if (current === maxNumberOfWeeks - 1) return current
                    return current + 1
                  })
                  setSelectedDay(0)
                }}
              />
            )}
          </div>
        </div>

        <div className='days-of-week-checkbox'>
          {days
            .filter((day, index) => {
              const dayDate = add(startOfWeek, { days: index })
              const currentDate = new Date()
              return (
                dayDate > currentDate ||
                fullDateToString(dayDate) === fullDateToString(currentDate)
              )
            })
            .map((day, index) => {
              const currentDay = currentWeek === 0 ? new Date().getDay() : 1
              return (
                <RadioInput
                  option={dayENToPT(day)}
                  id={index + currentDay - 1}
                  currentValue={selectedDay}
                  setValue={setSelectedDay}
                  key={day}
                />
              )
            })}
          <RadioInput
            option='Todos'
            id={-1}
            currentValue={selectedDay}
            setValue={setSelectedDay}
          />
        </div>
        {selectedDay !== -1 ? (
          <>
            <p className='day-appointments'>
              {dayENToPT(days[selectedDay])} (
              {dateToString(add(startOfWeek, { days: selectedDay }))})
            </p>
            {renderAppointmentsOfTheDay(selectedDay, appointmentsOfTheWeek)}
          </>
        ) : (
          renderAppointmentsOfTheWeek(startOfWeek, appointmentsOfTheWeek)
        )}
      </div>
    )
  }

  const renderAppointmentsOfTheDay = (day, appointmentsOfTheWeek) => {
    const appointmentsOfTheDay = appointmentsOfTheWeek.filter(appointment => {
      const appointmentDate = appointment.selectedDay
      const appointmentDay = (parseInt(format(appointmentDate, 'i')) + 6) % 7
      return parseInt(day) === appointmentDay
    })
    return appointmentsOfTheDay.length > 0 ? (
      <div className='appointments-container'>
        <div className='appointments-cards'>
          {appointmentsOfTheDay.map((appointment, index) => {
            return (
              <AppointmentDropdown
                setRefreshNextAppointments={setRefreshNextAppointments}
                key={index}
                appointment={appointment}
                identifier={{ selectedWeek: currentWeek, selectedDay, index }}
                authToken={authToken}
                auth={auth}
                petshopId={workerInfo?.petshops[0]?.id}
              />
            )
          })}
        </div>
      </div>
    ) : (
      <p className='empty-appointment'>
        Você não tem agendamentos marcados nesse dia.
      </p>
    )
  }

  const renderAppointmentsOfTheWeek = (startOfWeek, appointmentsOfTheWeek) => (
    <div className='appointments-of-the-week'>
      {days
        .filter((_, index) => {
          const dayDate = add(startOfWeek, { days: index })
          const currentDate = new Date()
          return (
            dayDate > currentDate ||
            fullDateToString(dayDate) === fullDateToString(currentDate)
          )
        })
        .map((day, index) => {
          const currentDay = currentWeek === 0 ? new Date().getDay() : 1
          return (
            <div key={index}>
              <p className='day-appointments'>
                {dayENToPT(day)} (
                {dateToString(
                  add(startOfWeek, { days: index + currentDay - 1 })
                )}
                )
              </p>
              {renderAppointmentsOfTheDay(
                index + currentDay - 1,
                appointmentsOfTheWeek
              )}
            </div>
          )
        })}
    </div>
  )

  const renderSkeleton = () => {
    return (
      <div className='next-appointments-content'>
        <p className='next-appointments-message'>
          <SkeletonText
            lines={3}
            height='15px'
            width='100%'
            x='0'
            rx='8'
            ry='8'
            style={{ borderRadius: '8px' }}
          />
        </p>
        <SkeletonText
          lines={1}
          height={'20px'}
          width='232px'
          x='calc((100% - 232px) / 2)'
          rx='8'
          ry='8'
        />
        <p className='day-appointments'>
          <SkeletonText lines={1} height={'19px'} width='100px' rx='8' ry='8' />
        </p>
        <div className='appointments-cards'>
          {renderComponentNTimes(3, SkeletonCard, {
            style: { borderRadius: '10px' },
            height: '260px'
          })}
        </div>
      </div>
    )
  }

  return (
    <div className='next-appointments-page-container'>
      <div className='next-appointments-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Próximos Atendimentos'
          buttonText='Menu'
          navigateTo='menu'
        />
        {isPageLoading ? (
          renderSkeleton()
        ) : (
          <div className='next-appointments-content'>
            {appointments?.length === 0 ? (
              <p className='empty-appointments'>
                Você não tem atendimentos agendados! <br /> <br />
                Configure seus horários disponíveis e agende atendimentos no
                Menu!
              </p>
            ) : (
              <>
                <p className='next-appointments-message'>
                  Estes são todos os atendimentos da sua empresa{' '}
                  <strong>por semana</strong>. Clique em um para exibir todas as
                  informações.
                </p>
                {renderNextAppointments()}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
