import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import { ReactComponent as AccountIcon } from '../../../assets/images/icons/account.svg'
import Card from '../../../components/Card'
import MenuHeader from '../../../components/MenuHeader'

import Button from '../../../components/Button'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { getPetshopWorkers } from '../../../services/api/apiCalls'
import SkeletonText from '../../../skeletonComponents/Text'
import SkeletonCard from '../../../skeletonComponents/Card'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'
import SkeletonButton from '../../../skeletonComponents/Button'

export default function WorkersPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const navigate = useNavigate()

  const [workers, setWorkers] = useState([])

  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    async function getWorkers () {
      if (!authToken) return
      setIsPageLoading(true)

      try {
        const response = await getPetshopWorkers(authToken)
        setWorkers(response.data)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getWorkers()
  }, [authToken])

  const renderWorkers = () => {
    return workers?.map(worker => {
      return (
        <Card
          key={worker.id}
          classes='worker-card'
          onClick={() => {
            navigate(`editar/${worker.id}`, { state: { worker } })
          }}
        >
          <AccountIcon />
          <p>{worker.name}</p>
        </Card>
      )
    })
  }

  const renderSkeleton = () => {
    return (
      <div className='workers-content'>
        <h4 className='title'>
          <SkeletonText
            lines={1}
            width='50%'
            x='25%'
            rx='8'
            ry='8'
            height={'19px'}
            style={{ borderRadius: '8px' }}
          />
        </h4>
        <div className='workers-container'>
          {renderComponentNTimes(6, SkeletonCard, {
            style: { aspectRatio: 1.2, borderRadius: '10px' }
          })}
        </div>
        <SkeletonButton
          height={'47px'}
          style={{ borderRadius: '25px', marginTop: 'auto' }}
        />
      </div>
    )
  }

  return (
    <div className='workers-page-container'>
      <div className='workers-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Colaboradores'
          buttonText='Voltar'
          navigateTo='minha-conta'
        />
        {isPageLoading ? (
          renderSkeleton()
        ) : (
          <div className='workers-content'>
            {workers?.length > 0 ? (
              <>
                <p className='workers-message'>
                  Selecione o banhista/tosador para editar:
                </p>
                <div className='workers-container'>{renderWorkers()}</div>
              </>
            ) : (
              <p className='empty-workers'>
                Você ainda não tem colaboradores cadastrados. Clique no botão
                abaixo para adicionar.
              </p>
            )}
            <Button
              classes='primary-button add-worker-button'
              text='Adicionar Banhista/Tosador'
              onClick={() => navigate('adicionar')}
            />
          </div>
        )}
      </div>
    </div>
  )
}
