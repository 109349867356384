import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import {
  getPetshopInfo,
  getPetshopPlan,
  login,
  signUp
} from '../../../services/api/apiCalls'
import { setCredentials } from '../../../features/auth/authSlice'
import {
  setMenuState,
  setPetshop
} from '../../../features/petshop/petshopSlice'
import { setCurrentPlan } from '../../../features/plan/planSlice'

import './style.css'
import Button from '../../../components/Button'
import UserAddressInfo from './UserAddressInfo'
import UserInfo from './UserInfo'
import SignUpOverview from './SignUpOverview'
import ConfirmationPopup from '../../../components/ConfirmationPopup'

export default function SignUpPage (props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Component states
  const [userInfo, setUserInfo] = useState()
  const [userAddressInfo, setUserAddressInfo] = useState()
  const [backTrigger, setBackTrigger] = useState(false)
  const [signUpStep, setSingUpStep] = useState('infoStep')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmittable, setIsSubmittable] = useState(false)
  const [submissionErrors, setSubmissionErrors] = useState()
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

  const closeFunction = () => setShowConfirmationPopup(false)

  const confirmationAction = () => {
    navigate(-1)
    closeFunction()
  }

  useEffect(() => {
    async function handleSignUp () {
      if (!userAddressInfo || !isSubmittable) {
        return
      }

      setIsLoading(true)
      setIsSubmittable(false)

      // The array below contains the input fields that will have their mask removed
      const formattedFields = ['cnpj', 'phoneNumber', 'cep']

      // The array below contains the input fields that will not be sent to the backend
      const filterFields = [
        'defaultValues',
        'agreeUseTermsPrivacyPolicy',
        'passwordConfirmation'
      ]

      const formatField = ([key, value]) => {
        if (formattedFields.includes(key)) {
          return [key, value?.replace(/[\D]/g, '')]
        }
        return [key, value]
      }

      const formatData = data => {
        return Object.entries(data)
          .filter(([key, _]) => !filterFields.includes(key))
          .map(formatField)
      }

      const userInfos = formatData(userInfo)
      const user = Object.fromEntries(userInfos)

      const userAddressInfos = formatData(userAddressInfo)
      const address = Object.fromEntries(userAddressInfos)

      try {
        await signUp({ user, address })
        toast.success('Usuário criado com sucesso!')

        const loginResponse = await login({
          email: userInfo.email,
          password: userInfo.password
        })
        const authorization = loginResponse?.headers?.authorization
        const auth = loginResponse?.data?.auth
        const credentials = { user: userInfo.email, token: authorization, auth }

        const planResponse = await getPetshopPlan(authorization)

        const petshopResponse = await getPetshopInfo(authorization)
        const petshopData = petshopResponse.data

        dispatch(setCredentials(credentials))
        dispatch(setPetshop(petshopData))
        dispatch(setMenuState({ menuState: { state: 'initial', index: 0 } }))
        dispatch(setCurrentPlan({ currentPlan: planResponse.data }))

        navigate('/menu')
      } catch (error) {
        if (error?.response?.data?.data?.constraint === 'user_email_unique') {
          setSubmissionErrors({ email: 'O e-mail já está em uso.' })
        } else {
          setSubmissionErrors({
            serverError:
              'Erro interno, se o problema persistir entre em contato com o suporte.'
          })
        }
        setSingUpStep('overview')
      } finally {
        setIsLoading(false)
      }
    }

    handleSignUp()
    // eslint-disable-next-line
  }, [userAddressInfo, userInfo])

  function goBack () {
    if (signUpStep === 'infoStep' || signUpStep === 'overview') {
      setShowConfirmationPopup(true)
    } else if (signUpStep === 'addressStep') {
      setBackTrigger(true)
    }
  }

  return (
    <div className='sign-up-page-container bg-color-identity-1'>
      {showConfirmationPopup && (
        <ConfirmationPopup
          closeFunction={closeFunction}
          confirmationAction={confirmationAction}
          defaultButtons={false}
        >
          <p className='sign-up-page-change-popup-title'>
            <>
              <strong>Tem certeza</strong> que deseja voltar? <br /> <br />
            </>
            <span>
              Você não conseguirá recuperar as informações preenchidas caso
              volte.
            </span>
          </p>
          <div className='change-page-popup-buttons'>
            <Button
              classes='primary-button btn-sm'
              text='Continuar na página'
              onClick={closeFunction}
            />
            <Button
              classes='alert-button btn-with-icon'
              text='Voltar e perder informações'
              onClick={confirmationAction}
            ></Button>
          </div>
        </ConfirmationPopup>
      )}
      <div className='sign-up-page-inner-container'>
        <div className='sign-up-header'>
          <Button
            text='Voltar'
            classes='action-button'
            onClick={() => goBack()}
          />
          <h1>Cadastro</h1>
        </div>
        {signUpStep === 'infoStep' ? (
          <UserInfo
            userInfo={userInfo}
            onSent={resp => {
              setUserInfo(resp)
              setSingUpStep('addressStep')
            }}
          />
        ) : signUpStep === 'addressStep' ? (
          <UserAddressInfo
            backTrigger={backTrigger}
            userAddressInfo={userAddressInfo}
            onSent={resp => {
              setIsSubmittable(true)
              setUserAddressInfo(resp)
            }}
            onBack={data => {
              setUserAddressInfo(data)
              setSingUpStep('infoStep')
              setBackTrigger(false)
            }}
            isLoading={isLoading}
          />
        ) : (
          <SignUpOverview
            userInfo={userInfo}
            userAddressInfo={userAddressInfo}
            submissionErrors={submissionErrors}
          />
        )}
      </div>
    </div>
  )
}
