import React, { useState, useEffect } from 'react'
import {
  add,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  nextSunday,
  isAfter,
  isBefore,
  isSameDay
} from 'date-fns'
import { isMobile } from 'react-device-detect'

import './style.css'
import { ReactComponent as BackwardIcon } from '../../assets/images/icons/backward.svg'
import { ReactComponent as ForwardIcon } from '../../assets/images/icons/forward.svg'
import { dateToString } from '../../services/utils/helpers/dateToString'
import { dayENToPT } from '../../services/utils/helpers/dayOfTheWeek'
import Button from '../Button'
import Card from '../Card'
import ConfirmationPopup from '../ConfirmationPopup'
import { generateImage } from '../../services/utils/helpers/imageGenerator'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../features/auth/authSlice'
import { getAvailablePeriodsForService } from '../../services/api/apiCalls'
import { weeksBuilder } from '../../services/utils/helpers/weeksBuilder'
import {
  selectPetshopMenuState,
  setMenuState
} from '../../features/petshop/petshopSlice'
import SkeletonText from '../../skeletonComponents/Text'
import SkeletonButton from '../../skeletonComponents/Button'
import { renderComponentNTimes } from '../../services/utils/helpers/renderComponentNTimes'
import { isWorker } from '../../services/utils/helpers/authHelper'
import { selectWorkerInfo } from '../../features/worker/workerSlice'

const dateOfNextDayOfTheWeek = day => {
  if (day === 'monday') return nextMonday
  if (day === 'tuesday') return nextTuesday
  if (day === 'wednesday') return nextWednesday
  if (day === 'thursday') return nextThursday
  if (day === 'friday') return nextFriday
  if (day === 'saturday') return nextSaturday
  if (day === 'sunday') return nextSunday
}

const createWhatsappLinkWithMesage = (number, message) => {
  const formattingNumber = number.replace(/[\D]/g, '')
  if (isMobile) return `https://wa.me/55${formattingNumber}?text=${message}`
  return `https://web.whatsapp.com/send/?phone=55${formattingNumber}&text=${message}`
}

// dayOfTheWeek: ranges from 1 (Sunday) to 7 (Saturday), and the week starts on Monday.
const dayOfTheWeek = [
  { weekDay: 2, label: 'monday' },
  { weekDay: 3, label: 'tuesday' },
  { weekDay: 4, label: 'wednesday' },
  { weekDay: 5, label: 'thursday' },
  { weekDay: 6, label: 'friday' },
  { weekDay: 7, label: 'saturday' },
  { weekDay: 1, label: 'sunday' }
]

// removes already selected schedules
const showOnlyNewSchedules = (availablePeriods, selectedSchedules) => {
  return availablePeriods.filter(period => {
    for (let i = 0; i < selectedSchedules.length; i++) {
      const { selectedDay, startTime } = selectedSchedules[i]
      if (
        selectedDay === period.selectedDay &&
        startTime === period.periodStart
      ) {
        return false
      }
    }
    return true
  })
}

export default function FreeSchedules ({
  selectedClientPet,
  selectedSchedule,
  setValue,
  resetField,
  field,
  onPopup = false,
  closeFunction,
  handleSubmit,
  buttonTextPopup = 'Adicionar Horário',
  furSize,
  bodySize,
  serviceId,
  freeSchedule,
  setFreeSchedule,
  schedules,
  setIsSchedulesUpdated,
  resetConsultSchedule,
  ...props
}) {
  const authToken = useSelector(selectCurrentToken)
  const menuState = useSelector(selectPetshopMenuState)
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const dispatch = useDispatch()

  const newWeeks = weeksBuilder(7)
  const [currentWeek, setCurrentWeek] = useState(0)
  const [weeks, setWeeks] = useState([])

  const [redirectToWhatsappPopup, setRedirectToWhatsappPopup] = useState(false)
  const [isScheduleCopied, setIsScheduleCopied] = useState(false)

  const [selectedDate, setSelectedDate] = useState()

  const [isSchedulesLoading, setIsSchedulingLoading] = useState(true)

  useEffect(() => {
    async function getFreeSchedules () {
      if (!authToken) return

      setIsSchedulingLoading(true)

      if (freeSchedule?.length > 0) {
        setWeeks(freeSchedule)
        setFreeSchedule(freeSchedule)
        setIsSchedulingLoading(false)
        return
      }

      const params = { serviceId, considerAllWorkers: true, bodySize, furSize }
      if (isWorker(auth)) {
        params.petshopId = workerInfo?.petshops[0]?.id
      }
      try {
        const response = await getAvailablePeriodsForService(authToken, params)
        const availablePeriodsForService = schedules
          ? showOnlyNewSchedules(response.data, schedules)
          : response.data
        setWeeks(availablePeriodsForService)
        if (setFreeSchedule) {
          setFreeSchedule(availablePeriodsForService)
        }
        if (!menuState || menuState?.index < 3) {
          dispatch(setMenuState({ menuState: { state: 'clients', index: 3 } }))
        }
        setIsSchedulingLoading(false)
      } catch (error) {
        console.error(error)
        resetConsultSchedule()
      } finally {
        setIsSchedulingLoading(false)
      }
    }

    getFreeSchedules()
    // eslint-disable-next-line
  }, [authToken, serviceId, bodySize, furSize, setFreeSchedule])

  //adds the schedule to the clipboard and if there is a customer selected, it can redirect to whatsapp with the message
  const copyToClipboard = () => {
    //converting DD/MM/YYYY to YYYY/MM/DD
    const { startOfWeek, endOfWeek } = newWeeks[currentWeek]
    const startDate = add(startOfWeek, { days: -1 })
    const startOfNextWeek = add(endOfWeek, { days: 1 })
    const scheduleOfWeek = weeks.filter(schedule => {
      const scheduleDate = new Date(
        schedule.selectedDay.slice(0, 10).replace(/-/g, '/')
      )
      return (
        isAfter(scheduleDate, startDate) &&
        isBefore(scheduleDate, startOfNextWeek)
      )
    })

    const messageToCopy = []
    messageToCopy.push(
      `Horários da Semana - ${dateToString(startOfWeek)} - ${dateToString(
        endOfWeek
      )}`
    )

    dayOfTheWeek.forEach(day => {
      const schedules = scheduleOfWeek.filter(
        schedule => schedule.weekDay === day.weekDay
      )
      if (schedules.length === 0) return ''

      const cardDate = dateOfNextDayOfTheWeek(day.label)(startDate)

      messageToCopy.push(
        `\n\t${dayENToPT(day.label)} - ${dateToString(cardDate)}`
      )

      schedules.forEach((schedule, index) => {
        if (index % 3 === 0) {
          messageToCopy.push('\n\t')
        }
        const periodStart = schedule.periodStart.replace(/\b(\d):/, '0$1:')
        messageToCopy.push(`      *${periodStart}*`)
      })
    })

    navigator.clipboard?.writeText(messageToCopy.join(''))
    if (selectedClientPet) {
      const encodeMessage = window.encodeURIComponent(messageToCopy.join(''))
      const whatsappLink = createWhatsappLinkWithMesage(
        selectedClientPet.client.phoneNumber,
        encodeMessage
      )
      setRedirectToWhatsappPopup({ state: true, whatsappLink })
    }
  }

  const renderFreeSchedules = schedules => {
    return schedules?.map(schedule => {
      const { selectedDay, periodStart, periodEnd, workerId } = schedule
      const scheduleInfos = { selectedDay, periodStart, periodEnd, workerId }
      let compareSchedules
      if (onPopup) {
        compareSchedules =
          selectedDate?.selectedDay === selectedDay &&
          selectedDate?.startTime === periodStart
      } else {
        compareSchedules =
          selectedSchedule?.selectedDay === selectedDay &&
          selectedSchedule?.periodStart === periodStart
      }
      return (
        <Button
          type='button'
          key={periodStart}
          text={periodStart}
          classes={
            (compareSchedules ? 'selected-schedule' : '') + ' schedule-button'
          }
          onClick={() => {
            if (onPopup) {
              const {
                periodStart,
                periodEnd,
                selectedDay,
                workerId
              } = scheduleInfos
              setSelectedDate({
                selectedDay,
                endTime: periodEnd,
                startTime: periodStart,
                workerId
              })
            } else {
              setValue(field, scheduleInfos)
            }
          }}
        />
      )
    })
  }

  const renderFreeScheduleDays = () => {
    //converting DD/MM/YYYY to YYYY/MM/DD
    const { startOfWeek, endOfWeek } = newWeeks[currentWeek]
    const startDate = add(startOfWeek, { days: -1 })

    const scheduleOfWeek = weeks.filter(schedule => {
      const scheduleDate = new Date(
        schedule.selectedDay.slice(0, 10).replace(/-/g, '/')
      )

      const isScheduleOfWeek =
        isAfter(scheduleDate, startDate) &&
        (isSameDay(scheduleDate, endOfWeek) ||
          isBefore(scheduleDate, endOfWeek))
      if (currentWeek === 0) {
        const today = new Date()
        const hours = ('0' + today.getHours()).slice(-2)
        const minutes = ('0' + today.getMinutes()).slice(-2)
        // prevent showing times before the current time
        return (
          isScheduleOfWeek &&
          (isSameDay(scheduleDate, today)
            ? schedule.periodStart > `${hours}:${minutes}`
            : true)
        )
      } else {
        return isScheduleOfWeek
      }
    })

    return dayOfTheWeek.map(day => {
      const schedules = scheduleOfWeek.filter(
        schedule => schedule.weekDay === day.weekDay
      )

      const scheduleAlreadyExists = (schedules, schedule) =>
        schedules.some(
          item =>
            item.selectedDay === schedule.selectedDay &&
            item.periodStart === schedule.periodStart
        )

      const uniqueScheduleTimes = schedules.filter((schedule, index, self) => {
        return !scheduleAlreadyExists(self.slice(index + 1), schedule)
      })

      if (schedules.length === 0) return ''

      const cardDate = dateOfNextDayOfTheWeek(day.label)(startDate)

      return (
        <Card key={day.weekDay} classes='day-schedule-card'>
          <h4>
            {dayENToPT(day.label)} - {dateToString(cardDate)}
          </h4>
          <div className='schedules-days-container'>
            {renderFreeSchedules(uniqueScheduleTimes)}
          </div>
        </Card>
      )
    })
    /*
    const daysOfWeek = weeks[currentWeek]?.weekFreeSchedule
    const daysOfWeekArray = Object.entries(daysOfWeek)
    return daysOfWeekArray
      .filter(day => day[1].length > 0)
      .map(day => {
        const date = dateOfNextDayOfTheWeek(day[0])(startDate)
        return (
          <Card key={day[0]} classes='day-schedule-card'>
            <h4>
              {dayENToPT(day[0])} - {dateToString(date)}
            </h4>
            <div className='schedules-days-container'>
              {renderFreeSchedules(day, date)}
            </div>
          </Card>
        )
      })
     */
  }

  const renderSkeleton = () => {
    return (
      <>
        <SkeletonText
          height={'20px'}
          width='30%'
          rx='8'
          ry='8'
          x='35%'
          style={{ borderRadius: '8px', margin: '0 auto' }}
        />
        <div className='share-schedules-buttons'>
          {renderComponentNTimes(2, SkeletonButton, {
            height: '38px',
            style: { borderRadius: '8px' }
          })}
        </div>
        <SkeletonText
          height={'17px'}
          width='40%'
          x={'30%'}
          rx='8'
          ry='8'
          style={{ borderRadius: '8px', margin: '0 auto min(3vh, 24px)' }}
        />
        <SkeletonButton height={'200px'} style={{ borderRadius: '10px' }} />
      </>
    )
  }

  return (
    <>
      {isSchedulesLoading
        ? renderSkeleton()
        : weeks?.length > 0 && (
            <>
              <div className='free-schedule-pagination'>
                {currentWeek !== 0 && (
                  <BackwardIcon
                    data-html2canvas-ignore
                    onClick={() => {
                      setCurrentWeek(current => {
                        if (current === 0) return current
                        return current - 1
                      })
                      if (!onPopup) {
                        resetField(field, { defaultValue: {} })
                        setValue(field, undefined)
                      } else {
                        setSelectedDate()
                      }
                    }}
                  />
                )}
                <span>
                  Semana {dateToString(newWeeks[currentWeek]?.startOfWeek)} -{' '}
                  {dateToString(newWeeks[currentWeek]?.endOfWeek)}
                </span>
                {currentWeek !== newWeeks.length - 1 && (
                  <ForwardIcon
                    data-html2canvas-ignore
                    onClick={() => {
                      setCurrentWeek(current => {
                        if (current === newWeeks.length - 1) return current
                        return current + 1
                      })
                      if (!onPopup) {
                        resetField(field, { defaultValue: {} })
                        setValue(field, undefined)
                      } else {
                        setSelectedDate()
                      }
                    }}
                  />
                )}
              </div>
              <div className='share-schedules-buttons'>
                <Button
                  type='button'
                  text='Compartilhar Horários - Imagem'
                  classes='action-button-tertiary share-image-button'
                  onClick={() => generateImage('schedules')}
                  data-html2canvas-ignore
                />
                <Button
                  type='button'
                  text='Compartilhar Horários - Texto'
                  classes='share-text-button'
                  onClick={() => copyToClipboard()}
                  data-html2canvas-ignore
                />
              </div>
              <div className='schedules-container'>
                {!onPopup && (
                  <p
                    className='schedules-container-message'
                    data-html2canvas-ignore
                  >
                    <strong>Clique</strong> em um horário para{' '}
                    <strong>selecionar</strong>.
                    <br />
                    Troque as semanas nas setas acima para ver outros horários.
                  </p>
                )}
                <div className='schedules-content'>
                  {weeks.length > 0 ? (
                    renderFreeScheduleDays()
                  ) : (
                    <p>Carregando</p>
                  )}
                </div>
              </div>
              {onPopup && (
                <>
                  <span className='free-schedule-error-message'>
                    {selectedDate?.error && 'Nenhum horário selecionado'}
                  </span>
                  <Button
                    text={buttonTextPopup}
                    classes='primary-button add-schedule-button'
                    {...(handleSubmit && {
                      onClick: () => {
                        if (selectedDate && !selectedDate?.error) {
                          setValue(field, selectedDate)
                          setIsSchedulesUpdated()
                          closeFunction()
                        } else {
                          setSelectedDate({ error: true })
                        }
                      }
                    })}
                  />
                </>
              )}
            </>
          )}
      {redirectToWhatsappPopup && (
        <ConfirmationPopup
          closeFunction={() => {
            setRedirectToWhatsappPopup(false)
            setIsScheduleCopied(false)
          }}
          defaultButtons={false}
        >
          <div className='whatsapp-redirect-container'>
            <p className='whatsapp-redirect-title'>
              Deseja enviar os horários para o WhatsApp do cliente{' '}
              {selectedClientPet.client.clientName}?
            </p>
            <div className='whatsapp-confirmation-popup-buttons'>
              <Button
                type='button'
                text={
                  isScheduleCopied ? 'Horários copiados!' : 'Copiar horários'
                }
                classes={`${
                  isScheduleCopied ? 'primary-button' : 'action-button'
                } btn-sm`}
                onClick={() => setIsScheduleCopied(true)}
              />
              <Button
                type='button'
                text={'Abrir WhatsApp'}
                classes='whatsapp-button'
                onClick={() => {
                  const { whatsappLink } = redirectToWhatsappPopup
                  window.open(whatsappLink, '_blank')
                }}
              />
            </div>
          </div>
        </ConfirmationPopup>
      )}
    </>
  )
}
