import React from 'react'
import ContentLoader from 'react-content-loader'

export default function SkeletonButton ({
  height,
  style,
  backgroundColor = '#dbdbdb',
  ...props
}) {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={height}
      backgroundColor={backgroundColor}
      foregroundColor='#ecebeb'
      style={style}
      title='Carregando...'
    >
      <rect x='0' y='0' width='100vw' height={height} />
    </ContentLoader>
  )
}
