import { bodySizeToString, furSizeToString } from './petHelpers'

export const petsToString = pets => {
  if (!pets?.length) return 'Sem pets cadastrados.'
  return pets.map(pet => {
    const { name, bodySize, furSize } = pet
    return `${name} (porte ${bodySizeToString(
      bodySize
    )} e pelo ${furSizeToString(furSize)}). `
  })
}
