import AppConfig from '../../config/appConfig'

export async function createCardToken (cardData) {
  const cardExpiration = cardData.validity.split('/')
  const expMonth = cardExpiration[0]
  const expYear = cardExpiration[1]

  const cardNumber = cardData.cardNumber.replace(/\s/g, '')

  return fetch(
    `https://api.pagar.me/core/v5/tokens?appId=${AppConfig.pagarmePublicKey}`,
    {
      method: 'post',
      body: JSON.stringify({
        type: 'card',
        card: {
          number: cardNumber,
          holder_name: cardData.cardHolderName,
          exp_month: expMonth,
          exp_year: expYear,
          cvv: cardData.securityCode
        }
      })
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw new Error(
          'Problema ao criar token do cartão, tente novamente mais tarde. Se o problema persistir, contate o suporte'
        )
      }
    })
    .catch(res => {
      console.error(res)
      return res
    })
}
