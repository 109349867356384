import React from 'react'
import ContentLoader from 'react-content-loader'

export default function SkeletonText ({
  height,
  width = '50vw',
  x,
  rx = '0',
  ry = '0',
  style,
  backgroundColor = '#dbdbdb',
  lines = 1,
  svgWidth = '100%',
  ...props
}) {
  return Array(lines)
    .fill(0)
    .map((_, index) => {
      const totalWidth = index > 0 && index === lines - 1 ? '75%' : width
      return (
        <ContentLoader
          key={index}
          speed={2}
          width={svgWidth}
          height={height}
          backgroundColor={backgroundColor}
          foregroundColor='#ecebeb'
          style={style}
          title='Carregando...'
        >
          <rect
            x={x}
            y='0'
            width={totalWidth}
            height={height}
            rx={rx}
            ry={ry}
          />
        </ContentLoader>
      )
    })
}
