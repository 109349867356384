const bodySizes = ['small', 'medium', 'large']
const furSizes = ['short', 'long']

const toServiceValues = (
  serviceDuration,
  servicePrices,
  serviceValuesIds = undefined
) => {
  const serviceValues = []

  bodySizes.forEach(bodySize => {
    furSizes.forEach(furSize => {
      const value = parseInt(
        servicePrices[bodySize][furSize].replace(/[\D]/g, '')
      )
      const time = parseInt(
        serviceDuration[bodySize][furSize].replace(' min', '')
      )
      const serviceValue = { bodySize, furSize, value, time }

      if (serviceValuesIds) {
        serviceValue['id'] = serviceValuesIds[bodySize][furSize]
        serviceValue['isUpdate'] = true
      }
      serviceValues.push(serviceValue)
    })
  })
  return serviceValues
}

export { toServiceValues }
