import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format, add } from 'date-fns'

import { selectCurrentToken } from '../../features/auth/authSlice'
import { getPetshopPlan } from '../../services/api/apiCalls'
import {
  selectCurrentPlan,
  setCurrentPlan
} from '../../features/plan/planSlice'
import { normalizePrice } from '../../services/utils/masks'
import { periodCycle } from '../../services/utils/helpers/planHelper'

import './style.css'
import MenuHeader from '../../components/MenuHeader'
import Button from '../../components/Button'
import SkeletonText from '../../skeletonComponents/Text'
import SkeletonButton from '../../skeletonComponents/Button'

export default function MyPlanPage ({ ...props }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const authToken = useSelector(selectCurrentToken)

  const [planInformations, setPlanInformations] = useState(
    useSelector(selectCurrentPlan)
  )

  const [isPageLoading, setIsPageLoading] = useState(false)

  useEffect(() => {
    async function getPlan () {
      setIsPageLoading(true)
      try {
        const response = await getPetshopPlan(authToken)
        dispatch(setCurrentPlan({ currentPlan: response.data }))
        setPlanInformations(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsPageLoading(false)
      }
    }
    getPlan()
  }, [authToken, dispatch])

  const getPlanName = plan => {
    return plan?.period?.plan?.name
  }

  const getPlanPrice = plan => {
    return plan?.period?.price && normalizePrice(plan.period.price)
  }

  const getPlanPeriodCycle = plan => {
    return periodCycle[plan?.period?.periodCycle]?.label
  }

  const resolvePlanDateTitle = plan => {
    if (!plan?.nextBillingDay) {
      return 'Válido Até'
    } else {
      return 'Próxima Cobrança'
    }
  }

  const getPlanNextBilling = plan => {
    let dateToBeUsed

    if (plan?.nextBillingDay) {
      dateToBeUsed = plan.nextBillingDay
    } else {
      dateToBeUsed = plan.validUntilDay
    }

    const date = new Date(dateToBeUsed)
    const utcDate = add(date, {
      days: 1
    })

    const day = format(utcDate, 'dd/MM/yyyy')
    return day
  }

  const renderUserWithPlan = () => (
    <>
      <div className='my-plan-information'>
        <p className='my-plan-information-title'>Plano Atual</p>
        <p className='my-plan-information-detail'>
          {getPlanName(planInformations)} {getPlanPeriodCycle(planInformations)}{' '}
          - {getPlanPrice(planInformations)} por mês
        </p>
      </div>
      <div className='my-plan-information'>
        <p className='my-plan-information-title'>
          {resolvePlanDateTitle(planInformations)}
        </p>
        <p className='my-plan-information-detail'>
          {getPlanNextBilling(planInformations)}
        </p>
      </div>
      <Button
        classes='primary-button my-plan-button'
        text='Trocar Plano'
        onClick={() => navigate('/planos')}
      />
    </>
  )

  const renderUserWithoutPlan = () => (
    <>
      <p className='message-user-without-plan'>
        Você ainda não assina nenhum plano. Clique no botão abaixo para ver
        nossas opções.
      </p>
      <Button
        classes='primary-button'
        text='Ver Planos'
        onClick={() => navigate('/planos')}
      />
    </>
  )

  const renderSkeleton = () => {
    return (
      <>
        <SkeletonText
          height={'20px'}
          width='100%'
          rx='8'
          ry='8'
          x='0%'
          style={{ borderRadius: '8px', margin: '2px auto' }}
        />
        <SkeletonText
          height={'20px'}
          width='100%'
          rx='8'
          ry='8'
          x='0%'
          style={{ borderRadius: '8px', margin: '2px auto' }}
        />
        <div className='general-settings-button'>
          <SkeletonButton height={'47px'} style={{ borderRadius: '25px' }} />
        </div>
      </>
    )
  }

  return (
    <div className='my-plan-page-container'>
      <div className='my-plan-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Meu Plano'
          buttonText='Menu'
          navigateTo='menu'
        />
        <div className='my-plan-content'>
          {isPageLoading
            ? renderSkeleton()
            : planInformations?.id
            ? renderUserWithPlan()
            : renderUserWithoutPlan()}
        </div>
      </div>
    </div>
  )
}
