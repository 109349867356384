import React, { useState } from 'react'

import './style.css'
import { ReactComponent as AddIcon } from '../../assets/images/icons/add.svg'
import Card from '../Card'
import Input from '../Input'
import Button from '../Button'
import TwoStepsRemove from '../TwoStepsRemove'
import CopyPopup from './CopyPopup'
import { convertDayToNumber } from '../../services/utils/helpers/dayOfTheWeek'
import { isNumber } from '../../services/utils/helpers/isNumber'
import { normalizeTime } from '../../services/utils/masks'

export default function DayScheduleCard ({
  title,
  className,
  register,
  setValue,
  fields,
  append,
  remove,
  day,
  dailySchedule = [],
  week,
  errors,
  clearErrors,
  setDeletedSchedules,
  date,
  filterFunction = () => true,
  getValues,
  ...props
}) {
  const [showCopyPopup, setShowCopyPopup] = useState(false)

  const isIOS =
    navigator.userAgent.indexOf('iPhone') > -1 ||
    navigator.userAgent.indexOf('iPad') > -1

  const removeDaySchedule = index => {
    if (week || week === 0) {
      // check to avoid passing the id created by using the useFieldArray append to new schedules
      if (dailySchedule[index]?.id && isNumber(+dailySchedule[index]?.id)) {
        setDeletedSchedules(curr => [
          ...curr,
          { ...dailySchedule[index], isDelete: true }
        ])
      }
    }
    // check to avoid passing the id created by using the useFieldArray append to new schedules
    if (dailySchedule[index]?.id && isNumber(+dailySchedule[index]?.id)) {
      setDeletedSchedules(curr => [
        ...curr,
        {
          ...dailySchedule[index],
          isDelete: true,
          weekDay: convertDayToNumber(day)
        }
      ])
    }
    remove(index)
  }

  const handleAddDaySchedule = () => {
    if (week || week === 0) {
      append({ periodStart: '', periodEnd: '', day: date })
    } else {
      append({ periodStart: '', periodEnd: '' })
    }
  }

  const orderDaySchedule = () => {
    if (week || week === 0) {
      return
    }
    const orderedDaySchedule = [...dailySchedule]
    orderedDaySchedule.sort((a, b) => {
      return a.periodStart < b.periodStart
        ? -1
        : a.periodStart > b.periodStart
        ? 1
        : 0
    })
    clearErrors(`${day}`)
    setValue(`${day}`, orderedDaySchedule)
  }

  const renderSchedule = () => {
    return fields
      .map((field, index) => {
        if (!filterFunction(field)) {
          return false
        }

        let isValid,
          registerField,
          inputProps = { periodStart: {}, periodEnd: {} }

        if (week || week === 0) {
          isValid = errors?.periods?.[index] === undefined
          registerField = `periods[${index}]`
        } else {
          isValid = errors?.[day]?.[index] === undefined
          registerField = `${day}[${index}]`
          inputProps.periodStart.onBlur = orderDaySchedule
          inputProps.periodEnd.onBlur = orderDaySchedule
        }

        inputProps.periodStart = register(`${registerField}.periodStart`)
        inputProps.periodEnd = register(`${registerField}.periodEnd`)

        if (isIOS) {
          const onChangePeriodStart = inputProps.periodStart.onChange
          inputProps.periodStart.onChange = e => {
            onChangePeriodStart(e)
            const formattedTime = normalizeTime(e.target.value)
            setValue(`${registerField}.periodStart`, formattedTime)
          }

          const onChangePeriodEnd = inputProps.periodEnd.onChange
          inputProps.periodEnd.onChange = e => {
            onChangePeriodEnd(e)
            const formattedTime = normalizeTime(e.target.value)
            setValue(`${registerField}.periodEnd`, formattedTime)
          }
        }

        return (
          <div key={field.id} className='schedule-item'>
            <div className='schedule-item-content'>
              <Input
                type={isIOS ? 'text' : 'time'}
                classes={`floating-input card-input`}
                isValid={isValid}
                {...inputProps.periodStart}
              />
              <p>às</p>
              <Input
                type={isIOS ? 'text' : 'time'}
                classes={`floating-input card-input`}
                isValid={isValid}
                {...inputProps.periodEnd}
              />
              <TwoStepsRemove removeFunction={() => removeDaySchedule(index)} />
            </div>
            {errors?.[day]?.[index] ? (
              <p className='period-error'>
                {errors[day][index]?.periodStart?.message ||
                  errors[day][index]?.periodEnd?.message}
              </p>
            ) : (
              ''
            )}
            {errors?.periods?.[index] ? (
              <p className='period-error'>
                {errors?.periods?.[index]?.periodStart?.message ||
                  errors?.periods?.[index]?.periodEnd?.message}
              </p>
            ) : (
              ''
            )}
          </div>
        )
      })
      .filter(Boolean)
  }

  return (
    <div className=''>
      {showCopyPopup && (
        <CopyPopup
          title={title}
          close={() => setShowCopyPopup(false)}
          addDaySchedule={setValue}
          daySchedule={date ? fields.filter(filterFunction) : fields}
          append={append}
          date={date}
          getValues={getValues}
        />
      )}
      <Card classes={`schedule-card ${className}`}>
        <div className='card-header'>
          <h3>{title}</h3>
          <div className='card-header-buttons'>
            <div className='svg-container'>
              <AddIcon
                onClick={() => {
                  handleAddDaySchedule()
                }}
              />
            </div>
            {fields.filter(filterFunction).length !== 0 && (
              <Button
                type='button'
                classes='action-button-tertiary action-button-small'
                text='Copiar'
                onClick={() => {
                  setShowCopyPopup(true)
                }}
              />
            )}
          </div>
        </div>
        <div className='card-content'>
          {fields.filter(filterFunction).length !== 0 ? (
            renderSchedule()
          ) : (
            <span>Sem horários</span>
          )}
          {errors?.[day] && (
            <span className='error-field'>{errors?.[day].message}</span>
          )}
        </div>
      </Card>
    </div>
  )
}
