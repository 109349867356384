import React, { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { object, string, ref } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'

import './style.css'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import { ReactComponent as CheckIcon } from '../../../../assets/images/icons/check.svg'
import { ReactComponent as UnCheckIcon } from '../../../../assets/images/icons/unCheck.svg'
import {
  normalizePhoneNumber,
  normalizeCNPJ
} from '../../../../services/utils/masks'
import cnpjValidator from '../../../../services/utils/validators/cnpjValidator'

const validationSchema = object({
  name: string()
    .required('Nome Fantasia é campo obrigatório')
    .trim(),
  cnpj: string()
    .required('CNPJ é campo obrigatório')
    .test('is-valid-cnpj', 'CNPJ inválido', value => cnpjValidator(value)),
  email: string()
    .email('Formato incorreto de email!')
    .required('E-mail é campo obrigatório'),
  phoneNumber: string().required('Telefone é um campo obrigatório'),
  password: string()
    .required('Senha é campo obrigatório')
    .min(6, 'A senha precisa ter ao menos 6 caracteres'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], 'Senhas precisam ser iguais')
    .required('Confirmar Senha é um campo obrigatório')
})

export default function UserInfo ({ onSent, userInfo, ...props }) {
  const [agreeUseTermsPrivacyPolicy, setAgreeUseTermsPrivacyPolicy] = useState(
    false
  )
  const [useTermsError, setUseTermsError] = useState('')

  const useTermsErrorMessage =
    'É preciso aceitar os termos de uso para realizar o cadastro'

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return userInfo
      }, [userInfo])
    }
  })

  const phoneValue = watch('phoneNumber')
  const cnpjValue = watch('cnpj')

  useEffect(() => {
    setValue('phoneNumber', normalizePhoneNumber(phoneValue))
    setValue('cnpj', normalizeCNPJ(cnpjValue))
  }, [phoneValue, cnpjValue, setValue])

  useEffect(() => {
    reset(userInfo)
  }, [userInfo, reset])

  function agreeDisagreeUseTerms () {
    if (agreeUseTermsPrivacyPolicy) {
      return <CheckIcon onClick={() => setAgreeUseTermsPrivacyPolicy(false)} />
    }
    return <UnCheckIcon onClick={() => setAgreeUseTermsPrivacyPolicy(true)} />
  }

  function handleSignUp (formData) {
    if (!agreeUseTermsPrivacyPolicy) {
      return
    }
    const data = {
      agreeUseTermsPrivacyPolicy,
      ...formData
    }
    onSent(data)
  }

  return (
    <div className='sign-up-content'>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div className='input-field'>
          <label htmlFor='name-input'>Nome Fantasia</label>
          <Input
            placeholder='Nome'
            name='name'
            type='text'
            classes='form-input'
            isValid={errors.name === undefined}
            {...register('name')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.name?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='cnpj-inpupt'>CNPJ</label>
          <Input
            placeholder='00.000.000/0000-00'
            name='cnpj'
            type='text'
            classes='form-input'
            isValid={errors.cnpj === undefined}
            {...register('cnpj')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.cnpj?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='phoneNumber-input'>Telefone/Celular</label>
          <Input
            placeholder='(99) 99999-9999'
            name='phoneNumber'
            type='text'
            classes='form-input'
            isValid={errors.phoneNumber === undefined}
            {...register('phoneNumber')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.phoneNumber?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='email-input'>
            E-mail <span>(será usado para seu login)</span>
          </label>
          <Input
            placeholder='email@email.com'
            name='email'
            type='text'
            classes='form-input'
            isValid={errors.email === undefined}
            {...register('email')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.email?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='password-input'>Senha</label>
          <Input
            name='password'
            type='password'
            classes='form-input'
            isValid={errors.password === undefined}
            {...register('password')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.password?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='confirm-password-input'>Confirmar Senha</label>
          <Input
            name='confirm-password'
            type='password'
            classes='form-input'
            isValid={errors.passwordConfirmation === undefined}
            {...register('passwordConfirmation')}
          />
          <div className='error-container'>
            <span className='field-error'>
              {errors?.passwordConfirmation?.message}
            </span>
          </div>
        </div>
        <div className='use-terms'>
          <div className='use-terms-content'>
            <span>{agreeDisagreeUseTerms()}</span>
            <p>
              Li e aceito os <a href='/termos/condicoes'>Termos de Uso</a> e a{' '}
              <a href='/termos/privacidade'>Política de Privacidade</a>
            </p>
          </div>
          <div className='error-container'>
            <span className='field-error'>
              {!agreeUseTermsPrivacyPolicy && useTermsError}
            </span>
          </div>
        </div>

        <Button
          type='submit'
          text='Cadastrar'
          classes='primary-button'
          onClick={() =>
            setUseTermsError(
              agreeUseTermsPrivacyPolicy ? '' : useTermsErrorMessage
            )
          }
        />
      </form>

      <p className='redirect-login'>
        Já possui conta? <Link to='/entrar'>Clique aqui</Link> e faça o Login!
      </p>
    </div>
  )
}
