import React from 'react'

import './style.css'
import Popup from '../Popup'
import Button from '../Button'

export default function SessionExpiredPopup ({ closeFunction, ...props }) {
  return (
    <Popup closeFunction={closeFunction}>
      <div className='expired-popup-content'>
        <h2 className='expired-popup-title'>Sessão expirou</h2>
        <br />
        <p>Sua sessão expirou. Faça login novamente para continuar.</p>
        <br />
        <Button
          onClick={closeFunction}
          text={'Continuar'}
          classes='primary-button'
        />
      </div>
    </Popup>
  )
}
