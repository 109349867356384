import React from 'react'

import './style.css'
import Popup from '../Popup'
import Button from '../Button'

export default function ConfirmationPopup ({
  closeFunction,
  confirmationAction,
  children,
  defaultButtons = true,
  ...props
}) {
  return (
    <Popup closeFunction={closeFunction} className='change-page-popup popup'>
      {children}
      {defaultButtons && (
        <div className='change-page-popup-buttons'>
          <Button
            classes='alert-button'
            text='Cancelar'
            onClick={closeFunction}
          />
          <Button
            classes='primary-button btn-sm'
            text='Continuar'
            onClick={confirmationAction}
          />
        </div>
      )}
    </Popup>
  )
}
