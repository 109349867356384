import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string, ref } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { resetPasswordWithToken } from '../../../services/api/apiCalls'

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { ReactComponent as PasswordIcon } from '../../../assets/images/icons/lock.svg'
import { ReactComponent as ArrowBackIcon } from '../../../assets/images/icons/arrow-back.svg'
import Header from '../../../components/Header'

import './style.css'

const validationSchema = object({
  password: string()
    .required('Senha é campo obrigatório')
    .min(6, 'A senha precisa ter ao menos 6 caracteres'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], 'As senhas precisam ser iguais')
    .required('Confirme a senha')
})

export default function ChangePasswordPage ({ ...props }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const authToken = searchParams.get('token')

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!authToken) {
      navigate('/entrar')
    }
  }, [authToken, navigate])

  async function handlePasswordChange (data) {
    setIsLoading(true)

    try {
      await resetPasswordWithToken(authToken, {
        password: data.password
      })
      toast.success(
        'Senha criada com sucesso! Faça o login para acessar a conta.'
      )
      navigate('/entrar')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  function goBack () {
    navigate(-1)
  }

  return (
    <div className='change-password-page-container bg-color-identity-1'>
      <div className='change-password-page-inner-container'>
        <Header />
        <div className='change-password-container'>
          <div className='change-password-header'>
            <div className='return-label' onClick={goBack}>
              <ArrowBackIcon />
              <span>Voltar</span>
            </div>
            <div className='header-title'>Redefinir Senha</div>
          </div>
          <div className='change-password-text-content'>
            <p>Crie uma nova senha:</p>
          </div>
          <form onSubmit={handleSubmit(handlePasswordChange)}>
            <div className='password-container'>
              <Input
                placeholder='Senha'
                name='password'
                type='password'
                isValid={errors.password === undefined}
                {...register('password')}
              >
                <PasswordIcon className='password-icon' />
              </Input>
              <span className='field-error'>{errors?.password?.message}</span>
            </div>
            <div className='password-container' style={{ marginBottom: 24 }}>
              <Input
                placeholder='Confirme a Senha'
                name='password'
                type='password'
                isValid={errors.password === undefined}
                {...register('passwordConfirmation')}
              >
                <PasswordIcon className='password-icon' />
              </Input>
              <span className='field-error'>
                {errors?.passwordConfirmation?.message}
              </span>
            </div>
            <Button
              type='submit'
              text='Criar Nova Senha'
              classes='primary-button'
              isLoading={isLoading}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
