import { createSlice } from '@reduxjs/toolkit'

const workerSlice = createSlice({
  name: 'worker',
  initialState: { workerInfo: null, menuState: null },
  reducers: {
    setWorker: (state, action) => {
      const payload = action.payload
      state.workerInfo = payload
    },
    removeWorker: state => {
      state.workerInfo = null
      state.menuState = null
    }
  }
})

export const { setWorker, removeWorker } = workerSlice.actions

export default workerSlice.reducer

export const selectWorkerInfo = state => state.reducer.worker.workerInfo
