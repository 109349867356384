import React from 'react'

import './style.css'
import Popup from '../Popup'
import Button from '../Button'

export default function FeesPopup ({ closeFunction, ...props }) {
  return (
    <Popup closeFunction={closeFunction} className='fees-popup popup'>
      <div className='fees-popup-container'>
        <h3 className='fees-popup-title'>Taxas de Cobrança</h3>
        <p className='fees-popup-content'>
          No momento os clientes só conseguem realizar os pagamentos via Pix.
          <br />
          <i>
            (Se tiver interesse em cobranças via cartão, entra em contato com a
            gente!)
          </i>
          <br />
          <br />
          <strong>Taxa da intermediadora:</strong> taxa de 0,99% sobre o valor{' '}
          <strong>total</strong>.
          <br />
          <br />
          <strong>Serviço Gepety</strong> <br />
          Taxa de 1% <strong>após retirada a taxa da intermediadora.</strong>
          <br />
          <br />
          <span> O que isso significa?</span> <br />
          <br />
          Em um pagamento no valor de R$ 100,00, a{' '}
          <strong>intermediadora</strong> recebe <strong>R$0,99</strong>, o{' '}
          <strong>Gepety</strong> recebe <strong>R$ 0,99</strong> e você recebe
          o <strong>valor restante</strong>, de <strong>R$ 98,02</strong>.
        </p>
        {/* <p className='fees-popup-content'>
          <strong>Pagamento em 1 parcela:</strong> taxa de 3,34% <br />
          <strong>Pagamento de 2 a 6 parcelas:</strong> taxa de 4,28% <br />
          <br />
          <strong>Serviço Gepety</strong> <br />
          FIxo para qualquer quantidade de <strong>parcelas</strong> ou{' '}
          <strong>valor</strong>: R$ 1,99 <br />
          <br />
          <span> O que isso significa?</span> <br />
          <br />
          Em um pagamento no valor de R$ 100,00, caso o cliente pague em{' '}
          <strong>1 parcela, 3,34%</strong> do valor total vai para a{' '}
          <strong>intermediadora</strong> do pagamento (maquininha) e{' '}
          <strong>R$ 1,99</strong> vai para o{' '}
          <strong>Gepety para a manutenção do serviço</strong>. Ou seja, serão
          descontados <strong>R$ 5,33</strong> do <strong>valor total</strong> e
          você <strong>recebe R$ 94,67.</strong> <br />
          <br />
          No caso de um pagamento de R$ 200,00 em <strong> 6 parcelas</strong>,
          a taxa final será de <strong>4,28%</strong> (
          <strong>maquininha</strong>) + <strong>R$1,99</strong> (
          <strong>serviço Gepety</strong>), totalizando{' '}
          <strong>R$10,55.</strong> Voce <strong>receberá</strong> então{' '}
          <strong>R$189,45.</strong>
        </p> */}
        <Button
          text='Continuar'
          classes='primary-button'
          onClick={() => closeFunction()}
        />
      </div>
    </Popup>
  )
}
