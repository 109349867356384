import React from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import Button from '../../../components/Button'

export default function ConditionTermsPage (props) {
  const navigate = useNavigate()

  function goBack () {
    navigate(-1)
  }

  return (
    <div className='condition-terms-page-container bg-color-identity-1'>
      <div className='condition-terms-page-inner-container'>
        <div className='condition-terms-header'>
          <Button
            text='Voltar'
            classes='action-button'
            onClick={() => goBack()}
          />
          <div className='condition-terms-header-title-container'>
            <h1>Termos e Condições</h1>
          </div>
        </div>
        <div className='condition-terms-content'>
          <p>
            Este Contrato de Licença de Usuário Final (“EULA” ou “CLUF”) é um
            acordo legal entre o licenciado (pessoa física ou jurídica) (o
            “LICENCIADO”) e a GEPETY DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR
            LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob n.
            49.498.851/0001-03, com sede na AVENIDA 29, 401, APTO 51, localizado
            no Cidade Jardim, CEP 13501-104, Rio Claro, SP - Brasil, (a
            “LICENCIANTE”) para uso do programa de computador denominado GEPETY,
            disponibilizado neste ato pela LICENCIANTE (o “SOFTWARE”) por meio
            do site “gepety.com.br” (“Site”), pelo determinado pelo LICENCIADO
            no ato do licenciamento do SOFTWARE, compreendendo o programa de
            computador e podendo incluir os meios físicos associados, bem como
            quaisquer materiais impressos e qualquer documentação online ou
            eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente ou a
            título de teste, o LICENCIADO estará vinculado aos termos deste
            EULA, concordando com suas disposições, principalmente com relação
            ao CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO,
            TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do LICENCIADO pela
            LICENCIANTE, necessárias para a integral execução das
            funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância com
            os termos aqui apresentados, a utilização do SOFTWARE deve ser
            imediatamente interrompida pelo LICENCIADO.
          </p>
          <br />
          <h2>1 - Objeto</h2>
          <br />
          <p>
            O presente contrato tem por objeto a prestação, pela GEPETY, da
            concessão de licença de uso do presente aplicativo de sua
            propriedade e titularidade exclusiva, oferecido por meio de site ou
            aplicativo em dispositivos móveis, destinados a prover ferramentas a
            fim de facilitar a atividade de serviços voltados às necessidades e
            bem-estar de animais, ora LICENCIADO.
          </p>
          <br />
          <p>
            O LICENCIADO terá permissões e restrições de uso do aplicativo,
            conforme plano por ele escolhido quando da contratação e
            disponibilizado na página específica. Para a utilização do
            aplicativo, o LICENCIADO será o único responsável por providenciar e
            manter as perfeitas condições de uso e instalação, sendo obrigatório
            o uso de internet da sua preferência, um navegador apropriado,
            celular ou qualquer dispositivo que conecte à www (world wide web).
          </p>
          <br />
          <p>
            O LICENCIADO deverá cadastrar uma senha e um username para login no
            aplicativo, independente do plano contratado, devendo manter TOTAL
            sigilo sobre tais dados. Em razão do caráter personalíssimo desta
            contratação, o LICENCIADO, desde já, concorda em não repassar os
            seus dados de acesso ao aplicativo para qualquer usuário, sob pena
            de suspensão da prestação de serviço e aplicação de penalidade.
          </p>
          <br />
          <h2>2 - Das Características da plataforma</h2>
          <br />
          <p>
            O LICENCIADO poderá optar, conforme pacotes de serviços oferecidos
            pelo GEPETY, a quantidade de acessos a serem disponibilizados aos
            seus clientes.
          </p>
          <br />
          <p>
            O LICENCIADO tem o limite de 5GB para utilização de fotos e arquivos
            em formatos jpeg ou pdf, sendo que o conteúdo das imagens é de sua
            INTEIRA RESPONSABILIDADE.
          </p>
          <br />
          <p>
            A GEPETY poderá a seu exclusivo critério desenvolver novas
            funcionalidades para os aplicativos, podendo cobrar ou não do
            LICENCIADO um valor adicional por cada uma das funcionalidades.
          </p>
          <br />
          <h2>3 - Das Obrigações do Licenciante</h2>
          <br />
          <p>
            A GEPETY obriga-se a cumprir as seguintes obrigações relacionadas ao
            presente termo:
          </p>
          <br />
          <p>a) manter o aplicativo em boas condições de uso;</p>
          <p>
            b) comunicar, sempre que possível, ao LICENCIADO sobre manutenções
            nos servidores que coloquem o serviço temporariamente indisponível;
          </p>
          <p>
            c) manter sigilo absoluto sobre as informações constantes no
            aplicativo fornecida pelo LICENCIADO, tanto dos dados do seu
            cadastramento quanto da utilização do aplicativo, exceto em caso de
            exigências jurídicas/legais;
          </p>
          <p>
            d) não violar os direitos autorais e de propriedade intelectual de
            terceiros;
          </p>
          <br />
          <p>
            A GEPETY não poderá ser responsabilizada por falhas na prestação de
            serviços do aplicativo ocasionados por caso fortuito ou força maior,
            como causas que estejam fora do seu controle, incluindo ataques
            externos e/ou eventos, ainda que previsíveis, relacionados a
            tecnologia, produto ou serviços utilizados pela GEPETY.
          </p>
          <br />
          <p>
            A GEPETY não tem nenhuma responsabilidade quanto à prestação de
            serviço realizada entre o LICENCIADO e seus clientes, não tendo
            obrigação de fiscalizar ou de controlar os conteúdos armazenados no
            aplicativo. Ainda, não poderá ser responsabilizada por quaisquer
            veiculações de materiais e informações do LICENCIADO, inclusive de
            cunho ilegal, imoral ou antiético, por ventura realizadas pelo
            LICENCIADO, cabendo a este responder pessoalmente por eventuais
            reclamações de terceiros ou demandas judiciais, isentando a GEPETY
            de qualquer responsabilidade.
          </p>
          <br />
          <h2>4 - Das Obrigações do Licenciado</h2>
          <br />
          <p>
            O LICENCIADO obriga-se a cumprir as seguintes obrigações
            relacionadas ao presente termo:
          </p>
          <br />
          <p>
            a) obter por meios próprios o acesso a Internet para uso do
            aplicativo, bem como utilizá-lo exclusivamente para a finalidade que
            foi criado e conforme delimitado na cláusula 1 do presente termo;
          </p>
          <p>
            b) efetuar o pagamento do serviço durante a vigência deste termo;
          </p>
          <p>
            c) responsabilizar-se pela segurança e guarda dos dados inseridos no
            aplicativo, pelo sigilo das informações cadastradas para login, a
            fim de evitar invasões de terceiros, não cabendo qualquer tipo de
            ressarcimento ou indenização, por parte da GEPETY ao LICENCIADO na
            ocorrência das referidas hipóteses;
          </p>
          <p>d) não compartilhar seus dados pessoais de acesso com ninguém;</p>
          <p>
            e) não violar quaisquer direitos de terceiros e especialmente
            aqueles referentes à propriedade intelectual e direitos autorais da
            GEPETY, e;
          </p>
          <p>
            f) responder pessoalmente por eventuais perdas e danos a que der
            causa, seja por culpa ou dolo, sempre isentando a GEPETY de qualquer
            responsabilidade neste sentido.
          </p>
          <p>
            g) tratar com respeito e educação todos os colaboradores da GEPETY,
            independente da situação. Em caso de descumprimento deste item, o
            LICENCIADO será comunicado da infração. Em caso de repetição da
            infração, a GEPETY reserva-se o direito de rescindir unilateralmente
            e imediatamente este contrato, sem prejuízo por parte desta.
          </p>
          <br />
          <h2>5 - Da Remuneração e Forma de Pagamento</h2>
          <br />
          <p>
            O LICENCIADO deverá seguir as disposições desta cláusula para
            pagamento e utilização do serviço de licença de uso do aplicativo:
          </p>
          <br />
          <p>
            a) pagar à GEPETY o valor estabelecido pelo plano escolhido,
            conforme tabela de preço e funcionalidades disponibilizadas na
            página específica;
          </p>
          <p>
            b) autorizar que o pagamento seja realizado pela GEPETY, via cartão
            de crédito, conforme dados fornecidos pelo LICENCIADO, de forma
            RECORRENTE, de acordo com o pacote escolhido pelo LICENCIADO;
          </p>
          <br />
          <p>
            O LICENCIADO renovará mensalmente o serviço de licença de uso do
            aplicativo a GEPETY mediante aprovação do pagamento pelo emissor do
            cartão de crédito, e a GEPETY fica responsável pela emissão da Nota
            Fiscal de Serviço que será enviada ao correio eletrônico do
            LICENCIADO.
          </p>
          <br />
          <p>
            Os preços ajustados, mediante assinatura deste contrato, podem
            sofrer variação de acordo com o IGPM e ocorrerão em período igual a
            doze meses. A GEPETY comunicará com antecedência mínima de 10 (dez)
            dias os reajustes no preço, que ocorrerão sempre na fatura seguinte
            ao anúncio dos novos valores.
          </p>
          <br />
          <p>
            Em caso de mudança na legislação tributária, seja em criação de novo
            tributo ou ajuste de alíquota, que desequilibre a operação da
            GEPETY, esta fica, independente do tempo de relacionamento com o
            LICENCIADO, autorizada a realizar reajuste no preço.
          </p>
          <br />
          <h2>
            6 - Da Remuneração e Forma de Pagamento de Serviços Oferecidos a
            Terceiros
          </h2>
          <br />
          <p>
            A GEPETY pode oferecer ao LICENCIADO a possibilidade de realizar a
            cobrança de seus serviços para seus clientes através de suas
            plataformas.
          </p>
          <br />
          <p>
            A responsabilidade em caso de não pagamento do serviço pelo cliente,
            em caso de pagamento e solicitação de reembolso (chargeback) e
            outras situações referentes à prestação do serviço, são inteiramente
            do LICENCIADO.
          </p>
          <br />
          <p>
            É importante ressaltar que a GEPETY oferece apenas uma facilidade
            para o LICENCIADO realizar suas cobranças, porém todas as questões
            legais referentes ao serviço e seus pagamentos devem ser tratadas
            entre o LICENCIADO e seus respectivos clientes.
          </p>
          <br />
          <p>
            Como remuneração pela facilitação oferecida por esse tipo de
            serviço, a GEPETY reserva-se o direito de cobrar uma taxa
            (previamente comunicada ao LICENCIADO) por cada transação realizada
            através da plataforma.
          </p>
          <br />
          <p>
            Para ofertar tal serviço, a GEPETY poderá utilizar de alguma
            plataforma intermediadora de pagamentos, que possua cobrança de taxa
            que será incluída nos custos da transação, também comunicadas ao
            LICENCIADO.
          </p>
          <br />
          <p>
            Em caso de mudança da taxa da plataforma intermediadora contratada,
            a GEPETY reserva-se o direito de mudar as taxas cobradas sem aviso
            prévio, por não ter controle dessa plataforma externa. Entretanto, a
            GEPETY fará o possível para avisar sobre as mudanças assim que for
            informada.
          </p>
          <br />
          <p>
            Em caso de mudança da taxa por parte da GEPETY (sem relação com a
            intermediadora de pagamentos), seja por decisões financeiras ou
            estratégicas, o LICENCIADO será comunicado com uma antecedência
            mínima de 30 dias.
          </p>
          <br />
          <h2>7 - Da Falta de Pagamento e Cancelamento</h2>
          <br />
          <p>
            Em caso de não pagamento, o LICENCIADO receberá notificação para no
            prazo de até 72 horas regularizar o pagamento. Em caso de não
            pagamento, no prazo estabelecido na cláusula anterior, o LICENCIADO
            terá o serviço suspenso, o que impede o acesso ao aplicativo para
            disponibilidade de novos acessos.
          </p>
          <br />
          <p>
            Na hipótese de suspensão do serviço, com o reconhecimento do
            pagamento, a GEPETY restabelecerá o acesso no prazo de 48 (quarenta
            e oito) horas (desconsiderados finais de semana e feriados).
          </p>
          <br />
          <p>
            O LICENCIADO poderá utilizar os serviços e funcionalidades do
            aplicativo da GEPETY, respeitadas as obrigações contratuais contidas
            neste documento, podendo se preferir cancelar através do próprio
            aplicativo ou site da GEPETY ou entrando em contato com a equipe de
            suporte. Em decorrência do pedido de cancelamento do contrato, a
            GEPETY fica isenta de responsabilidade pela perda dos dados dos
            clientes do LICENCIADO, quando este não efetuar a exportação para
            outra plataforma.
          </p>
          <br />
          <p>
            O LICENCIADO que optou por cancelar o serviço e posteriormente
            deseje retornar ao uso do aplicativo, terá seus dados salvos no seu
            login pelo período de 90 (noventa) dias.
          </p>
          <br />
          <p>
            Para cancelamento do serviço, o LICENCIADO deve realizar a
            solicitação em no mínimo 10 dias úteis antes da próxima cobrança
            agendada, para que ela não seja efetuada. Caso contrário, a cobrança
            será efetuada uma última vez antes do cancelamento efetivo. Em
            nenhuma hipótese o pagamento de uma cobrança passada será ressarcido
            por parte da GEPETY em caso de pedido de cancelamento posterior a
            ele ou caso o pedido de cancelamento tenha sido feito em um período
            inferior a 5 dias úteis antes da data agendada para a cobrança.
          </p>
          <br />
          <p>
            A GEPETY se mantém no direito de rescindir unilateralmente o
            presente contrato, sem necessidade de explicação prévia ao
            LICENCIADO, mas se comprometendo à comunicação com antecedência de
            30 (trinta) dias.
          </p>
          <br />

          <h2>8 - Da Propriedade Intelectual e Licença de Uso</h2>
          <br />
          <p>
            O LICENCIADO não adquire, pelo presente instrumento ou pela
            utilização do SOFTWARE, nenhum direito de propriedade intelectual ou
            outros direitos exclusivos, incluindo patentes, desenhos, marcas,
            direitos autorais ou quaisquer direitos sobre informações
            confidenciais ou segredos de negócio, bem como todo o conteúdo
            disponibilizado no Site, incluindo, mas não se limitando a textos,
            gráficos, imagens, logotipos, ícones, fotografias, conteúdo
            editorial, notificações, softwares e qualquer outro material, sobre
            ou relacionados ao SOFTWARE ou nenhuma parte dele. O LICENCIADO
            também não adquire nenhum direito sobre ou relacionado ao SOFTWARE
            ou qualquer componente dele, além dos direitos expressamente
            licenciados ao LICENCIADO sob o presente EULA/CLUF ou em qualquer
            outro contrato mutuamente acordado por escrito entre o LICENCIADO e
            a LICENCIANTE. Quaisquer direitos não expressamente concedidos sob o
            presente instrumento são reservados.
          </p>
          <br />
          <p>
            Sujeito aos termos e condições aqui estabelecidos, este EULA/CLUF
            concede ao LICENCIADO uma licença revogável, não exclusiva e
            intransferível para uso do SOFTWARE. Em nenhuma hipótese o
            LICENCIADO terá acesso ao código fonte do SOFTWARE ora licenciado,
            por este se tratar de propriedade intelectual da LICENCIANTE.
          </p>
          <br />
        </div>
      </div>
    </div>
  )
}
