import React from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import { ReactComponent as AccountIcon } from '../../assets/images/icons/account.svg'
import { ReactComponent as CompanyDataIcon } from '../../assets/images/icons/companyData.svg'
import { ReactComponent as CollaboratorsIcon } from '../../assets/images/icons/collaborators.svg'
import MenuHeader from '../../components/MenuHeader'
import Card from '../../components/Card'
import { useSelector } from 'react-redux'
import { selectCurrentAuth } from '../../features/auth/authSlice'
import { isWorker } from '../../services/utils/helpers/authHelper'
import { selectWorkerInfo } from '../../features/worker/workerSlice'
import WorkerForm from '../PetshopWorkers/WorkerForm'

const myAccountOptions = [
  {
    id: 0,
    description: 'Dados Responsável',
    icon: () => <AccountIcon className='account-icon' />,
    navigateTo: '/dados-responsavel'
  },
  {
    id: 1,
    description: 'Dados da Empresa',
    icon: () => <CompanyDataIcon />,
    navigateTo: '/dados-empresa'
  },
  {
    id: 2,
    description: 'Colaboradores',
    icon: () => <CollaboratorsIcon />,
    navigateTo: '/meus-colaboradores'
  }
]

export default function MyAccountPage ({ ...props }) {
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const navigate = useNavigate()

  const renderWorker = () => {
    const { email, name, phoneNumber } = workerInfo

    return (
      <div className='worker-crud-content'>
        <WorkerForm worker={{ email, name, phoneNumber }} readOnly={true} />
      </div>
    )
  }

  const renderAccountOptions = () => {
    return myAccountOptions.map(option => {
      return (
        <Card
          key={option.id}
          classes='my-account-option'
          onClick={() => navigate(option.navigateTo)}
        >
          {option.icon()}
          <p className='account-option-description'>{option.description}</p>
        </Card>
      )
    })
  }

  return (
    <div className='my-account-container'>
      <div className='my-account-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Minha Conta'
          buttonText='Menu'
          navigateTo='menu'
        />
        {isWorker(auth) ? (
          renderWorker()
        ) : (
          <div className='my-account-content'>
            <div className='my-account-options-content'>
              {renderAccountOptions()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
