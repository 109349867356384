import React from 'react'
import Select, { components } from 'react-select'

import './style.css'
import { ReactComponent as DropdownIcon } from '../../assets/images/icons/dropdown.svg'

//style of the select component
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid var(--text-input-border)',
    borderRadius: '20px',
    height: '37px',
    '&:hover': {
      borderColor: 'var(--text-input-border)'
    },
    boxShadow: 'none'
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '20px',
    marginTop: 0
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: '20px',
    backgroundColor: state.isSelected ? 'var(--text-input-border)' : 'white',
    ':hover': {
      backgroundColor: !state.isSelected && 'rgba(224, 112, 0, 0.4)'
    },
    ':active': {
      backgroundColor: state.isSelected
        ? 'var(--text-input-border)'
        : 'rgba(224, 112, 0, 0.4)'
    }
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: (_, state) => ({
    paddingTop: '8px',
    paddingBottom: '7px',
    paddingRight: '12px',
    paddingLeft: '8px',
    display: state.isDisabled ? 'none' : 'flex',
    boxSizing: 'border-box',
    flexWrap: 'nowrap'
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--light-black-text)'
  })
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  )
}

export default function CustomSelect ({
  field,
  options,
  placeholder,
  isSearchable = false,
  ...props
}) {
  return (
    <Select
      {...field}
      placeholder={placeholder}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      isSearchable={isSearchable}
      options={options}
      styles={customStyles}
      {...props}
    />
  )
}
