export default function cnpjValidator (cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '') return false

  // Elimina CNPJs invalidos conhecidos
  const items = [...new Set(cnpj)]
  if (items.length === 1) return false

  // Valida DVs
  const calcDV = length => {
    const numbers = cnpj.substring(0, length)
    let sum = 0
    let pos = length - 7
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--
      if (pos < 2) pos = 9
    }
    return sum % 11 < 2 ? 0 : 11 - (sum % 11)
  }

  const digits = cnpj.substring(12)
  const fstDigit = calcDV(12)

  if (fstDigit !== parseInt(digits[0])) return false

  const sndDigit = calcDV(13)

  if (sndDigit !== parseInt(digits[1])) return false

  return true
}
