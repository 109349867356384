import React, { useEffect, useState } from 'react'

import './style.css'
import { ReactComponent as RadioCheckIcon } from '../../assets/images/icons/radioCheck.svg'
import { ReactComponent as RadioUncheckIcon } from '../../assets/images/icons/radioUncheck.svg'

const RadioInput = React.forwardRef(
  ({ option, className, id, currentValue, setValue, ...props }, ref) => {
    const [radioState, setRadioState] = useState(false)

    useEffect(() => {
      setRadioState(currentValue === id)
    }, [currentValue, id])

    return (
      <div className={'radio ' + className}>
        <input
          type='radio'
          id={id}
          className='radio-input'
          ref={ref}
          value={id}
          {...props}
        />
        <label
          htmlFor={id}
          className='radio-container'
          onClick={() => {
            if (!radioState) {
              setRadioState(current => !current)
              setValue(id)
            }
          }}
        >
          {radioState ? <RadioCheckIcon /> : <RadioUncheckIcon />}
          <span className='radio-title'>{option}</span>
        </label>
      </div>
    )
  }
)

export default RadioInput
