import React, { useEffect, useState } from 'react'
import Card from '../Card'

import './style.css'
import { ReactComponent as StepUncheck } from '../../assets/images/icons/stepUncheck.svg'
import { ReactComponent as StepUncheckDisabled } from '../../assets/images/icons/stepUncheckDisabled.svg'
import { ReactComponent as StepCheck } from '../../assets/images/icons/stepCheck.svg'
import { ReactComponent as StepSeparator } from '../../assets/images/icons/stepSeparator.svg'
import { ReactComponent as StepSeparatorDisabled } from '../../assets/images/icons/stepSeparatorDisabled.svg'
import Button from '../Button'
import CancelAppointmentPopup from '../CancelAppointmentPopup'
import {
  customerServiceNextStep,
  customerServicePreviousStep
} from '../../services/api/apiCalls'
import { toast } from 'react-toastify'
import { stepsState } from '../../services/utils/helpers/stepsState'
import { dateToISOString } from '../../services/utils/helpers/dateToString'
import { isWorker } from '../../services/utils/helpers/authHelper'

export default function StepComponent ({
  authToken,
  step,
  isFirstStep = false,
  state,
  stepIndex,
  setSteps,
  previousStepId,
  auth,
  petshopId,
  appointmentId,
  setRefreshNextAppointments,
  ...props
}) {
  const [componentState, setComponentState] = useState()

  const [showCancelAppointmentPopup, setShowCancelAppointmentPopup] = useState(
    false
  )

  const [action, setAction] = useState()

  useEffect(() => {
    setComponentState(state)
  }, [state])

  const renderButton = () => {
    if (isFirstStep) {
      return (
        <Button
          text='Cancelar'
          {...(action === 'next'
            ? { style: { pointerEvents: 'none', opacity: 0.5 } }
            : { isLoading: action })}
          classes='alert-button step-component-first-button'
          onClick={() => setShowCancelAppointmentPopup(true)}
        />
      )
    }
    return (
      <Button
        text='Voltar Etapa'
        {...(action === 'next'
          ? { style: { pointerEvents: 'none', opacity: 0.5 } }
          : { isLoading: action })}
        classes='action-button step-component-first-button'
        onClick={async () => {
          setAction('back')
          const currentDate = new Date()
          const date = dateToISOString(currentDate)

          const returnedAt = { eventOccurredAt: date }
          const { scheduledClientServiceId } = step

          try {
            const response = await customerServicePreviousStep(
              authToken,
              returnedAt,
              scheduledClientServiceId,
              previousStepId,
              isWorker(auth) ? petshopId : undefined
            )
            toast.success('Voltando para a etapa anterior!')
            setSteps(stepsState(response.data))
          } catch (error) {
            console.error(error)
          } finally {
            setAction()
          }
        }}
      />
    )
  }

  //ends the current step and starts the next one
  const completeStep = async () => {
    setAction('next')
    const currentDate = new Date()
    const date = dateToISOString(currentDate)

    const completedAt = { eventOccurredAt: date }
    const { scheduledClientServiceId, id } = step

    try {
      const response = await customerServiceNextStep(
        authToken,
        completedAt,
        scheduledClientServiceId,
        id,
        isWorker(auth) ? petshopId : undefined
      )
      toast.success('Etapa concluida!')
      setSteps(stepsState(response.data))
    } catch (error) {
      console.error(error)
    } finally {
      setAction()
    }
  }

  const dateToHours = completedAt => {
    const options = { hour: 'numeric', minute: 'numeric' }
    const date = new Date(completedAt)
    return date?.toLocaleTimeString('pt-BR', options)
  }

  return (
    <>
      {componentState === 'initial' ? (
        //rendered when step is initialized
        <div className='step-component-container'>
          {!isFirstStep && <StepSeparator className='step-sepator' />}
          <Card classes='step-component-card'>
            <div className='step-component-header'>
              <StepUncheck /> {step.serviceStepName}
            </div>
            <div className='step-component-buttons'>
              {renderButton()}
              <Button
                text='Concluir'
                {...(action && action !== 'next'
                  ? { style: { pointerEvents: 'none', opacity: 0.5 } }
                  : { isLoading: action })}
                classes='action-button-tertiary step-component-conclude-button'
                onClick={() => completeStep()}
              />
            </div>
          </Card>
        </div>
      ) : componentState === 'upcoming' ? (
        //rendered when step has not yet been initialized
        <div className='step-component-container'>
          <StepSeparatorDisabled className='step-sepator' />
          <div className='upcoming-step'>
            <StepUncheckDisabled />
            <p className='upcoming-step-name'>{step.serviceStepName}</p>
          </div>
        </div>
      ) : (
        //rendered when step completed
        <div className='step-component-container'>
          {!isFirstStep && <StepSeparator className='step-sepator' />}
          <div className='step-component-complete'>
            <StepCheck className='step-component-logo' />
            <p className='step-component-step-name'>{step.serviceStepName}</p>
            <span className='step-component-span'>
              Concluído em {dateToHours(step.completedAt)}
            </span>
          </div>
        </div>
      )}
      {showCancelAppointmentPopup && (
        <CancelAppointmentPopup
          setRefreshNextAppointments={setRefreshNextAppointments}
          appointmentId={appointmentId}
          authToken={authToken}
          closeFunction={() => {
            setShowCancelAppointmentPopup(false)
          }}
        />
      )}
    </>
  )
}
