import { createSlice } from '@reduxjs/toolkit'

const petshopSlice = createSlice({
  name: 'petshop',
  initialState: { petshopInfo: null, responsiblePerson: null, menuState: null },
  reducers: {
    setPetshop: (state, action) => {
      const payload = action.payload
      const { responsiblePerson } = payload
      if (responsiblePerson) {
        delete payload.responsiblePerson
        state.responsiblePerson = responsiblePerson
      }
      state.petshopInfo = payload
    },
    removePetshop: state => {
      state.petshopInfo = null
      state.responsiblePerson = null
      state.menuState = null
    },
    setPetshopConfiguration: (state, action) => {
      const payload = action.payload
      state.petshopInfo = {
        ...state.petshopInfo,
        petshopConfiguration: payload
      }
    },
    setResponsiblePerson: (state, action) => {
      const { responsiblePerson } = action.payload
      state.responsiblePerson = responsiblePerson
    },
    setMenuState: (state, action) => {
      const { menuState } = action.payload
      state.menuState = menuState
    }
  }
})

export const {
  setPetshop,
  removePetshop,
  setPetshopConfiguration,
  setResponsiblePerson,
  setMenuState
} = petshopSlice.actions

export default petshopSlice.reducer

export const selectPetshopInfo = state => state.reducer.petshop.petshopInfo
export const selectPetshopResponsiblePerson = state =>
  state.reducer.petshop.responsiblePerson
export const selectPetshopMenuState = state => state.reducer.petshop.menuState
export const selectPetshopConfiguration = state =>
  state.reducer.petshop?.petshopInfo?.petshopConfiguration
