import React, { useEffect, useMemo } from 'react'
import { object, string, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import './style.css'
import ufValidator from '../../../services/utils/validators/ufValidator'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import {
  normalizeCEP,
  normalizeCNPJ,
  normalizePhoneNumber
} from '../../../services/utils/masks'
import { cepSearch } from '../../../services/externalAPI/cep'
import { toast } from 'react-toastify'

const fieldsLabel = {
  name: 'Nome Fantasia',
  phoneNumber: 'Telefone',
  cnpj: 'CNPJ',
  email: 'E-mail',
  address: {
    cep: 'CEP',
    city: 'Cidade',
    uf: 'Estado',
    district: 'Bairro',
    street: 'Rua',
    streetNumber: 'Número',
    complement: 'Complemento'
  }
}

const validationSchema = object({
  name: string().required('Informe o nome fantasia da empresa.'),
  cnpj: string().required(),
  phoneNumber: string().required('Informe o telefone da empresa.'),
  email: string().required(),
  address: object({
    cep: string().required('CEP é um campo obrigatório'),
    city: string().required('Cidade é um campo obrigatório'),
    uf: string()
      .required('Estado é um campo obrigatório')
      .matches(/^[A-z]+$/, 'Formato incorreto')
      .test('is-valid-uf', 'Estado não existente', value => ufValidator(value)),
    district: string().required('Bairro é um campo obrigatório'),
    street: string().required('Rua é um campo obrigatório'),
    streetNumber: number().required('Número é um campo obrigatório'),
    complement: string()
  })
})

export default function CompanyForm ({
  company,
  isLoading,
  submitFunction,
  ...props
}) {
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return company
      }, [company])
    }
  })

  useEffect(() => {
    reset(company)
  }, [company, reset])

  const phone = watch('phoneNumber')

  //masking phone field
  useEffect(() => {
    if (phone) setValue('phoneNumber', normalizePhoneNumber(phone))
  }, [setValue, phone])

  const cnpj = watch('cnpj')

  //masking cnpj field
  useEffect(() => {
    if (cnpj) setValue('cnpj', normalizeCNPJ(cnpj))
  }, [setValue, cnpj])

  const cep = watch('address.cep')

  //masking cep field
  useEffect(() => {
    if (cep) setValue('address.cep', normalizeCEP(cep))
  }, [setValue, cep])

  useEffect(() => {
    let cepValue = cep
    if (!cepValue) return

    cepValue = cepValue.replace(/[\D]/g, '')
    if (cepValue.length < 8) return

    cepSearch(cepValue).then(data => {
      if (!data?.message) {
        setValue('address.uf', data.state)
        setValue('address.district', data.neighborhood)
        setValue('address.street', data.street)
        setValue('address.city', data.city)
        clearErrors('address.cep')
      } else {
        setError('address.cep', { type: 'custom', message: data.message })
      }
    })
  }, [setValue, setError, clearErrors, cep])

  const uf = watch('address.uf')

  useEffect(() => {
    if (uf) setValue('address.uf', uf?.replace(/[^A-z]/g, '')?.toUpperCase())
  }, [setValue, uf])

  const streetNumber = watch('address.streetNumber')

  useEffect(() => {
    if (streetNumber) setValue('address.streetNumber', streetNumber)
  }, [setValue, streetNumber])

  const renderCompanyInfo = () => {
    const keys = Object.keys(fieldsLabel)
    return keys
      .filter(key => key !== 'address')
      .map((key, index) => {
        return (
          <div className='company-input-container' key={index}>
            <label htmlFor={`${key}-input`} className='company-input-title'>
              {fieldsLabel[key]}
            </label>
            <div>
              <Input
                type='text'
                {...(key === 'email' || key === 'cnpj'
                  ? { disabled: true, classes: 'floating-input disabled-input' }
                  : { isValid: errors[key] === undefined })}
                {...register(`${key}`)}
              />
              {!(key === 'email' || key === 'cnpj') && (
                <span
                  className={
                    errors[key]?.message ? 'add-client-field-error' : ''
                  }
                >
                  {errors[key]?.message}
                </span>
              )}
            </div>
          </div>
        )
      })
  }

  const renderCompanyAddressInfo = () => {
    const addressKeys = Object.keys(fieldsLabel.address)
    return addressKeys.map(addressKey => (
      <div className='company-input-container' key={addressKey}>
        <label
          htmlFor={`address.${addressKey}-input`}
          className='company-input-title'
        >
          {fieldsLabel?.address[addressKey]}
        </label>
        <div>
          <Input
            type='text'
            isValid={
              errors?.address ? errors?.address[addressKey] === undefined : true
            }
            {...register(`address.${addressKey}`)}
          />
          <span
            className={
              (errors?.address
              ? errors?.address[addressKey]?.message
              : false)
                ? 'add-client-field-error'
                : ''
            }
          >
            {errors?.address ? errors?.address[addressKey]?.message : ''}
          </span>
        </div>
      </div>
    ))
  }

  const handleFormSubmit = data => {
    if (!errors?.address?.cep) {
      if (!isDirty) {
        toast.warning('Faça uma alteração para poder atualizar os dados!')
        return
      }

      const { email, name, phoneNumber, cnpj, address } = data

      const user = {
        email,
        name,
        phoneNumber: phoneNumber.replace(/[\D]/g, ''),
        cnpj: cnpj.replace(/[\D]/g, '')
      }

      const {
        cep,
        city,
        uf,
        district,
        street,
        streetNumber,
        complement
      } = address

      const companyData = {
        user,
        address: {
          cep: cep.replace(/[\D]/g, ''),
          city,
          uf,
          district,
          street,
          streetNumber: String(streetNumber),
          complement
        }
      }

      submitFunction(companyData)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className='company-form'>
      {renderCompanyInfo()}
      {renderCompanyAddressInfo()}
      <Button
        text='Salvar'
        classes='primary-button company-form-button'
        isLoading={isLoading}
      />
    </form>
  )
}
