import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { object, string, ref } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import './style.css'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import cnpjValidator from '../../../../services/utils/validators/cnpjValidator'
import ufValidator from '../../../../services/utils/validators/ufValidator'
import {
  normalizePhoneNumber,
  normalizeCEP,
  normalizeCNPJ
} from '../../../../services/utils/masks'
import {
  getPetshopPlan,
  login,
  signUp
} from '../../../../services/api/apiCalls'
import { setCredentials } from '../../../../features/auth/authSlice'
import {
  setMenuState,
  setPetshop
} from '../../../../features/petshop/petshopSlice'
import { setCurrentPlan } from '../../../../features/plan/planSlice'

const fieldsLabel = {
  name: 'Nome Fantasia',
  phoneNumber: 'Telefone',
  cnpj: 'CNPJ',
  email: 'E-mail',
  password: 'Senha',
  passwordConfirmation: 'Confirme a Senha',
  cep: 'CEP',
  city: 'Cidade',
  uf: 'Estado',
  district: 'Bairro',
  street: 'Rua',
  streetNumber: 'Número',
  complement: 'Complemento'
}

const validationSchema = object({
  name: string().required('Nome Fantasia é campo obrigatório'.trim()),
  cnpj: string()
    .required('CNPJ é campo obrigatório')
    .test('is-valid-cnpj', 'CNPJ inválido', value => cnpjValidator(value)),
  email: string()
    .email('Formato incorreto de email!')
    .required('E-mail é campo obrigatório'),
  phoneNumber: string().required('Telefone é um campo obrigatório'),
  password: string()
    .required('Senha é campo obrigatório')
    .min(6, 'A senha precisa ter ao menos 6 caracteres'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], 'Senhas precisam ser iguais')
    .required('Confirmar Senha é um campo obrigatório'),
  cep: string().required('CEP é um campo obrigatório'),
  city: string()
    .required('Cidade é um campo obrigatório')
    .trim(),
  uf: string()
    .required('Estado é um campo obrigatório')
    .matches(/^[A-z]+$/, 'Formato incorreto')
    .test('is-valid-uf', 'Estado não existente', value => ufValidator(value)),
  district: string()
    .required('Bairro é um campo obrigatório')
    .trim(),
  street: string()
    .required('Rua é um campo obrigatório')
    .trim(),
  streetNumber: string().required('Número é um campo obrigatório'),
  complement: string().trim()
})

const clearMask = value => value?.replace(/[\D]/g, '')

export default function SignUpOverview ({
  userInfo,
  userAddressInfo,
  submissionErrors,
  ...props
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userData, setUserData] = useState([])
  const [addressInfo, setAddressInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const emailError = submissionErrors?.email

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return { ...userInfo, ...userAddressInfo }
      }, [userInfo, userAddressInfo])
    }
  })

  useEffect(() => {
    reset({ ...userInfo, ...userAddressInfo })
  }, [userInfo, userAddressInfo, reset])

  const phoneValue = watch('phoneNumber')
  const cnpjValue = watch('cnpj')
  const cepValue = watch('cep')
  const numberValue = watch('streetNumber')

  useEffect(() => {
    if (phoneValue) setValue('phoneNumber', normalizePhoneNumber(phoneValue))
    if (cnpjValue) setValue('cnpj', normalizeCNPJ(cnpjValue))
    if (cepValue) setValue('cep', normalizeCEP(cepValue))
    setValue('streetNumber', numberValue?.replace(/[\D]/g, ''))
  }, [phoneValue, cnpjValue, cepValue, numberValue, setValue])

  useEffect(() => {
    if (userInfo) {
      setUserData(Object.keys(userInfo))
    }
  }, [userInfo])

  useEffect(() => {
    if (userAddressInfo) {
      setAddressInfo(Object.keys(userAddressInfo))
    }
  }, [userAddressInfo])

  async function handleSignUp (data) {
    setIsLoading(true)
    data.cep = clearMask(data.cep)
    data.phoneNumber = clearMask(data.phoneNumber)
    data.cnpj = clearMask(data.cnpj)

    const {
      email,
      password,
      name,
      phoneNumber,
      cnpj,
      cep,
      city,
      uf,
      district,
      street,
      streetNumber,
      complement
    } = data

    const petshop = {
      user: { email, password, name, phoneNumber, cnpj },
      address: { cep, city, uf, district, street, streetNumber, complement }
    }

    try {
      const signUpResponse = await signUp(petshop)
      toast.success('Usuário criado com sucesso!')

      const petshopData = JSON.parse(signUpResponse.request.response)

      const loginResponse = await login({ email, password })
      const authorization = loginResponse?.headers?.authorization
      const auth = loginResponse?.data?.auth
      const credentials = { user: email, token: authorization, auth }

      const planResponse = await getPetshopPlan(authorization)

      dispatch(setCredentials(credentials))
      dispatch(setPetshop(petshopData))
      dispatch(setMenuState({ menuState: { state: 'initial', index: 0 } }))
      dispatch(setCurrentPlan({ currentPlan: planResponse.data }))

      navigate('/menu')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='overview-content'>
      <form onSubmit={handleSubmit(handleSignUp)}>
        {userData
          .filter(key => fieldsLabel[key])
          .map(key => (
            <div className='input-field' key={key}>
              <label htmlFor={key + '-input'}>{fieldsLabel[key]}</label>
              <Input
                placeholder={fieldsLabel[key]}
                name={key}
                type={key.includes('password') ? 'password' : 'text'}
                classes='form-input'
                isValid={
                  emailError && key === 'email'
                    ? false
                    : errors[key] === undefined
                }
                {...register(key)}
              />
              <div className='error-container'>
                <span className='field-error'>
                  {emailError && key === 'email'
                    ? emailError
                    : errors[key]?.message}{' '}
                </span>
              </div>
            </div>
          ))}
        {addressInfo
          .filter(key => fieldsLabel[key])
          .map(key => (
            <div className='input-field' key={key}>
              <label htmlFor={key + '-input'}>{fieldsLabel[key]}</label>
              <Input
                placeholder={fieldsLabel[key]}
                name={key}
                type='text'
                classes='form-input'
                isValid={errors[key] === undefined}
                {...register(key)}
              />
              <div className='error-container'>
                <span className='field-error'>{errors[key]?.message}</span>
              </div>
            </div>
          ))}
        {submissionErrors?.serverError && (
          <span className='field-error'>{submissionErrors.serverError}</span>
        )}
        <Button
          type='submit'
          text='Cadastrar'
          classes='primary-button'
          isLoading={isLoading}
        />
      </form>
    </div>
  )
}
