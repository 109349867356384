import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import MenuHeader from '../../../components/MenuHeader'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { createPetshopClient } from '../../../services/api/apiCalls'
import ClientForm from '../ClientForm'

import './style.css'

export default function AddClientPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const handleAddClient = async data => {
    setIsLoading(true)

    const {
      additionalPhoneNumber,
      canScheduleThroughApp,
      email,
      name,
      notes,
      pets,
      phoneNumber,
      pickUpAndTakePrice,
      pickUpAndTakeTime
    } = data
    const formattedPets = pets.map(pet => {
      const { bodySize, furSize, extraTime, name, breed } = pet
      const petNotes = pet.notes

      return {
        bodySize: bodySize.value,
        furSize: furSize.value,
        name,
        breed,
        extraTime,
        ...(petNotes && { notes: petNotes })
      }
    })

    const user = {
      email,
      name,
      phoneNumber: phoneNumber.replace(/[\D]/g, ''),
      additionalPhoneNumber: additionalPhoneNumber.replace(/[\D]/g, '')
    }
    const deliveryValue = pickUpAndTakePrice
      ? pickUpAndTakePrice?.replace(/[\D]/g, '')
      : '0'
    const deliveryTime = pickUpAndTakeTime
      ? pickUpAndTakeTime?.replace(/[\D]/g, '')
      : '0'
    const cep = data?.address?.cep?.replace(/[\D]/g, '')

    const address = { ...data?.address, deliveryValue, deliveryTime, cep }
    const clientInfo = { ...(notes && { notes }), canScheduleThroughApp }

    const client = { user, clientInfo, pets: formattedPets }

    if (address.cep) {
      client.address = address
    }

    try {
      const response = await createPetshopClient(authToken, client)
      toast.success('Cliente criado com sucesso!')
      if (location.state?.scheduleInfo) {
        navigate('/consultar-horarios', {
          state: {
            ...location.state.scheduleInfo,
            client: response.data
          }
        })
        return
      }
      navigate('/meus-clientes')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='add-client-page-container'>
      <div className='add-client-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Adicionar Cliente'
          buttonText='Voltar'
          navigateTo='-1'
        />
        <div className='add-client-content'>
          <ClientForm
            formSubmitFunction={data => handleAddClient(data)}
            defaultFormValues={{ canScheduleThroughApp: true }}
            action='add'
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
