import React, { useEffect, useState } from 'react'

import './style.css'
import { ReactComponent as CircleCheckIcon } from '../../assets/images/icons/circleCheck.svg'
import { ReactComponent as CircleUncheckIcon } from '../../assets/images/icons/circleUncheck.svg'

const BooleanCheckbox = React.forwardRef(
  (
    {
      id,
      firstOption,
      secondOption,
      className = '',
      defaultValue = false,
      setValue,
      field,
      secondOptionAction,
      ...props
    },
    ref
  ) => {
    const [checkboxState, setCheckboxState] = useState({
      checked: defaultValue,
      disabled: true
    })

    useEffect(() => {
      setCheckboxState({
        checked: defaultValue,
        disabled: true
      })
    }, [defaultValue])

    return (
      <div className={'boolean-checkbox ' + className}>
        <input
          type='checkbox'
          id={id}
          ref={ref}
          disabled={checkboxState.disabled}
        />
        <label
          htmlFor={id}
          className='boolean-checkbox-container'
          onClick={() => {
            if (checkboxState.checked) {
              setCheckboxState({ checked: false, disabled: false })
              setValue(field, false)
            }
            if (!checkboxState.disabled) {
              setCheckboxState(current => ({ ...current, disabled: true }))
            }
          }}
        >
          {!checkboxState.checked ? <CircleCheckIcon /> : <CircleUncheckIcon />}
          <span className='boolean-checkbox-title'>{firstOption}</span>
        </label>
        <label
          htmlFor={id}
          className='boolean-checkbox-container'
          onClick={() => {
            if (!checkboxState.checked) {
              if (secondOptionAction) {
                secondOptionAction()
              } else {
                setCheckboxState({ checked: true, disabled: false })
                setValue(field, true)
              }
            }
            if (!checkboxState.disabled) {
              setCheckboxState(current => ({ ...current, disabled: true }))
            }
          }}
        >
          {checkboxState.checked ? <CircleCheckIcon /> : <CircleUncheckIcon />}
          <span className='boolean-checkbox-title'>{secondOption}</span>
        </label>
      </div>
    )
  }
)

export default BooleanCheckbox
