import React, { useEffect, useMemo, useState } from 'react'
import { bool, object, string, array, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import './style.css'
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg'
import { ReactComponent as UnCheckIcon } from '../../../assets/images/icons/unCheck.svg'
import { ReactComponent as AddIcon } from '../../../assets/images/icons/add.svg'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import {
  normalizePhoneNumber,
  normalizeCEP,
  normalizePrice
} from '../../../services/utils/masks'
import ufValidator from '../../../services/utils/validators/ufValidator'
import { cepSearch } from '../../../services/externalAPI/cep'
import TwoStepsRemove from '../../../components/TwoStepsRemove'
import { toast } from 'react-toastify'
import CustomSelect from '../../../components/CustomSelect'
import {
  furOptions,
  sizeOptions
} from '../../../services/utils/helpers/petOptions'

const clientValidationSchema = object().shape({
  name: string().required('Nome é um campo obrigatório.'),
  phoneNumber: string()
    .required('Telefone principal é um campo obrigatório.')
    .min(14, 'Formato incorreto'),
  additionalPhoneNumber: string()
    .notRequired()
    .nullable(),
  email: string()
    .email('Formato incorreto de email!')
    .required('Email é um campo obrigatório.'),
  notes: string()
    .notRequired()
    .nullable(),
  canScheduleThroughApp: bool().notRequired(),
  pickUpAndTakePrice: string().when('address', {
    is: address => address !== undefined,
    then: string().required('Preencha ambos os campos de Leva e Traz.'),
    otherwise: string()
  }),
  pickUpAndTakeTime: string().when('address', {
    is: address => address !== undefined,
    then: string().required('Preencha ambos os campos de Leva e Traz.'),
    otherwise: string()
  }),
  address: object({
    cep: string().required('cep é um campo obrigatório'),
    city: string().required('Cidade é um campo obrigatório'),
    uf: string()
      .required('Estado é um campo obrigatório')
      .matches(/^[A-z]+$/, 'Formato incorreto')
      .test('is-valid-uf', 'Estado não existente', value =>
        value === undefined ? true : ufValidator(value)
      ),
    district: string().required('Bairro é um campo obrigatório'),
    street: string().required('Rua é um campo obrigatório'),
    streetNumber: string().required('Número é um campo obrigatório'),
    complement: string()
  }).default(undefined),
  pets: array()
    .of(
      object({
        name: string().required('Nome do pet é um campo obrigatório'),
        breed: string(),
        bodySize: object({
          label: string().required('Porte do Pet é um campo obrigatório.'),
          value: string().required('Porte do Pet é um campo obrigatório.')
        }).nullable(),
        furSize: object({
          label: string().required('Pelo do Pet é um campo obrigatório.'),
          value: string().required('Pelo do Pet é um campo obrigatório.')
        }).nullable(),
        notes: string().nullable(),
        extraTime: number()
          .typeError('Informe se será preciso tempo extra.')
          .required('Informe se será preciso tempo extra.')
          .default(0),
        id: number()
          .nullable()
          .notRequired()
      })
    )
    .min(1, 'Insira ao menos um pet.')
})

export default function ClientForm ({
  formSubmitFunction,
  action,
  defaultFormValues,
  isLoading,
  ...props
}) {
  const {
    register,
    control,
    watch,
    setValue,
    setError,
    unregister,
    clearErrors,
    reset,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(clientValidationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return defaultFormValues
      }, [defaultFormValues])
    }
  })

  const { fields, append, remove } = useFieldArray({ control, name: 'pets' })

  useEffect(() => {
    if (defaultFormValues) reset(defaultFormValues)
  }, [defaultFormValues, reset])

  const formCheckbox = watch('canScheduleThroughApp')

  const phoneValue = watch('phoneNumber')

  useEffect(() => {
    if (phoneValue) setValue('phoneNumber', normalizePhoneNumber(phoneValue))
  }, [phoneValue, setValue])

  const additionalPhoneNumberValue = watch('additionalPhoneNumber')

  useEffect(() => {
    if (additionalPhoneNumberValue)
      setValue(
        'additionalPhoneNumber',
        normalizePhoneNumber(additionalPhoneNumberValue)
      )
  }, [additionalPhoneNumberValue, setValue])

  const pickAndTakePrice = watch('pickUpAndTakePrice')

  useEffect(() => {
    if (pickAndTakePrice)
      setValue('pickUpAndTakePrice', normalizePrice(pickAndTakePrice))
  }, [setValue, pickAndTakePrice])

  const pickUpAndTakeTime = watch('pickUpAndTakeTime')

  const formatTime = value => {
    value = String(value)
    if (value === undefined) return ''
    const time = value?.replace(/[\D]/g, '')
    if (parseInt(time) > 240) return '240'
    return time
  }

  useEffect(() => {
    if (pickUpAndTakeTime)
      setValue('pickUpAndTakeTime', formatTime(pickUpAndTakeTime))
  }, [setValue, pickUpAndTakeTime])

  const cepValue = watch('address.cep')

  useEffect(() => {
    if (cepValue) setValue('address.cep', normalizeCEP(cepValue))

    let cep = cepValue
    if (!cep) return

    cep = cep.replace(/[\D]/g, '')
    if (cep.length !== 8) return

    cepSearch(cep)
      .then(data => {
        setValue('address.city', data.city)
        setValue('address.uf', data.state)
        setValue('address.district', data.neighborhood)
        setValue('address.street', data.street)
        clearErrors(['address.cep'])
      })
      .catch(err => {
        setError('address.cep', {
          type: 'custom',
          message: 'cep não encontrado'
        })
      })
  }, [cepValue, clearErrors, setError, setValue])

  const [showAdressFields, setShowAddressFields] = useState(false)

  const addressValue = watch('address')

  const renderAddressCard = () => {
    return (
      <Card classes='address-card'>
        <div className='card-header'>
          <h3>Endereço</h3>
          {!showAdressFields && !addressValue ? (
            <AddIcon onClick={() => setShowAddressFields(true)} />
          ) : (
            <TwoStepsRemove
              icon='remove'
              removeFunction={() => {
                setShowAddressFields(false)
                unregister('address')
              }}
            />
          )}
        </div>
        <div className='card-content'>
          {showAdressFields || addressValue ? (
            <div className='address-input-fields'>
              <div className='address-input-container cep'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.cep-input'
                >
                  cep
                </label>
                <Input
                  type='text'
                  placeholder='00000-000'
                  {...register('address.cep')}
                  isValid={errors?.address?.cep === undefined}
                />
                <span
                  className={
                    errors?.address?.cep?.message
                      ? 'add-client-field-error'
                      : ''
                  }
                >
                  {errors?.address?.cep?.message}
                </span>
              </div>
              <div className='address-input-container state'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.uf-input'
                >
                  Estado
                </label>
                <Input
                  type='text'
                  placeholder='Ex: AC'
                  {...register('address.uf')}
                  isValid={errors?.address?.uf === undefined}
                />
                <span
                  className={
                    errors?.address?.uf?.message ? 'add-client-field-error' : ''
                  }
                >
                  {errors?.address?.uf?.message}
                </span>
              </div>
              <div className='address-input-container city'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.city-input'
                >
                  Cidade
                </label>
                <Input
                  type='text'
                  placeholder=''
                  {...register('address.city')}
                  isValid={errors?.address?.city === undefined}
                />
                <span
                  className={
                    errors?.address?.city?.message
                      ? 'add-client-field-error'
                      : ''
                  }
                >
                  {errors?.address?.city?.message}
                </span>
              </div>
              <div className='address-input-container district'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.district-input'
                >
                  Bairro
                </label>
                <Input
                  type='text'
                  placeholder=''
                  {...register('address.district')}
                  isValid={errors?.address?.district === undefined}
                />
                <span
                  className={
                    errors?.address?.district?.message
                      ? 'add-client-field-error'
                      : ''
                  }
                >
                  {errors?.address?.district?.message}
                </span>
              </div>
              <div className='address-input-container street'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.street-input'
                >
                  Rua
                </label>
                <Input
                  type='text'
                  placeholder=''
                  {...register('address.street')}
                  isValid={errors?.address?.street === undefined}
                />
                <span
                  className={
                    errors?.address?.street?.message
                      ? 'add-client-field-error'
                      : ''
                  }
                >
                  {errors?.address?.street?.message}
                </span>
              </div>
              <div className='address-input-container number'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.streetNumber-input'
                >
                  Número
                </label>
                <Input
                  type='text'
                  placeholder=''
                  {...register('address.streetNumber')}
                  isValid={errors?.address?.streetNumber === undefined}
                />
                <span
                  className={
                    errors?.address?.streetNumber?.message
                      ? 'add-client-field-error'
                      : ''
                  }
                >
                  {errors?.address?.streetNumber?.message}
                </span>
              </div>
              <div className='address-input-container address-detail'>
                <label
                  className='address-input-label mb-8'
                  htmlFor='address.complement-input'
                >
                  Complemento
                </label>
                <Input
                  type='text'
                  placeholder=''
                  {...register('address.complement')}
                  isValid={errors?.address?.complement === undefined}
                />
                <span
                  className={
                    errors?.address?.complement?.message
                      ? 'add-client-field-error'
                      : ''
                  }
                >
                  {errors?.address?.complement?.message}
                </span>
              </div>
            </div>
          ) : (
            <span className='empty-card'>
              Sem endereços adicionados. Clique no botão ao lado para adicionar,
              se quiser cadastrar o serviço de <strong>leva e traz.</strong>
            </span>
          )}
        </div>
      </Card>
    )
  }

  const handleAddPet = () => {
    append({
      name: '',
      breed: '',
      bodySize: {},
      furSize: {},
      notes: '',
      extraTime: 0
    })
  }

  const handleRemovePet = index => {
    remove(index)
  }

  const renderPets = () => {
    return (
      <Card classes='pets-card'>
        <div className='card-header'>
          <h3>Pets</h3>
          <AddIcon
            onClick={() => {
              handleAddPet()
            }}
          />
        </div>
        <div className='card-content'>
          {fields?.length !== 0 ? (
            fields?.map((pet, index) => {
              const fieldName = `pets[${index}]`
              return (
                <div className='pet-input-fields' key={index}>
                  <div className='remove-button'>
                    <TwoStepsRemove
                      icon='remove'
                      removeFunction={() => handleRemovePet(index)}
                    />
                  </div>
                  <div className='name'>
                    <label
                      className='pet-input-label mb-8'
                      htmlFor={`${fieldName}.name`}
                    >
                      Nome*
                    </label>
                    <Input
                      type='text'
                      placeholder=''
                      {...register(fieldName + '.name')}
                    />
                    <span
                      className={
                        errors?.pets && errors?.pets[index]?.name?.message
                          ? 'add-client-field-error'
                          : ''
                      }
                    >
                      {errors?.pets && errors?.pets[index]?.name?.message}
                    </span>
                  </div>
                  <div className='breed'>
                    <label
                      className='pet-input-label mb-8'
                      htmlFor={`${fieldName}.breed`}
                    >
                      Raça
                    </label>
                    <Input
                      type='text'
                      placeholder=''
                      {...register(fieldName + '.breed')}
                    />
                    <span
                      className={
                        errors?.pets && errors?.pets[index]?.breed?.message
                          ? 'add-client-field-error'
                          : ''
                      }
                    >
                      {errors?.pets && errors?.pets[index]?.breed?.message}
                    </span>
                  </div>
                  <div className='size'>
                    <label
                      className='pet-input-label mb-8'
                      htmlFor={`${fieldName}.bodySize`}
                    >
                      Porte*
                    </label>
                    <Controller
                      name={`${fieldName}.bodySize`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          field={{
                            name: field.name,
                            onChange: field.onChange,
                            onBlur: field.onBlur,
                            ref: field.ref,
                            ...(field.value?.label && { value: field.value })
                          }}
                          options={sizeOptions}
                          placeholder='Selecionar Porte'
                        />
                      )}
                    />
                    <span
                      className={
                        errors?.pets &&
                        errors?.pets[index]?.bodySize?.label?.message
                          ? 'add-client-field-error'
                          : ''
                      }
                    >
                      {errors?.pets &&
                        errors?.pets[index]?.bodySize?.label?.message}
                    </span>
                  </div>
                  <div className='fur'>
                    <label
                      className='pet-input-label mb-8'
                      htmlFor={`${fieldName}.furSize`}
                    >
                      Pelo*
                    </label>
                    <Controller
                      name={fieldName + '.furSize'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <CustomSelect
                            field={{
                              name: field.name,
                              onChange: field.onChange,
                              onBlur: field.onBlur,
                              ref: field.ref,
                              ...(field.value?.label && { value: field.value })
                            }}
                            options={furOptions}
                            placeholder='Selecionar Pelo'
                          />
                        )
                      }}
                    />
                    <span
                      className={
                        errors?.pets &&
                        errors?.pets[index]?.furSize?.label?.message
                          ? 'add-client-field-error'
                          : ''
                      }
                    >
                      {errors?.pets &&
                        errors?.pets[index]?.furSize?.label?.message}
                    </span>
                  </div>
                  <div className='comments'>
                    <label className='pet-input-label mb-8'>
                      Observações{' '}
                      <span>
                        (Não será exibido para o cliente, somente você
                        visualiza)
                      </span>
                    </label>
                    <textarea
                      rows='4'
                      {...register(fieldName + '.notes')}
                      className='pet-comments'
                    />
                    <span
                      className={
                        errors?.pets && errors?.pets[index]?.notes?.message
                          ? 'add-client-field-error'
                          : ''
                      }
                    >
                      {errors?.pets && errors?.pets[index]?.notes?.message}
                    </span>
                  </div>

                  <div className='extra-time'>
                    <label className='pet-input-label mb-8'>
                      Tempo Extra Necessário{' '}
                      <span>
                        (Caso leve mais tempo do que o comum para o porte e
                        pelo, o valor final de todos os serviços aumentará
                        conforme o selecionado)
                      </span>
                    </label>
                    <div className='radio-input'>
                      <input
                        type='radio'
                        id={`0${index}`}
                        name='extraTime'
                        value={0}
                        {...register(`${fieldName}.extraTime`)}
                        defaultChecked={pet.extraTime === 0}
                      />
                      <label htmlFor={`0${index}`}>Não</label>
                      <input
                        type='radio'
                        id={`10${index}`}
                        name='extraTime'
                        value={10}
                        {...register(`${fieldName}.extraTime`)}
                        defaultChecked={pet.extraTime === 10}
                      />
                      <label htmlFor={`10${index}`}>10%</label>
                      <input
                        type='radio'
                        id={`20${index}`}
                        name='extraTime'
                        value={20}
                        {...register(`${fieldName}.extraTime`)}
                        defaultChecked={pet.extraTime === 20}
                      />
                      <label htmlFor={`20${index}`}>20%</label>
                      <input
                        type='radio'
                        id={`30${index}`}
                        name='extraTime'
                        value={30}
                        {...register(`${fieldName}.extraTime`)}
                        defaultChecked={pet.extraTime === 30}
                      />
                      <label htmlFor={`30${index}`}>30%</label>
                    </div>
                    <span
                      className={
                        errors?.pets && errors?.pets[index]?.extraTime?.message
                          ? 'radio-input-error'
                          : ''
                      }
                    >
                      {errors?.pets && errors?.pets[index]?.extraTime?.message}
                    </span>
                  </div>
                </div>
              )
            })
          ) : (
            <span className='empty-card'>
              Sem pets adicionados. Clique no botão ao lado para adicionar.
            </span>
          )}
        </div>
      </Card>
    )
  }

  const handleFormSubmit = data => {
    if (!isDirty) {
      toast.warning('Faça alguma alteração para poder atualizar o cliente!')
    } else {
      formSubmitFunction(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className='client-form'>
      <div
        className={`client-inputs ${
          defaultFormValues ? 'client-edit-form' : ''
        }`}
      >
        <div className='client-input-container'>
          <label className='client-input-label mb-8' htmlFor='name-input'>
            Nome*
          </label>
          <Input
            type='text'
            placeholder='Nome Cliente'
            {...register('name')}
            isValid={errors.name === undefined}
          />
          <span
            className={errors?.name?.message ? 'add-client-field-error' : ''}
          >
            {errors?.name?.message}
          </span>
        </div>
        <div className='client-input-container'>
          <label
            className='client-input-label  mb-8'
            htmlFor='phoneNumber-input'
          >
            Telefone Principal*
          </label>
          <Input
            type='text'
            placeholder='(99) 99999-9999'
            {...register('phoneNumber')}
            isValid={errors.phoneNumber === undefined}
          />
          <span
            className={
              errors?.phoneNumber?.message ? 'add-client-field-error' : ''
            }
          >
            {errors?.phoneNumber?.message}
          </span>
        </div>
        <div className='client-input-container'>
          <label
            className='client-input-label  mb-8'
            htmlFor='additionalPhoneNumber-input'
          >
            Telefone Adicional
          </label>
          <Input
            type='text'
            {...register('additionalPhoneNumber')}
            isValid={errors.additionalPhoneNumber === undefined}
          />
          <span
            className={
              errors?.additionalPhoneNumber?.message
                ? 'add-client-field-error'
                : ''
            }
          >
            {errors?.additionalPhoneNumber?.message}
          </span>
        </div>
        <div className='client-input-container'>
          <label className='client-input-label mb-24' htmlFor='email-input'>
            E-mail*
          </label>
          <Input
            type='text'
            {...register('email')}
            isValid={errors.email === undefined}
          />
          <span
            className={errors?.email?.message ? 'add-client-field-error' : ''}
          >
            {errors?.email?.message}
          </span>
        </div>
        <div className='client-input-container'>
          <label className='client-input-label mb-8' htmlFor='client-remarks'>
            Observações do Cliente{' '}
            <span>
              (Não será exibido para o cliente, somente você visualiza)
            </span>
          </label>
          <textarea id='client-remarks' {...register('notes')} rows='4' />
          <span
            className={errors?.notes?.message ? 'add-client-field-error' : ''}
          >
            {errors?.notes?.message}
          </span>
        </div>
        <div className='client-input-container'>
          <input
            type='checkbox'
            id='custom-checkbox'
            {...register('canScheduleThroughApp')}
          />
          <label htmlFor='custom-checkbox' className='checkbox-container'>
            {formCheckbox ? <UnCheckIcon /> : <CheckIcon />}
            <span className='checkbox-title'>
              Cliente não pode fazer o agendamento pelo App{' '}
              <span className='checkbox-detail'>
                (Marque essa opção se seu cliente tiver alguma particularidade
                que necessite que você mesmo escolha o horário do atendimento)
              </span>
            </span>
          </label>
        </div>
        {renderAddressCard()}
        <div className='client-input-container'>
          <label
            className='client-input-label  mb-8'
            htmlFor='pickUpAndTakePrice-input'
          >
            Valor Leva e Traz <span>(para o endereço cadastrado acima)</span>
          </label>
          <Input
            type='text'
            placeholder='R$ 00,00'
            {...register('pickUpAndTakePrice')}
            isValid={errors.pickUpAndTakePrice === undefined}
          />
          <span
            className={
              errors?.pickUpAndTakePrice?.message
                ? 'add-client-field-error'
                : ''
            }
          >
            {errors?.pickUpAndTakePrice?.message}
          </span>
        </div>
        <div className='client-input-container'>
          <label
            className='client-input-label mb-8'
            htmlFor='pickUpAndTakeTime-input'
          >
            Tempo Leva e Traz <span>(para o endereço cadastrado acima)</span>
          </label>
          <div className='time-input'>
            <Input
              type='text'
              placeholder='00'
              defaultValue={defaultFormValues?.pickUpAndTakeTime}
              {...register('pickUpAndTakeTime')}
              isValid={errors.pickUpAndTakeTime === undefined}
            />
            <span>Minutos</span>
          </div>
          <span
            className={
              errors?.pickUpAndTakeTime?.message ? 'add-client-field-error' : ''
            }
          >
            {errors?.pickUpAndTakeTime?.message}
          </span>
        </div>
        {renderPets()}
        <span className={errors?.pets?.message ? 'add-client-field-error' : ''}>
          {errors?.pets?.message}
        </span>
      </div>
      <Button
        text={defaultFormValues ? 'Salvar Cliente' : 'Adicionar Cliente'}
        classes={`primary-button add-button ${defaultFormValues &&
          'fixed-button'}`}
        type='submit'
        isLoading={isLoading}
      />
    </form>
  )
}
