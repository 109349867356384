import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import './style.css'
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg'
import { ReactComponent as PasswordIcon } from '../../../assets/images/icons/lock.svg'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Header from '../../../components/Header'

import {
  getPetshopInfo,
  getPetshopPlan,
  login,
  getWorkerInfo,
  workerGetPetshopPlan
} from '../../../services/api/apiCalls'

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../features/auth/authSlice'
import {
  setMenuState,
  setPetshop
} from '../../../features/petshop/petshopSlice'
import { setCurrentPlan } from '../../../features/plan/planSlice'
import { setWorker } from '../../../features/worker/workerSlice'
import SessionExpiredPopup from '../../../components/SessionExpiredPopup'
import { toast } from 'react-toastify'

const validationSchema = object({
  email: string()
    .email('Formato incorreto de email!')
    .required('E-mail é campo obrigatório'),
  password: string().required('Senha é campo obrigatório')
})

export default function LoginPage (props) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) })

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  async function handleLogin (data) {
    setIsLoading(true)

    try {
      const response = await login(data)
      const authorization = response?.headers?.authorization
      const auth = response?.data?.auth
      const credentials = { user: data.email, token: authorization, auth }

      if (auth === 50) {
        const petshopResponse = await getPetshopInfo(authorization)
        const petshopData = petshopResponse.data
        dispatch(setPetshop(petshopData))

        // TODO: in the future, check what can be showed to user so that he can have the tutorial anywhere he logs in.
        // In this way, when he logs in other device, the tutorial is gone, even though he has not finished it previously
        dispatch(setMenuState({ menuState: { state: 'complete', index: 8 } }))

        const planResponse = await getPetshopPlan(authorization)
        dispatch(setCurrentPlan({ currentPlan: planResponse.data }))
      } else if (auth === 40) {
        const workerResponse = await getWorkerInfo(authorization)
        const workerData = workerResponse.data
        dispatch(setWorker(workerData))
        dispatch(setMenuState({ menuState: { state: 'complete', index: 8 } }))

        // For now the worker will have only one petshop
        const planResponse = await workerGetPetshopPlan({
          authToken: authorization,
          petshopId: workerData?.petshops[0]?.id
        })
        dispatch(setCurrentPlan({ currentPlan: planResponse.data }))
      }

      if (auth !== 40 && auth !== 50) {
        toast.warning(
          'Esse não é o aplicativo para o cliente! Baixe diretamente da sua app store!'
        )
        // TODO: ADD REDIRECT
      } else {
        dispatch(setCredentials(credentials))
        if (location.state?.from?.pathname) {
          navigate(location.state?.from?.pathname)
        } else {
          navigate('/menu')
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const [showExpiredPopup, setShowExpiredPopup] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('isTokenExpired') === '1') {
      setShowExpiredPopup(true)
      localStorage.removeItem('isTokenExpired')
    }
  }, [])

  return (
    <>
      <div className='login-page-container bg-color-identity-1'>
        <div className='login-page-inner-container'>
          <Header />
          <div className='login-page-content'>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className='email-container'>
                <Input
                  placeholder='E-mail'
                  name='email'
                  type='text'
                  isValid={errors.email === undefined}
                  {...register('email')}
                >
                  <UserIcon className='user-icon' />
                </Input>
                <span className='field-error'>{errors?.email?.message}</span>
              </div>
              <div className='password-container'>
                <Input
                  placeholder='Senha'
                  name='password'
                  type='password'
                  isValid={errors.password === undefined}
                  {...register('password')}
                >
                  <PasswordIcon className='password-icon' />
                </Input>
                <span className='field-error'>{errors?.password?.message}</span>
              </div>
              <span className='span-forget-password'>
                <Link to='/recuperar-senha'>Esqueci a senha</Link>
              </span>
              <Button
                type='submit'
                text='Entrar'
                classes={`primary-button`}
                isLoading={isLoading}
              />
            </form>
            <span className='span-sign-up'>
              Ainda não possui conta? <Link to='/cadastro'>Clique aqui</Link> e
              cadastre-se para testar gratuitamente por 15 dias!
            </span>
          </div>
        </div>
      </div>
      {showExpiredPopup && (
        <SessionExpiredPopup closeFunction={() => setShowExpiredPopup(false)} />
      )}
    </>
  )
}
