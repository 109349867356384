import React from 'react'

import './style.css'
import { ReactComponent as MainLogo } from '../../assets/images/logos/full-name-blue-white.svg'

export default function Header (props) {
  return (
    <div className='header'>
      <MainLogo className='logo' />
    </div>
  )
}
