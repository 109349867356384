import { normalizePrice } from '../masks'

const periodCycle = {
  monthly: { label: 'Mensal', value: '1' },
  quarterly: { label: 'Trimestral', value: '3' },
  semiannually: { label: 'Semestral', value: '6' },
  annually: { label: 'Anual', value: '12' }
}

const calculeTotalValue = (price, period) => {
  const installments = periodCycle[period].value
  return normalizePrice(parseInt(installments) * price)
}

const calculateDiscount = (monthlyPrice, periodPrice) => {
  const discount = monthlyPrice - periodPrice
  const discountPercentage = parseInt((discount / monthlyPrice) * 100)
  return discountPercentage
}

export { periodCycle, calculateDiscount, calculeTotalValue }
