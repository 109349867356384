import React, { useState, forwardRef } from 'react'

import './style.css'
import Popup from '../../../../components/Popup'
import SearchField from '../../../../components/SearchField'
import ClientPetCard from './ClientPetCard'
import Button from '../../../../components/Button'
import { searchClientsByNameOrPhone } from '../../../../services/utils/helpers/searchClientByNameOrPhone'

const SelectClientPetPopup = (
  { closeFunction, setClientPet, setClientPetIcon, clientsList, ...props },
  ref
) => {
  const [clients, setClients] = useState(clientsList)
  const [clientPetSelected, setClientPetSelected] = useState()
  const [cardIdentifier, setCardIdentifier] = useState('')
  const [clientsNotFound, setClientsNotFound] = useState(false)

  const renderClients = () => {
    return clients.map(client => {
      const resetCards = cardIdentifier && cardIdentifier === client.email
      return (
        <ClientPetCard
          ref={resetCards ? ref : null}
          key={client.email}
          client={client}
          resetCards={resetCards}
          setCardIdentifier={email => {
            setCardIdentifier(email)
          }}
          cardIdentifier={cardIdentifier}
          changeClientPetSelected={clientPet => {
            setClientPetSelected(clientPet)
          }}
        />
      )
    })
  }

  const searchFunction = term => {
    const searchResult = searchClientsByNameOrPhone(clientsList, term)
    if (searchResult.length !== 0) {
      setClients(searchResult)
      setClientsNotFound(false)
    } else {
      setClientsNotFound(true)
    }
  }

  return (
    <Popup
      closeFunction={closeFunction}
      className='select-client-pet-popup popup'
    >
      <div className='select-client-pet-popup-header'>
        <h3 className='select-client-pet-popup-title'>Selecione um cliente</h3>
      </div>
      <div className='select-client-pet-popup-content'>
        <div className='select-client-pet-search-field'>
          <SearchField
            onSearchSubmit={searchFunction}
            clearResults={() => {
              setClients(clientsList)
              setClientsNotFound(false)
            }}
            placeholder='Buscar Cliente por nome ou telefone'
          />
        </div>
        <div className='pets-select-client-pet-popup'>
          {!clientsNotFound ? (
            renderClients()
          ) : (
            <p className='select-client-pet-client-not-found'>
              Nenhum cliente correspondente à sua busca foi encontrado.
            </p>
          )}
        </div>
      </div>
      {clientPetSelected && (
        <Button
          text='Confirmar'
          classes='primary-button'
          onClick={() => {
            setClientPet(clientPetSelected)
            setClientPetIcon('change')
            closeFunction()
          }}
        />
      )}
    </Popup>
  )
}

export default forwardRef(SelectClientPetPopup)
