import React, { useEffect, useState } from 'react'

import './style.css'
import MenuHeader from '../../components/MenuHeader'
import CompanyForm from './CompanyForm'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectPetshopInfo,
  setPetshop
} from '../../features/petshop/petshopSlice'
import { updatePetshopInfo } from '../../services/api/apiCalls'
import { selectCurrentToken } from '../../features/auth/authSlice'
import { toast } from 'react-toastify'
import SkeletonText from '../../skeletonComponents/Text'
import SkeletonButton from '../../skeletonComponents/Button'
import { renderComponentNTimes } from '../../services/utils/helpers/renderComponentNTimes'

export default function PetshopCompanyInformations () {
  const authToken = useSelector(selectCurrentToken)
  const petshopInfo = useSelector(selectPetshopInfo)

  const dispatch = useDispatch()

  const [petshopFormattedData, setPetshopFormattedData] = useState([])

  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    setIsPageLoading(true)
    if (petshopInfo) {
      const { address } = petshopInfo
      setPetshopFormattedData({
        ...petshopInfo,
        address: address[0]
      })
      setIsPageLoading(false)
    }
  }, [petshopInfo])

  const [isLoading, setIsLoading] = useState(false)

  const submitFunction = async data => {
    setIsLoading(true)
    try {
      const response = await updatePetshopInfo(authToken, data)
      toast.success('Dados atualizados com sucesso!')

      const { user, address } = response.data

      dispatch(setPetshop({ ...user, address: [address] }))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderSkeleton = () => {
    const InputSkeleton = () => {
      return (
        <div>
          <SkeletonText
            height={'19px'}
            width='62px'
            rx='8'
            ry='8'
            style={{ borderRadius: '8px' }}
          />
          <SkeletonButton
            height={'37px'}
            style={{ borderRadius: '30px', marginTop: '8px' }}
          />
        </div>
      )
    }

    return (
      <div className='company-form'>
        {renderComponentNTimes(5, InputSkeleton, {})}
        <SkeletonButton
          height={'47px'}
          style={{ borderRadius: '25px', marginTop: '18px' }}
        />
      </div>
    )
  }

  return (
    <div className='company-informations-container'>
      <div className='company-informations-inner-container'>
        <MenuHeader
          buttonText='Voltar'
          title='Dados Empresa'
          classes='floating-left'
          navigateTo='minha-conta'
        />
        <div className='company-informations-content'>
          {isPageLoading ? (
            renderSkeleton()
          ) : (
            <CompanyForm
              company={petshopFormattedData}
              isLoading={isLoading}
              submitFunction={submitFunction}
            />
          )}
        </div>
      </div>
    </div>
  )
}
