import React, { useEffect, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'

import './style.css'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { normalizeCEP } from '../../../../services/utils/masks'
import { cepSearch } from '../../../../services/externalAPI/cep'
import ufValidator from '../../../../services/utils/validators/ufValidator'

const validationSchema = object({
  cep: string().required('CEP é um campo obrigatório'),
  city: string()
    .required('Cidade é um campo obrigatório')
    .trim(),
  uf: string()
    .required('Estado é um campo obrigatório')
    .matches(/^[A-z]+$/, 'Formato incorreto')
    .test('is-valid-uf', 'Estado não existente', value => ufValidator(value)),
  district: string()
    .required('Bairro é um campo obrigatório')
    .trim(),
  street: string()
    .required('Rua é um campo obrigatório')
    .trim(),
  streetNumber: string().required('Número é um campo obrigatório'),
  complement: string().trim()
})

export default function UserAddressInfo ({
  onSent,
  onBack,
  backTrigger,
  userAddressInfo,
  isLoading,
  ...props
}) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return userAddressInfo
      }, [userAddressInfo])
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    reset(userAddressInfo)
  }, [userAddressInfo, reset])

  const onBackCallback = useCallback(
    resp => {
      onBack(resp)
    },
    [onBack]
  )

  useEffect(() => {
    if (backTrigger) onBackCallback(getValues())
  }, [backTrigger, onBackCallback, getValues])

  const cepValue = watch('cep')
  const numberValue = watch('streetNumber')
  const ufValue = watch('uf')

  const loadCEPInfo = useCallback(() => {
    let cep = cepValue
    if (!cep) return

    cep = cep.replace(/[\D]/g, '')
    if (cep.length < 8) return

    cepSearch(cep)
      .then(data => {
        setValue('city', data.city)
        setValue('uf', data.state)
        setValue('district', data.neighborhood)
        setValue('street', data.street)
        clearErrors(['city', 'uf', 'district', 'street', 'cep'])
      })
      .catch(err => {
        setError('cep', { type: 'custom', message: err.message })
      })
  }, [cepValue, clearErrors, setError, setValue])

  useEffect(() => {
    if (cepValue) setValue('cep', normalizeCEP(cepValue))
    setValue('streetNumber', numberValue?.replace(/[\D]/g, ''))
    setValue('uf', ufValue?.replace(/[^A-z]/g, '')?.toUpperCase())
  }, [cepValue, numberValue, ufValue, setValue])

  useEffect(() => {
    loadCEPInfo()
  }, [loadCEPInfo])

  function handleSignUp (data) {
    onSent(data)
  }

  return (
    <div className='user-address-info-container'>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div className='input-field'>
          <label htmlFor='cep-input'>CEP</label>
          <Input
            placeholder='00000-000'
            name='cep'
            type='text'
            classes='form-input'
            isValid={errors.cep === undefined}
            {...register('cep')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.cep?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='city-input'>Cidade</label>
          <Input
            name='city'
            type='text'
            classes='form-input'
            isValid={errors.city === undefined}
            {...register('city')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.city?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='uf-input'>Estado</label>
          <Input
            placeholder='Ex: AC'
            name='uf'
            type='text'
            classes='form-input'
            maxLength='2'
            isValid={errors.uf === undefined}
            {...register('uf')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.uf?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='district-input'>Bairro</label>
          <Input
            name='district'
            type='text'
            classes='form-input'
            isValid={errors.district === undefined}
            {...register('district')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.district?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='street-input'>Rua</label>
          <Input
            name='street'
            type='text'
            classes='form-input'
            isValid={errors.street === undefined}
            {...register('street')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.street?.message}</span>
          </div>
        </div>
        <div className='input-field'>
          <label htmlFor='streetNumber-input'>Número</label>
          <Input
            name='streetNumber'
            type='text'
            classes='form-input'
            isValid={errors.streetNumber === undefined}
            {...register('streetNumber')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.streetNumber?.message}</span>
          </div>
        </div>
        <div className='input-field mb-4'>
          <label htmlFor='address-input'>Complemento</label>
          <Input
            name='complement'
            type='text'
            classes='form-input'
            isValid={errors.complement === undefined}
            {...register('complement')}
          />
          <div className='error-container'>
            <span className='field-error'>{errors?.complement?.message}</span>
          </div>
        </div>

        <Button
          type='submit'
          text='Finalizar Cadastro'
          classes='primary-button'
          isLoading={isLoading}
        />
      </form>

      <p className='redirect-login'>
        Já possui conta? <Link to='/entrar'>Clique aqui</Link> e faça o Login!
      </p>
    </div>
  )
}
