import React, { useEffect } from 'react'

import './style.css'
import Popup from '../Popup'
import FreeSchedules from '../FreeSchedules'

export default function FreeSchedulesPopup ({
  selectedClientPet,
  selectedSchedule,
  setValue,
  resetField,
  field,
  closeFunction,
  handleSubmit,
  buttonTextPopup,
  params,
  schedules,
  setIsSchedulesUpdated,
  ...props
}) {
  useEffect(() => {
    if (!selectedSchedule) {
    }
  }, [selectedSchedule])

  return (
    <Popup closeFunction={closeFunction} className='free-schedule-popup popup'>
      <div className='free-schedule-popup-container'>
        <FreeSchedules
          selectedClientPet={selectedClientPet}
          selectedSchedule={selectedSchedule}
          setValue={setValue}
          resetField={resetField}
          field={field}
          onPopup={true}
          closeFunction={closeFunction}
          handleSubmit={handleSubmit}
          buttonTextPopup={buttonTextPopup}
          serviceId={params.serviceId}
          bodySize={params.bodySize}
          furSize={params.furSize}
          schedules={schedules}
          setIsSchedulesUpdated={setIsSchedulesUpdated}
        />
      </div>
    </Popup>
  )
}
