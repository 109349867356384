import axios from 'axios'
import { toast } from 'react-toastify'
import handleServerError from './handleServerError'

import { store } from '../../store'
import { logOut } from '../../features/auth/authSlice'
import { removePetshop } from '../../features/petshop/petshopSlice'
import { removeCurrentPlan } from '../../features/plan/planSlice'

const API_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://micro-gateway-g3qtboiega-ul.a.run.app/api/v1'
    : 'https://micro-gateway-dev-g3qtboiega-ul.a.run.app/api/v1'

const API = axios.create({
  baseURL: API_BASE_URL
})

API.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const message = handleServerError(error)
    toast.error(message, {
      pauseOnFocusLoss: false
    })
    if (error?.response?.status === 401) {
      store.dispatch(logOut())
      store.dispatch(removePetshop())
      store.dispatch(removeCurrentPlan())
      localStorage.setItem('isTokenExpired', '1')
    }
    throw error
  }
)

export default API

// Local API for test
// const localAPI = axios.create({
//   baseURL: 'http://localhost:4000/api/v1'
// })

// export { localAPI }

// const localAPI2 = axios.create({
//   baseURL: 'http://localhost:5000/api/v1'
// })

// export { localAPI2 }
