import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import './style.css'
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg'
import { ReactComponent as UnCheckIcon } from '../../../assets/images/icons/unCheck.svg'
import MenuHeader from '../../../components/MenuHeader'
import Button from '../../../components/Button'
import DropdownCard from '../../../components/DropdownCard'

import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { getClientServiceHistory } from '../../../services/api/apiCalls'
import {
  bodySizeToString,
  furSizeToString
} from '../../../services/utils/helpers/petHelpers'
import { normalizePrice } from '../../../services/utils/masks'
import SkeletonText from '../../../skeletonComponents/Text'
import SkeletonButton from '../../../skeletonComponents/Button'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'

export default function ClientServicesPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const location = useLocation()
  const { clientId } = useParams()

  const [clientServicesHistory, setClientServicesHistory] = useState([])

  const [pastServicesCheckbox, setPastServicesCheckbox] = useState(true)
  const [upcomingServicesCheckbox, setUpcomingServicesCheckbox] = useState(true)

  const [isPageLoading, setIsPageLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    async function getClientHistory () {
      if (!authToken) return

      setIsPageLoading(true)

      if (location.state?.history) {
        setClientServicesHistory(location.state.history)
        setIsPageLoading(false)
        return
      }

      try {
        const response = await getClientServiceHistory(authToken, clientId)

        setClientServicesHistory(response.data)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getClientHistory()
  }, [clientId, authToken, location.state])

  const pastAndUpcomingServicesQuantity = useCallback(() => {
    const currentDate = new Date()
    const pastQuantity = clientServicesHistory?.filter(service => {
      const serviceDate = new Date(
        service.selectedDay.slice(0, 10).replace(/-/g, '/')
      )
      return currentDate > serviceDate
    }).length

    return {
      pastQuantity,
      upcomingQuantity: clientServicesHistory?.length - pastQuantity
    }
  }, [clientServicesHistory])

  const renderServiceHistoryContent = () => {
    const { pastQuantity, upcomingQuantity } = pastAndUpcomingServicesQuantity()
    return (
      <>
        <div className='client-history-header'>
          <p className='client-history-title'>
            Histórico de Serviços de {location.state?.client?.name}
          </p>
          <div className='service-filters'>
            <input type='checkbox' id='past-services-checkbox' />
            <label
              htmlFor='past-services-checkbox'
              className='service-history-checkbox-container'
              onClick={() => setPastServicesCheckbox(current => !current)}
            >
              {pastServicesCheckbox ? <CheckIcon /> : <UnCheckIcon />}
              <span className='history-checkbox-title'>
                Passados ({pastQuantity})
              </span>
            </label>
            <input type='checkbox' id='upcoming-services-checkbox' />
            <label
              htmlFor='upcoming-services-checkbox'
              className='service-history-checkbox-container'
              onClick={() => setUpcomingServicesCheckbox(current => !current)}
            >
              {upcomingServicesCheckbox ? <CheckIcon /> : <UnCheckIcon />}
              <span className='history-checkbox-title'>
                Próximos ({upcomingQuantity})
              </span>
            </label>
          </div>
        </div>
        <div className='service-history-container'>
          {renderServices()}
          {!pastServicesCheckbox && !upcomingServicesCheckbox && (
            <p className='empty-search'>Nenhum serviço a ser exibido</p>
          )}
        </div>
      </>
    )
  }

  const renderServices = () => {
    return clientServicesHistory
      .filter(service => {
        if (pastServicesCheckbox && upcomingServicesCheckbox) {
          return true
        } else if (!pastServicesCheckbox && !upcomingServicesCheckbox) {
          return false
        } else {
          const currentDate = new Date()
          const serviceDate = new Date(
            service.selectedDay.slice(0, 10).split('-')
          )
          // true if service has already been completed and false otherwise
          const response = currentDate > serviceDate

          return pastServicesCheckbox ? response : !response
        }
      })
      .map((service, index) => {
        const [yyyy, mm, dd] = service.selectedDay.slice(0, 10).split('-')
        return (
          <DropdownCard
            key={index}
            title={`${dd}/${mm}/${yyyy}: ${service.startTime} - ${service.endTime}`}
            readOnly={true}
          >
            <div className='client-service-info'>
              <p>
                <strong>Pet(s): </strong>{' '}
                {`${service.petName} (porte ${bodySizeToString(
                  service.petBodySize
                )} e pelo ${furSizeToString(service.petFurSize)})`}
              </p>
              <p>
                <strong>Serviço: </strong> {service.serviceName}
                {service.deliveryValue ? (
                  <strong> com leva e traz.</strong>
                ) : (
                  '.'
                )}
              </p>
              {service.notes && (
                <p>
                  <strong>Observações do Serviço: </strong>
                  {service.notes}
                </p>
              )}
              {(service.payThroughApp !== 0 ||
                service.payedOutsideApp !== 0) && (
                <p>
                  <strong>Pagamento: </strong>
                  {service.payedOutsideApp
                    ? 'Pago fora do app.'
                    : 'Pago pelo app.'}
                </p>
              )}
              <p>
                <strong>Valor Total: </strong>{' '}
                {normalizePrice(service.totalValue)}
              </p>
              {service?.clientConfirmationTime && (
                <p>
                  {/* TODO: handle format of variable clientConfirmationTime */}
                  <strong>Cliente Confirmou: </strong> Sim, em{' '}
                  {service.clientConfirmationTime}
                </p>
              )}
            </div>
          </DropdownCard>
        )
      })
  }

  const renderSkeleton = () => {
    return (
      <div className='client-services-content'>
        <SkeletonText
          height={'19px'}
          width='50%'
          x={'25%'}
          rx='8'
          ry='8'
          style={{ borderRadius: '8px' }}
        />
        <div className='service-filters' style={{ marginBottom: '20px' }}>
          {renderComponentNTimes(2, SkeletonButton, {
            height: '17px',
            style: {
              borderRadius: '8px',
              margin: 'min(3vh, 24px) 0',
              maxWidth: '115px'
            }
          })}
        </div>
        {renderComponentNTimes(6, SkeletonButton, {
          height: '49px',
          style: {
            borderRadius: '10px',
            marginBottom: 'min(3vh, 24px)'
          }
        })}
      </div>
    )
  }

  return (
    <div className='client-services-page-container'>
      <div className='client-services-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Editar Cliente'
          buttonText='Voltar'
          navigateTo='meus-clientes'
        />

        <div className='client-services-content-header'>
          <Button
            classes='action-button-tertiary'
            text='Dados'
            onClick={() =>
              navigate(`/meus-clientes/editar-cliente/${clientId}`, {
                state: {
                  client: location.state.client,
                  history: clientServicesHistory
                }
              })
            }
          />
          <h3>Serviços</h3>
        </div>

        {isPageLoading ? (
          renderSkeleton()
        ) : (
          <div className='client-services-content'>
            {clientServicesHistory?.length !== 0
              ? renderServiceHistoryContent()
              : `O cliente ainda não tem nenhum serviço em sua linha do tempo.`}
          </div>
        )}
      </div>
    </div>
  )
}
