import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectPetshopConfiguration } from '../../features/petshop/petshopSlice'
import { cancelScheduledClientService } from '../../services/api/apiCalls'

import './style.css'
import Popup from '../Popup'
import Button from '../Button'
import ConfirmationPopup from '../ConfirmationPopup'

const defaultAppointmentCancellationMessage =
  'Infelizmente precisei cancelar após 30 minutos de espera para não atrasar o atendimento dos outros pets!'

export default function CancelAppointmentPopup ({
  closeFunction,
  customMessage,
  cancellationReason,
  isServiceAlreadyPaid,
  authToken,
  appointmentId,
  setRefreshNextAppointments,
  ...props
}) {
  const petshopConfiguration = useSelector(selectPetshopConfiguration)

  const [cancelMessage, setCancelMessage] = useState()
  const [
    showCancellationConfirmationPopup,
    setShowCancellationConfirmationPopup
  ] = useState(false)
  const [isCancellationLoading, setIsCancellationLoading] = useState(false)

  const defaultMessage = customMessage
    ? customMessage
    : defaultAppointmentCancellationMessage

  const cancelAppointment = async () => {
    setIsCancellationLoading(true)

    try {
      await cancelScheduledClientService({
        authToken,
        scheduledClientServiceId: appointmentId,
        data: {
          cancellationMessage: cancelMessage ? cancelMessage : defaultMessage
        }
      })

      setRefreshNextAppointments(true)
      closeFunction()
      toast.success('Atendimento cancelado com sucesso!')
    } catch (error) {
      console.error(error)
    } finally {
      setIsCancellationLoading(false)
    }
  }

  const renderServiceAlreadyPaidMessage = () => {
    return (
      <>
        {petshopConfiguration?.cancellationFee ? (
          <>
            <strong>O serviço já está pago!</strong>
            <br />
            Você possui uma taxa de{' '}
            <strong>
              {petshopConfiguration?.cancellationFee}% de cancelamento
            </strong>{' '}
            configurada, então se desejar, pode devolver apenas uma parte e
            ficar com {petshopConfiguration?.cancellationFee}%.
            <br />
            <br />
            Mas se preferir, pode devolver o valor integral recebido.
            <br />
            <br />
            Lembre-se que do valor total pago <strong>já foi retirada</strong> a
            taxa da nossa <strong>intermediadora (0,99%)</strong> e do valor
            restante a <strong>nossa taxa (1%)</strong>.
            <br />
            <strong>Essas taxas NÃO podem ser devolvidas.</strong>
          </>
        ) : (
          <>
            <strong>O serviço já está pago!</strong>
            <br />
            Você <strong>não</strong> possui uma taxa de cancelamento
            configurada, porém lembre-se que do valor total pago{' '}
            <strong>já foi retirada</strong> a taxa da nossa{' '}
            <strong>intermediadora (0,99%)</strong> e do valor restante a{' '}
            <strong>nossa taxa (1%)</strong>.
            <br />
            <strong>Essas taxas NÃO podem ser devolvidas.</strong>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Popup
        closeFunction={closeFunction}
        className='cancel-appointment popup'
        closeOnClickOut={!showCancellationConfirmationPopup}
      >
        <h3 className='cancel-appointment-title'>Cancelar Atendimento</h3>
        <p className='cancel-appointment-content'>
          {cancellationReason === 'PetshopCancellation' ? (
            <>Tem certeza de que deseja cancelar este atendimento?</>
          ) : (
            <>
              O pet já deveria ter chegado há{' '}
              <strong>pelo menos 20 minutos</strong>. Deseja{' '}
              <strong>cancelar</strong> o atendimento?
            </>
          )}
          <br />
          <br />
          {isServiceAlreadyPaid ? (
            renderServiceAlreadyPaidMessage()
          ) : (
            <>
              {petshopConfiguration?.cancellationFee && (
                <>
                  Caso o serviço já esteja <strong>pago</strong>, você{' '}
                  <strong>
                    poderá receber {petshopConfiguration?.cancellationFee}%
                  </strong>{' '}
                  (a taxa que você definiu para esses casos) do valor{' '}
                  <strong>total</strong> do serviço.
                  <br />
                  Mas se preferir, pode cancelar sem receber a taxa.
                </>
              )}
            </>
          )}
          <br />
          <br />
          Insira abaixo uma <strong>mensagem</strong> informando o cancelamento
          para seu cliente ou deixe em branco para uma mensagem automática:
        </p>
        <textarea
          id='cancel-menssage'
          value={cancelMessage}
          onChange={e => setCancelMessage(e.target.value)}
          placeholder={`Ex: ${defaultMessage}`}
          rows='4'
        />
        <Button
          classes='alert-button cancel-appointment-button'
          text='Cancelar Atendimento'
          onClick={() => {
            setShowCancellationConfirmationPopup(true)
          }}
        />
        {showCancellationConfirmationPopup && (
          <ConfirmationPopup
            closeFunction={() => setShowCancellationConfirmationPopup(false)}
            defaultButtons={false}
          >
            <h3 className='cancel-appointment-confirmation-title'>
              Deseja realmente cancelar o atendimento?
            </h3>
            <div className='cancel-appointment-confirmation-content'>
              <h3>Mensagem de cancelamento:</h3> {'\n'}
              <i>"{cancelMessage ? cancelMessage : defaultMessage}"</i>
            </div>
            <div className='change-page-popup-buttons'>
              <Button
                classes='primary-button btn-sm'
                text='Voltar'
                onClick={() => setShowCancellationConfirmationPopup(false)}
              />
              <Button
                isLoading={isCancellationLoading}
                classes='alert-button'
                text='Continuar'
                onClick={() => {
                  cancelAppointment()
                }}
              />
            </div>
          </ConfirmationPopup>
        )}
      </Popup>
    </>
  )
}
