import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import './style.css'
import Popup from '../../../components/Popup'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import cpfValidator from '../../../services/utils/validators/cpfValidator'
import {
  normalizePhoneNumber,
  normalizeCPF
} from '../../../services/utils/masks'

import { createResponsiblePerson } from '../../../services/api/apiCalls'
import { useDispatch, useSelector } from 'react-redux'
import { setResponsiblePerson } from '../../../features/petshop/petshopSlice'
import { selectCurrentToken } from '../../../features/auth/authSlice'

const validationSchema = object({
  name: string().required('Nome é um campo obrigatório'),
  cpf: string()
    .required('CPF é um campo obrigatório')
    .test('is-valid-cpf', 'CPF inválido', value => cpfValidator(value)),
  phoneNumber: string().required('Telefone é um campo obrigatório')
})

export default function MenuPagePopup ({ onSent, ...props }) {
  const dispatch = useDispatch()
  const authToken = useSelector(selectCurrentToken)

  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) })

  const phoneNumberValue = watch('phoneNumber')
  const cpfValue = watch('cpf')

  useEffect(() => {
    setValue('phoneNumber', normalizePhoneNumber(phoneNumberValue))
    setValue('cpf', normalizeCPF(cpfValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberValue, cpfValue])

  async function handlePersonInChargeRegister (formData) {
    setIsLoading(true)
    onSent()
    try {
      await createResponsiblePerson(authToken, formData)

      toast.success('Responsável criado com sucesso!')
      dispatch(setResponsiblePerson({ responsiblePerson: formData }))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Popup canClose={false} closeOnClickOut={false}>
      <h3 className='popup-title'>Seja bem-vindo!</h3>
      <p className='popup-description'>
        Vamos adicionar o responsável pelo estabelecimento:
      </p>
      <form
        className='popup-form'
        onSubmit={handleSubmit(handlePersonInChargeRegister)}
      >
        <div className='form-content'>
          <div className='input-field'>
            <label htmlFor='name-input'>Nome</label>
            <Input
              placeholder='Nome Responsável'
              name='name'
              type='text'
              isValid={errors.name === undefined}
              {...register('name')}
            />
            <div className='error-container'>
              <span className='field-error'>{errors?.name?.message}</span>
            </div>
          </div>

          <div className='input-field'>
            <label htmlFor='cpf-inpupt'>CPF</label>
            <Input
              placeholder='000.000.000-00'
              name='cpf'
              type='text'
              isValid={errors.cpf === undefined}
              {...register('cpf')}
            />
            <div className='error-container'>
              <span className='field-error'>{errors?.cpf?.message}</span>
            </div>
          </div>

          <div className='input-field'>
            <label htmlFor='phoneNumber-input'>Telefone</label>
            <Input
              placeholder='(99) 99999-9999'
              name='phoneNumber'
              type='text'
              isValid={errors.phoneNumber === undefined}
              {...register('phoneNumber')}
            />
            <div className='error-container'>
              <span className='field-error'>
                {errors?.phoneNumber?.message}
              </span>
            </div>
          </div>
        </div>
        <Button
          type='submit'
          text='Adicionar'
          classes='primary-button'
          isLoading={isLoading}
        />
      </form>
    </Popup>
  )
}
