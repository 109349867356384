import React from 'react'
import Button from '../../../components/Button'
import Popup from '../../../components/Popup'

import './style.css'

export default function InitialPopup ({ close, ...props }) {
  return (
    <Popup canClose={true} closeFunction={close}>
      <h3 className='popup-title'>Pronto!</h3>

      <p className='popup-message'>
        Agora é hora de <strong>explorar</strong> o App!
        <br />
        <strong>Clique</strong> em <strong>tudo</strong> sem medo!
        <br />
        <br />
        As funcionalidades do app vão <strong>aparecer</strong> conforme você
        for <strong>navegando</strong> e <strong>completando</strong> os
        cadastros <strong>necessários</strong>.<br />
        <br />
        Se tiver alguma dúvida, entre em contato com a gente pelo botão do
        suporte.
      </p>
      <div className='popup-btn'>
        <Button
          type='submit'
          text='Começar'
          classes='primary-button'
          onClick={close}
        />
      </div>
    </Popup>
  )
}
