import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import { ReactComponent as AccountIcon } from '../../../assets/images/icons/account.svg'
import Card from '../../../components/Card'
import MenuHeader from '../../../components/MenuHeader'

import { useSelector } from 'react-redux'
import { selectPetshopResponsiblePerson } from '../../../features/petshop/petshopSlice'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { getPetshopWorkers } from '../../../services/api/apiCalls'
import SkeletonText from '../../../skeletonComponents/Text'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'
import SkeletonCard from '../../../skeletonComponents/Card'

export default function SelectEmployeePage ({ ...props }) {
  const responsiblePerson = useSelector(selectPetshopResponsiblePerson)
  const authToken = useSelector(selectCurrentToken)

  const navigate = useNavigate()

  const [employees, setEmployees] = useState([])

  const [isPageloading, setIsPageLoading] = useState(true)

  useEffect(() => {
    async function getWorkers () {
      if (!authToken) return
      setIsPageLoading(true)
      try {
        const response = await getPetshopWorkers(authToken)
        setEmployees(response.data)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getWorkers()
  }, [authToken])

  const renderEmployee = (employee, isResponsiblePerson = false) => {
    return (
      <Card
        onClick={() =>
          navigate(`${!isResponsiblePerson ? employee.id : 'responsavel'}`, {
            state: { name: employee.name, isResponsiblePerson }
          })
        }
        key={employee.id}
      >
        <div className='card-icon'>
          <AccountIcon />
        </div>
        <p className='card-description'>{employee.name}</p>
      </Card>
    )
  }

  function renderEmployees () {
    return employees.map(employee => renderEmployee(employee))
  }

  const renderSkeleton = () => {
    return (
      <div className='select-employee-content'>
        <h4 className='title'>
          <SkeletonText
            lines={1}
            width='50%'
            x='25%'
            rx='8'
            ry='8'
            height={'19px'}
            style={{ borderRadius: '8px' }}
          />
        </h4>
        <div className='employees-container'>
          {renderComponentNTimes(6, SkeletonCard, {
            style: { aspectRatio: 1.2, borderRadius: '10px' }
          })}
        </div>
      </div>
    )
  }

  return (
    <div className='select-employee-page-container'>
      <div className='select-employee-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Horários de Atendimento'
          buttonText='Menu'
          navigateTo='menu'
        />
        {isPageloading ? (
          renderSkeleton()
        ) : (
          <div className='select-employee-content'>
            <h4 className='title'>Selecione o(a) banhista/tosador(a):</h4>
            <div className='employees-container'>
              {responsiblePerson && renderEmployee(responsiblePerson, true)}
              {renderEmployees()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
