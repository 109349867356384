import React, { useState } from 'react'
import './style.css'

import { ReactComponent as VisibilityIcon } from '../../assets/images/icons/visibility.svg'
import { ReactComponent as VisibilityOffIcon } from '../../assets/images/icons/visibilityOff.svg'

const Input = React.forwardRef(
  (
    {
      placeholder,
      name,
      type,
      children,
      isValid = true,
      classes = 'floating-input',
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false)
    const [inputType, setInputType] = useState(type)

    function changeVisibility () {
      if (showPassword) {
        return (
          <VisibilityOffIcon
            onClick={() => {
              setShowPassword(!showPassword)
              setInputType('password')
            }}
          />
        )
      }
      return (
        <VisibilityIcon
          onClick={() => {
            setShowPassword(!showPassword)
            setInputType('text')
          }}
        />
      )
    }

    return (
      <fieldset
        className={classes}
        id={name}
        style={{ border: isValid ? '' : '2px solid var(--input-error)' }}
      >
        <div className='input-container'>
          <div>
            {children}
            <input
              type={inputType}
              placeholder={placeholder}
              name={name}
              autoComplete='true'
              id={`${name}-input`}
              ref={ref}
              {...props}
            />
          </div>
          {type !== 'password' ? '' : changeVisibility()}
        </div>
      </fieldset>
    )
  }
)

export default Input
