import React, { useState } from 'react'

import './style.css'
import { ReactComponent as DownwardIcon } from '../../assets/images/icons/downward.svg'
import { ReactComponent as UpwardIcon } from '../../assets/images/icons/upward.svg'
import { ReactComponent as AlertIcon } from '../../assets/images/icons/exclamation-triangle.svg'
import Card from '../Card'
import Button from '../Button'
import ConfirmationPopup from '../ConfirmationPopup'

export default function DropdownCard ({
  children,
  title,
  removeFunction,
  editFunction,
  removalConfirmationPopup = false,
  objectRemovedMessage = '',
  readOnly = false,
  ...props
}) {
  const [showDetails, setShowDetails] = useState(false)
  const [showConfirmationPopup, setShowConfirmatinPopup] = useState(false)

  const renderServiceCard = () => {
    return (
      <div className={readOnly ? '' : `dropdown-card-content`}>
        {children}
        {!readOnly && (
          <div className='dropdown-card-buttons'>
            <Button
              classes='action-button-tertiary action-button-small'
              text='Editar'
              onClick={() => {
                editFunction()
              }}
            />
            <Button
              classes='alert-button'
              text='Remover'
              onClick={() => setShowConfirmatinPopup(true)}
            />
          </div>
        )}
      </div>
    )
  }

  const closeFunction = () => setShowConfirmatinPopup(false)

  const confirmationAction = () => {
    removeFunction()
    closeFunction()
  }

  return (
    <div className='dropdown-card-container'>
      {showConfirmationPopup && (
        <ConfirmationPopup
          closeFunction={closeFunction}
          confirmationAction={confirmationAction}
          defaultButtons={!removalConfirmationPopup}
        >
          <p className='change-popup-title'>
            {objectRemovedMessage ? (
              <>
                <strong>Tem certeza</strong> que deseja <strong>apagar</strong>{' '}
                {objectRemovedMessage}? <br /> <br />
              </>
            ) : (
              <>
                <strong>Tem certeza</strong> que deseja continuar? <br /> <br />
              </>
            )}
            <span>
              Você não conseguirá recuperar essas informações caso continue.
            </span>
          </p>
          {removalConfirmationPopup && (
            <div className='change-page-popup-buttons'>
              <Button
                classes='primary-button btn-sm'
                text='Cancelar'
                onClick={closeFunction}
              />
              <Button
                classes='alert-button btn-with-icon'
                text='Apagar'
                onClick={confirmationAction}
              >
                <AlertIcon />
              </Button>
            </div>
          )}
        </ConfirmationPopup>
      )}
      <Card classes='dropdown-card'>
        <div
          className='dropdown-card-header'
          onClick={() => setShowDetails(current => !current)}
        >
          <h3>{title}</h3>
          {showDetails ? <UpwardIcon /> : <DownwardIcon />}
        </div>
        {showDetails && renderServiceCard()}
      </Card>
    </div>
  )
}
