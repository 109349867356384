const dateToString = date => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  return dd + '/' + mm
}

const fullDateToString = date => {
  const yyyy = String(date.getFullYear()).padStart(2, '0')
  return dateToString(date) + '/' + yyyy
}

const dateToMMYY = date => {
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yy = String(date.getFullYear())
    .padStart(2, '0')
    .slice(-2)
  return mm + '/' + yy
}

const dateToISOString = date => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = String(date.getFullYear()).padStart(2, '0')
  const HH = String(date.getHours()).padStart(2, '0')
  const min = String(date.getMinutes()).padStart(2, '0')
  const sec = String(date.getSeconds()).padStart(2, '0')
  const ms = String(date.getMilliseconds()).padStart(3, '0')

  return (
    yyyy + '-' + mm + '-' + dd + 'T' + HH + ':' + min + ':' + sec + '.' + ms
  )
}

export { dateToString, fullDateToString, dateToMMYY, dateToISOString }
