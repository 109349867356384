import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'

import './style.css'
import { validateCard } from '../../services/utils/validators/validateCard'
import { dateToMMYY } from '../../services/utils/helpers/dateToString'
import Input from '../Input'
import Button from '../Button'
import { normalizeCard, normalizeDate } from '../../services/utils/masks'

const isValidityValid = validity => {
  const [currentMonth, currentYear] = dateToMMYY(new Date())
    .split('/')
    .map(value => parseInt(value))
  const [validityMonth, validityYear] = validity
    .split('/')
    .map(value => parseInt(value))
  if (validityMonth > 12 || validityMonth <= 0) return false
  if (validityYear > currentYear) return true
  if (validityYear === currentYear && validityMonth >= currentMonth) return true
  return false
}

const validationSchema = object().shape({
  cardNumber: string()
    .required('O número do cartão é obrigatório')
    .test('is-card-valid', 'Número de cartão inválido', value =>
      validateCard(value)
    ),
  validity: string()
    .required('Insira uma data válida')
    .test('is-validity-valid', 'Cartão expirado', value =>
      isValidityValid(value)
    ),
  securityCode: string()
    .required('O código de segurança é obrigatório')
    .min(3, 'O código de segurança é obrigatório'),
  cardHolderName: string().required('Insira o nome presente no cartão')
})

export default function CreditCardForm ({
  onSubmitAction,
  isLoading,
  ...props
}) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) })

  const validity = watch('validity')

  useEffect(() => {
    setValue('validity', normalizeDate(validity))
  }, [validity, setValue])

  const securityCode = watch('securityCode')

  useEffect(() => {
    setValue(
      'securityCode',
      securityCode?.replace(/[\D]/g, '')?.replace(/(\d{4})(\d)/, '$1')
    )
  }, [securityCode, setValue])

  const cardHolderName = watch('cardHolderName')

  useEffect(() => {
    setValue(
      'cardHolderName',
      cardHolderName
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        ?.replace(/[0-9]/g, '')
    )
  }, [cardHolderName, setValue])

  const cardNumber = watch('cardNumber')

  useEffect(() => {
    setValue('cardNumber', normalizeCard(cardNumber))
  }, [setValue, cardNumber])

  return (
    <form onSubmit={handleSubmit(onSubmitAction)} className='credit-card-form'>
      <div className='card-input-container'>
        <label htmlFor='card-number-input'>Número do Cartão</label>
        <div>
          <Input
            type='text'
            name='card-number'
            placeholder='0000 0000 0000 0000'
            isValid={errors?.cardNumber === undefined}
            {...register('cardNumber')}
          />
          <span
            {...(errors?.cardNumber?.message && {
              className: 'add-client-field-error'
            })}
          >
            {errors?.cardNumber?.message}
          </span>
        </div>
      </div>
      <div className='validity-security-code-container'>
        <div className='card-input-container'>
          <label htmlFor='card-validity-input'>Validade</label>
          <div>
            <Input
              type='text'
              name='card-validity'
              placeholder='MM/AA'
              isValid={errors?.validity === undefined}
              {...register('validity')}
            />
            <span
              {...(errors?.validity?.message && {
                className: 'add-client-field-error'
              })}
            >
              {errors?.validity?.message}
            </span>
          </div>
        </div>
        <div className='card-input-container'>
          <label htmlFor='card-security-code-input'>Código Segurança</label>
          <div>
            <Input
              type='text'
              name='card-security-code'
              isValid={errors?.securityCode === undefined}
              {...register('securityCode')}
            />
            <span
              {...(errors?.securityCode?.message && {
                className: 'add-client-field-error'
              })}
            >
              {errors?.securityCode?.message}
            </span>
          </div>
        </div>
      </div>
      <div className='card-input-container'>
        <label htmlFor='cardholder-name-input'>Nome no Cartão</label>
        <Input
          type='text'
          name='cardholder-name'
          placeholder='Nome Completo'
          isValid={errors?.cardHolderName === undefined}
          {...register('cardHolderName')}
        />
        <span
          {...(errors?.cardHolderName?.message && {
            className: 'add-client-field-error'
          })}
        >
          {errors?.cardHolderName?.message}
        </span>
      </div>
      <Button
        text='Adicionar Cartão'
        classes='action-button-tertiary payment-add-data-button'
        isLoading={isLoading}
      />
    </form>
  )
}
