import { isMobile } from 'react-device-detect'
import AppConfig from '../../../config/appConfig'

const createWhatsappLink = number => {
  //leaving only numbers without blanks
  const formattingNumber = number?.replace(/[\D]/g, '')
  if (isMobile) return `https://wa.me/55${formattingNumber}`
  return `https://web.whatsapp.com/send/?phone=55${formattingNumber}`
}

const openWhatsappSales = () => {
  window.open(createWhatsappLink(AppConfig.salesPhoneNumber), '_blank')
}

const openWhatsappSupport = () => {
  window.open(createWhatsappLink(AppConfig.supportPhoneNumber), '_blank')
}

export { createWhatsappLink, openWhatsappSales, openWhatsappSupport }
