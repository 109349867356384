import React, { useState } from 'react'

import './style.css'
import Popup from '../../../../components/Popup'
import SearchField from '../../../../components/SearchField'
import CheckboxCard from '../../../../components/CheckboxCard'
import Button from '../../../../components/Button'

export default function SelectServicesPopup ({
  closeFunction,
  services,
  setValue,
  ...props
}) {
  const [servicesToShow, setServicesToShow] = useState(services)
  const [servicesNotFound, setServicesNotFound] = useState(false)
  const [serviceSelected, setServiceSelected] = useState()

  const renderServices = () => {
    return servicesToShow?.map((service, index) => {
      return (
        <CheckboxCard
          key={index}
          checkboxLabel={service.label}
          checkboxValue={service.value}
          checkFunction={() => setServiceSelected(service)}
          optionSelected={serviceSelected}
        />
      )
    })
  }

  const handleAddServices = () => {
    setValue('service', serviceSelected, { shouldValidate: true })
    closeFunction()
  }

  const searchFunction = term => {
    const newServices = [...services].filter(service => {
      //name without accentuation
      const clearName = service.label
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
      //term without accentuation
      const clearTerm = term
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()

      return clearName.includes(clearTerm)
    })
    if (newServices.length !== 0) {
      setServicesToShow(newServices)
      setServicesNotFound(false)
    } else {
      setServicesNotFound(true)
    }
  }

  return (
    <Popup
      closeFunction={() => closeFunction()}
      className='select-service-popup popup'
    >
      <div className='select-service-popup-header'>
        <h3 className='select-service-popup-title'>Selecione um serviço</h3>
      </div>
      <div className='select-service-popup-content'>
        {services?.length >= 5 && (
          <div className='select-service-search-field'>
            <SearchField
              onSearchSubmit={searchFunction}
              clearResults={() => {
                setServicesToShow(services)
                setServicesNotFound(false)
              }}
              placeholder='Buscar Serviço'
            />
          </div>
        )}
        <div className='select-service-popup-services'>
          {servicesNotFound ? (
            <p className='select-services-not-found-message'>
              Nenhum serviço correspondente à sua busca foi encontrado.
            </p>
          ) : (
            renderServices()
          )}
        </div>
        <div className='select-service-button'>
          <Button
            classes='primary-button'
            text='Confirmar'
            onClick={handleAddServices}
          />
        </div>
      </div>
    </Popup>
  )
}
