import React, { useEffect, useMemo } from 'react'
import { number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import './style.css'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { normalizePercentage } from '../../../../services/utils/masks'
import CustomSelect from '../../../../components/CustomSelect'

//options for the select tag
const options = [
  { value: '1', label: '1 parcela' },
  { value: '2', label: '2 parcelas' },
  { value: '3', label: '3 parcelas' },
  { value: '4', label: '4 parcelas' },
  { value: '5', label: '5 parcelas' },
  { value: '6', label: '6 parcelas' }
]

const validationSchema = object({
  serviceAmount: number()
    .required('Número de serviços é um campo obrigatório.')
    .min(2, 'Número de serviços deve ser no mínimo 2.')
    .typeError('Número de serviços é um campo obrigatório.'),
  discountPercentage: string().required('Desconto é um campo obrigatório.'),
  maxInstallments: object({
    label: string().required('Máximo de parcelas é um campo obrigatório.'),
    value: string().required('Máximo de parcelas é um campo obrigatório.')
  })
    .nullable()
    .required('Máximo de parcelas é um campo obrigatório.')
})

const defaultValues = {
  discountPercentage: '',
  serviceAmount: '',
  maxInstallments: ''
}

export default function PackageForm ({
  formSubmitFunction,
  defaultFormValues = defaultValues,
  action,
  isLoading,
  ...props
}) {
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        const value = defaultFormValues?.maxInstallments
        const formValues = {
          ...defaultFormValues,
          maxInstallments: options[parseInt(value) - 1]
        }
        return formValues
      }, [defaultFormValues])
    }
  })

  useEffect(() => {
    if (action === 'add') return
    const value = defaultFormValues?.maxInstallments
    const formValues = {
      ...defaultFormValues,
      maxInstallments: options[parseInt(value) - 1]
    }
    reset(formValues)
  }, [defaultFormValues, reset, action])

  const handleFormSubmit = data => {
    formSubmitFunction(data)
  }

  const currDiscount = watch('discountPercentage')

  useEffect(() => {
    if (currDiscount) {
      setValue('discountPercentage', normalizePercentage(currDiscount))
    }
  }, [currDiscount, setValue])

  const onBackspace = event => {
    if (event.key === 'Backspace') {
      let value = event.target.value
      value = value.replace(/[\D]/g, '').replace(/.$/, '')
      setValue('discountPercentage', normalizePercentage(value))
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className='package-form'>
      <div className='package-inputs'>
        <div className='package-input-container'>
          <label className='package-input-label' htmlFor='serviceAmount-input'>
            Número de Serviços
          </label>
          <Input
            type='number'
            {...register('serviceAmount')}
            isValid={errors.serviceAmount === undefined}
          />
          <span
            className={
              errors?.serviceAmount?.message ? 'add-package-field-error' : ''
            }
          >
            {errors?.serviceAmount?.message}
          </span>
        </div>
        <div className='package-input-container'>
          <label
            className='package-input-label'
            htmlFor='discountPercentage-input'
          >
            Desconto <span>(em percentual)</span>
          </label>
          <Input
            placeholder='0%'
            type='text'
            {...register('discountPercentage')}
            isValid={errors.discountPercentage === undefined}
            onKeyUp={e => onBackspace(e)}
          />
          <span
            className={
              errors?.discountPercentage?.message
                ? 'add-package-field-error'
                : ''
            }
          >
            {errors?.discountPercentage?.message}
          </span>
        </div>
        <div className='package-select-container'>
          <label className='package-input-label' htmlFor='maxInstallments'>
            Número máximo de parcelas
          </label>
          <Controller
            name='maxInstallments'
            control={control}
            render={({ field }) => (
              <CustomSelect
                field={field}
                placeholder='Clique para selecionar'
                options={options}
              />
            )}
          />
          <span
            className={
              errors?.maxInstallments?.label?.message
                ? 'add-package-field-error'
                : ''
            }
          >
            {errors?.maxInstallments?.label?.message}
          </span>
        </div>
      </div>

      <Button
        text={
          action === 'edit'
            ? 'Salvar Pacote'
            : action === 'add' && 'Adicionar Pacote'
        }
        classes={`primary-button ${action ? 'fixed-button' : ''}`}
        type='submit'
        isLoading={isLoading}
      />
    </form>
  )
}
