import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { object, string, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import './style.css'
import { ReactComponent as AddIcon } from '../../../assets/images/icons/add.svg'
import { ReactComponent as DownwardIcon } from '../../../assets/images/icons/downward.svg'
import { ReactComponent as UpwardIcon } from '../../../assets/images/icons/upward.svg'
import Input from '../../../components/Input'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import TwoStepsRemove from '../../../components/TwoStepsRemove'
import {
  normalizePrice,
  normalizeDuration
} from '../../../services/utils/masks'
import { toast } from 'react-toastify'

const bodySize = ['Pequeno*', 'Médio*', 'Grande*', 'Especial', 'Outro']

const toBodySizeAttribute = bodySize => {
  if (bodySize === 'Pequeno*') return 'small'
  if (bodySize === 'Médio*') return 'medium'
  if (bodySize === 'Grande*') return 'large'
  if (bodySize === 'Especial') return 'special'
  if (bodySize === 'Outro') return 'other'
}

const furSizeYupObjectRequired = object({
  short: string().required('Campo Obrigatório'),
  long: string().required('Campo Obrigatório')
})

const furSizeYupObjectNotRequired = object({
  short: string().notRequired(),
  long: string().notRequired()
})

//TODO: refactor to match backend structure
const validationSchema = object().shape(
  {
    id: number()
      .nullable()
      .notRequired(),
    name: string().required('Nome é um campo obrigatório.'),
    description: string(),
    servicePrices: object().when(['serviceDuration'], serviceDuration => {
      const specialIsRequired =
        serviceDuration?.special?.short !== '' ||
        serviceDuration?.special?.long !== ''
      const otherIsRequired =
        serviceDuration?.other?.short !== '' ||
        serviceDuration?.other?.long !== ''
      return object({
        small: furSizeYupObjectRequired,
        medium: furSizeYupObjectRequired,
        large: furSizeYupObjectRequired,
        special: specialIsRequired
          ? furSizeYupObjectRequired
          : furSizeYupObjectNotRequired,
        other: otherIsRequired
          ? furSizeYupObjectRequired
          : furSizeYupObjectNotRequired
      })
    }),
    serviceDuration: object().when(['servicePrices'], servicePrices => {
      const specialIsRequired =
        servicePrices.special.short !== '' || servicePrices.special.long !== ''
      const otherIsRequired =
        servicePrices.other.short !== '' || servicePrices.other.long !== ''
      return object({
        small: furSizeYupObjectRequired,
        medium: furSizeYupObjectRequired,
        large: furSizeYupObjectRequired,
        special: specialIsRequired
          ? furSizeYupObjectRequired
          : furSizeYupObjectNotRequired,
        other: otherIsRequired
          ? furSizeYupObjectRequired
          : furSizeYupObjectNotRequired
      })
    })
  },
  [['servicePrices', 'serviceDuration']]
)

export default function ServiceForm ({
  formSubmitFunction,
  defaultFormValues = '',
  isLoading,
  setStepsRemoved,
  ...props
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        //validationSchema and the backend return must have the same structure so that the received data is shown, only needed for edit page
        return defaultFormValues
      }, [defaultFormValues])
    }
  })

  useEffect(() => {
    setServiceSteps(_ => {
      if (defaultFormValues.steps) {
        return defaultFormValues.steps
      }
      return []
    })
    reset({ ...defaultFormValues })
  }, [defaultFormValues, reset])

  const normalizeServicePrice = (servicePath, value) => {
    const normalizedPrice = normalizePrice(value)
    setValue(servicePath, normalizedPrice)
  }

  const normalizeServiceDuration = (servicePath, value) => {
    const normalizedDuration = normalizeDuration(value)
    setValue(servicePath, normalizedDuration)
  }

  //delete number on input
  const onBackspace = (servicePath, event) => {
    if (event.key === 'Backspace') {
      let value = event.target.value
      value = value.replace(/[\D]/g, '').replace(/.$/, '')
      normalizeServiceDuration(servicePath, value)
    }
  }

  const [addServiceErrorMessage, setAddServiceErrorMessage] = useState('')

  const [serviceSteps, setServiceSteps] = useState([])

  const [areStepsValids, setAreStepsValids] = useState(true)

  const [stepsChanged, setStepsChanged] = useState(false)

  const setChangedSteps = useCallback(() => {
    if (!stepsChanged) {
      setStepsChanged(true)
    }
  }, [stepsChanged])

  //for each change in serviceSteps it is checked if it is a non-empty step
  useEffect(() => {
    let isValid = true
    serviceSteps.forEach(step => {
      if (step.name === '') {
        isValid = false
      }
    })
    setAreStepsValids(isValid)
  }, [serviceSteps])

  //add a step in array of steps
  const handleAddStep = useCallback(() => {
    setServiceSteps(currentSteps => [...currentSteps, { name: '' }])
  }, [])

  //remove a step in array of steps
  const handleRemoveStep = useCallback(
    index => {
      setServiceSteps(currentSteps => {
        if (currentSteps[index]?.id) {
          setStepsRemoved(curr => [
            ...curr,
            { ...currentSteps[index], isDelete: true }
          ])
        }
        const newSteps = [...currentSteps]
        newSteps.splice(index, 1)
        return newSteps
      })

      setChangedSteps()
    },
    [setStepsRemoved, setChangedSteps]
  )

  //monitor changes in steps
  const handleStepChange = (event, index) => {
    setChangedSteps()

    const newSteps = [...serviceSteps]
    newSteps[index] = { ...newSteps[index], name: event.target.value }
    setServiceSteps(newSteps)
  }

  //handles changing the position of steps
  const handleSwapPosition = (index, indexToSwap) => {
    setServiceSteps(currentSteps => {
      const newSteps = [...currentSteps]
      const swapAux = newSteps[index]
      newSteps[index] = newSteps[indexToSwap]
      newSteps[indexToSwap] = swapAux
      return newSteps
    })

    setChangedSteps()
  }

  //render the steps
  const renderSteps = () => {
    const numberOfSteps = serviceSteps.length
    return serviceSteps.map((_, index) => (
      <div className='step-item-content' key={index}>
        <Input
          type='text'
          classes={`floating-input card-input step-input`}
          value={serviceSteps[index].name}
          onChange={e => handleStepChange(e, index)}
          placeholder={'Próxima Etapa'}
        />
        <div className='change-order-steps'>
          {index !== 0 && (
            <UpwardIcon onClick={() => handleSwapPosition(index, index - 1)} />
          )}
          {index < numberOfSteps - 1 && (
            <DownwardIcon
              onClick={() => handleSwapPosition(index, index + 1)}
            />
          )}
        </div>
        <TwoStepsRemove removeFunction={() => handleRemoveStep(index)} />
      </div>
    ))
  }

  //render the card with the steps
  const renderStepsCard = () => (
    <Card classes='steps-card'>
      <div className='steps-card-header'>
        <h3 className='steps-card-title'>
          Etapas{' '}
          <span>
            (Ex: um banho pode ter 3 etapas: Banho, Secagem e Arrumação)
          </span>
        </h3>
        <AddIcon
          className='add-step-icon'
          onClick={() => {
            handleAddStep()
          }}
        />
      </div>
      <div className='steps-card-content'>
        {serviceSteps.length !== 0 && (
          <p className='common-step'>
            <strong>1ª Etapa:</strong> Recebimento do Pet
          </p>
        )}
        {serviceSteps.length !== 0 ? (
          renderSteps()
        ) : (
          <span>Sem etapas adicionadas</span>
        )}
        {serviceSteps.length !== 0 && (
          <p className='common-step'>
            <strong>Última etapa: </strong>Retirada do Pet pelo Tutor/ Pet
            entregue ao Tutor
          </p>
        )}
      </div>
    </Card>
  )

  //render the card with the prices
  const renderServicePrices = () => (
    <Card classes='prices-card'>
      <h3 className='service-card-title'>
        Valores <span>(Adicione os valores conforme o porte do pet)</span>
      </h3>
      {bodySize.map(size => {
        const bodySizeAttribute = toBodySizeAttribute(size)
        const registerPath = `servicePrices.${bodySizeAttribute}`
        return (
          <div className='prices-pet-size' key={size}>
            <h4>{size}:</h4>
            <div className='pet-hair'>
              <label htmlFor={`${registerPath}.short-input`}>
                Pelo curto:{' '}
              </label>
              <Input
                placeholder='R$ 0,00'
                type='text'
                {...register(`${registerPath}.short`)}
                isValid={
                  errors.servicePrices === undefined
                    ? true
                    : errors.servicePrices[bodySizeAttribute]?.short ===
                      undefined
                }
                onChange={e =>
                  normalizeServicePrice(
                    `${registerPath}.short`,
                    e.target.value,
                    'short'
                  )
                }
              />
            </div>
            <span
              className={
                errors?.servicePrices &&
                errors.servicePrices[bodySizeAttribute]?.short?.message
                  ? 'add-service-field-error'
                  : ''
              }
            >
              {errors?.servicePrices
                ? errors.servicePrices[bodySizeAttribute]?.short?.message
                : ''}
            </span>
            <div className='pet-hair'>
              <label htmlFor={`${registerPath}.long-input`}>Pelo longo: </label>
              <Input
                placeholder='R$ 0,00'
                type='text'
                {...register(`${registerPath}.long`)}
                isValid={
                  errors.servicePrices === undefined
                    ? true
                    : errors.servicePrices[bodySizeAttribute]?.long ===
                      undefined
                }
                onChange={e =>
                  normalizeServicePrice(
                    `${registerPath}.long`,
                    e.target.value,
                    'long'
                  )
                }
              />
            </div>
            <span
              className={
                errors?.servicePrices &&
                errors.servicePrices[bodySizeAttribute]?.long?.message
                  ? 'add-service-field-error'
                  : ''
              }
            >
              {errors?.servicePrices
                ? errors.servicePrices[bodySizeAttribute]?.long?.message
                : ''}
            </span>
          </div>
        )
      })}
    </Card>
  )

  //render the card with the duration of the service
  const renderServiceTime = () => (
    <Card classes={`duration-card ${defaultFormValues && 'mb'}`}>
      <h3 className='service-card-title'>
        Tempo Serviço{' '}
        <span>(Adicione os valores conforme o porte do pet, em minutos)</span>
      </h3>
      {bodySize.map(size => {
        const bodySizeAttribute = toBodySizeAttribute(size)
        const registerPath = `serviceDuration.${bodySizeAttribute}`
        return (
          <div className='duration-pet-size' key={size}>
            <h4>{size}:</h4>
            <div className='pet-hair'>
              <label htmlFor={`${registerPath}.short-input`}>
                Pelo curto:{' '}
              </label>
              <Input
                placeholder='00 min'
                type='text'
                {...register(`${registerPath}.short`)}
                isValid={
                  errors.serviceDuration === undefined
                    ? true
                    : errors.serviceDuration[toBodySizeAttribute(size)]
                        ?.short === undefined
                }
                onChange={e =>
                  normalizeServiceDuration(
                    `${registerPath}.short`,
                    e.target.value,
                    'short'
                  )
                }
                onKeyUp={e => onBackspace(`${registerPath}.short`, e)}
              />
            </div>
            <span
              className={
                errors?.serviceDuration &&
                errors.serviceDuration[bodySizeAttribute]?.short?.message
                  ? 'add-service-field-error'
                  : ''
              }
            >
              {errors?.serviceDuration
                ? errors.serviceDuration[bodySizeAttribute]?.short?.message
                : ''}
            </span>
            <div className='pet-hair'>
              <label htmlFor={`${registerPath}.long-input`}>Pelo longo: </label>
              <Input
                placeholder='00 min'
                type='text'
                {...register(`${registerPath}.long`)}
                isValid={
                  errors.serviceDuration === undefined
                    ? true
                    : errors.serviceDuration[toBodySizeAttribute(size)]
                        ?.long === undefined
                }
                onChange={e =>
                  normalizeServiceDuration(
                    `${registerPath}.long`,
                    e.target.value,
                    'long'
                  )
                }
                onKeyUp={e => onBackspace(`${registerPath}.long`, e)}
              />
            </div>
            <span
              className={
                errors?.serviceDuration &&
                errors.serviceDuration[bodySizeAttribute]?.long?.message
                  ? 'add-service-field-error'
                  : ''
              }
            >
              {errors?.serviceDuration
                ? errors.serviceDuration[bodySizeAttribute]?.long?.message
                : ''}
            </span>
          </div>
        )
      })}
    </Card>
  )

  const handleFormSubmit = data => {
    if (areStepsValids) {
      setAddServiceErrorMessage('')
      const formValues = {
        ...data,
        steps: serviceSteps
      }
      if (!isDirty && !stepsChanged) {
        toast.warning('Faça alguma alteração para poder atualizar o serviço!')
        return
      }
      formSubmitFunction(formValues)
    } else {
      setAddServiceErrorMessage(
        'Verifique se todas as etapas foram preenchidas.'
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className='service-form'>
      <div className='service-input-name'>
        <label htmlFor='name-input'>Serviço*</label>
        <Input
          placeholder='Nome Serviço'
          type='text'
          {...register('name')}
          isValid={errors.name === undefined}
        />
        <span className={errors?.name ? 'add-service-field-error' : ''}>
          {errors?.name?.message}
        </span>
      </div>
      <div className='service-description-input'>
        <label htmlFor='service-description'>Descrição</label>
        <textarea
          id='service-description'
          {...register('description')}
          rows='4'
        />
      </div>
      {renderStepsCard()}
      <p className={addServiceErrorMessage && 'add-service-error-message'}>
        {!areStepsValids && addServiceErrorMessage}
      </p>
      {renderServicePrices()}
      {renderServiceTime()}
      <Button
        text={defaultFormValues ? 'Salvar Serviço' : 'Adicionar Serviço'}
        classes={`primary-button add-button ${defaultFormValues &&
          'fixed-button'}`}
        type='submit'
        isLoading={isLoading}
      />
    </form>
  )
}
