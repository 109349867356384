import React from 'react'
import { isMobile } from 'react-device-detect'

import './style.css'
import { ReactComponent as WazeIcon } from '../../assets/images/icons/waze.svg'
import { ReactComponent as GoogleMapsIcon } from '../../assets/images/icons/googleMaps.svg'
import SelectAppPopup from '../../components/SelectAppPopup'
import Button from '../Button'
import { createGoogleMapsLink } from '../../services/utils/helpers/createGoogleMapsLink'
import { createWazeLink } from '../../services/utils/helpers/createWazeLink'

export default function MapsOrWazeSelectionPopup ({
  closeFunction,
  address,
  ...props
}) {
  return (
    <SelectAppPopup closeFunction={closeFunction}>
      <div className='google-maps-container'>
        <a
          href={createGoogleMapsLink('', address)}
          target='_blank'
          rel='noreferrer'
        >
          <GoogleMapsIcon title='Google Inc.SVG by Premeditated, Public domain, via Wikimedia Commons' />
          Google Maps
        </a>
      </div>
      <div
        className={'waze-container'}
        title='Disponível apenas na versão para celular.'
      >
        <a
          href={createWazeLink(address)}
          className={!isMobile ? 'disable-link' : ''}
          target='_blank'
          rel='noreferrer'
        >
          <WazeIcon />
          {isMobile ? 'Waze' : 'Waze (Disponível apenas para celular)'}
        </a>
      </div>
      <div className='select-app-cancel-button'>
        <Button
          classes='action-button btn-sm'
          text='Cancelar'
          onClick={closeFunction}
        />
      </div>
    </SelectAppPopup>
  )
}
