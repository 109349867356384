import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import { dayENToPT } from '../../../services/utils/helpers/dayOfTheWeek'
import {
  dateToString,
  fullDateToString
} from '../../../services/utils/helpers/dateToString'
import { generateImage } from '../../../services/utils/helpers/imageGenerator'
import { normalizePrice } from '../../../services/utils/masks'
import { getScheduledBillingPixCode } from '../../../services/api/apiCalls'
import { selectCurrentToken } from '../../../features/auth/authSlice'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import Card from '../../../components/Card'
import Button from '../../../components/Button'

export default function SchedulingDetails ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const location = useLocation()
  const navigate = useNavigate()

  const [scheduleInfo, setScheduleInfo] = useState()
  const [scheduleTimes, setScheduleTimes] = useState()
  const [selectedClientPet, setSelectedClientPet] = useState()

  const [pixCode, setPixCode] = useState('')
  const [isPixButtonLoading, setIsPixButtonLoading] = useState(false)

  const payThroughApp = location.state?.scheduleInfo?.payThroughApp

  useEffect(() => {
    if (location.state === null) {
      navigate('/consultar-horarios')
    } else {
      setScheduleInfo(location.state.scheduleInfo)
      setScheduleTimes(location.state.scheduleTimes)
      setSelectedClientPet(location.state.selectedClientPet)
    }
  }, [location, navigate, setScheduleInfo])

  // const renderServices = () => {
  //   return scheduleInfo?.services?.map(service => {
  //     return (
  //       <p className='scheduling-card-text' key={service}>
  //         {service}
  //       </p>
  //     )
  //   })
  // }

  const renderSchedulesInfo = () => {
    return scheduleTimes?.map((schedule, index) => {
      const date = new Date(
        schedule.selectedDay.slice(0, 10).replace(/-/g, '/')
      )
      const start = schedule.startTime
      const end = schedule.endTime
      const day = format(date, 'EEEE').toLowerCase()
      return (
        <p key={index} className='scheduling-details-text-content'>
          {dayENToPT(day)} - {dateToString(date)} - {start} - {end}
        </p>
      )
    })
  }

  const renderEmptySchedules = () => {
    const definedSchedules = scheduleTimes?.length
    const packageLength = parseInt(scheduleInfo?.serviceAmount)
    if (definedSchedules === packageLength) return
    const schedulesToBeDefined =
      definedSchedules + 1 === packageLength
        ? packageLength
        : `${definedSchedules + 1} a ${packageLength}`
    return (
      <p className='scheduling-details-text-content'>
        Horário {schedulesToBeDefined} - Não definido. Cliente irá escolher.
      </p>
    )
  }

  const renderServiceWorker = () => {
    const workers = location.state?.scheduleWorkers
    return workers?.map((worker, index) => {
      return (
        <span key={index}>
          {workers.length === 1
            ? `${worker}`
            : `${index + 1}° Serviço - ${worker}`}
        </span>
      )
    })
  }

  const getPixCode = async () => {
    const scheduledServiceId = location.state?.scheduledServiceId
    setIsPixButtonLoading(true)
    try {
      const response = await getScheduledBillingPixCode(authToken, {
        scheduledClientServiceId: scheduledServiceId
      })
      setPixCode(response.data.pixCode)
    } catch (error) {
      console.error(error)
    } finally {
      setIsPixButtonLoading(false)
    }
  }

  const renderPixCode = () => {
    return (
      <div className='pix-code-container'>
        {pixCode ? (
          <>
            <p className='pix-code-text' style={{ fontWeight: 600 }}>
              Código Pix:
            </p>
            <div className='pix-code-text-container'>
              <span className='pix-code-text'>{pixCode}</span>
              <Button
                classes='action-button-tertiary'
                text={'Copiar Pix'}
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard?.writeText(pixCode)
                    toast.success('Código copiado!')
                  } else {
                    toast.error('Erro ao copiar Pix.')
                  }
                }}
              />
            </div>
          </>
        ) : (
          <Button
            classes='action-button-tertiary'
            text={'Obter Código Pix'}
            onClick={() => getPixCode()}
            isLoading={isPixButtonLoading}
          />
        )}
      </div>
    )
  }

  return (
    <div className='scheduling-details-page-container'>
      <div className='scheduling-details-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Detalhes Agendamento'
          buttonText='Voltar'
          navigateTo='consultar-horarios'
        />
        <div className='scheduling-details-content' id='scheduling-details'>
          <p className='scheduling-details-message'>
            Agendamento realizado com sucesso!{' '}
            <span data-html2canvas-ignore>
              Compartilhe o agendamento com seu cliente clicando no botão no
              final da página!
            </span>
          </p>
          <Card classes='scheduling-card'>
            <h3 className='scheduling-card-title'>Serviço</h3>
            <div className='scheduling-card-content'>
              {/* {renderServices()} */}
              <p className='scheduling-card-text'>
                {location.state?.service?.label}
              </p>
            </div>
          </Card>
          <Card classes='scheduling-card'>
            <h3 className='scheduling-card-title'>Pet/Cliente</h3>
            <div className='scheduling-card-content'>
              <p className='scheduling-card-text'>
                {selectedClientPet?.pet?.name}/{selectedClientPet?.client?.name}
              </p>
            </div>
          </Card>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>Pacote</p>
            <p className='scheduling-details-text-content'>
              {scheduleInfo?.serviceAmount === 1
                ? '1 Serviço'
                : `${scheduleInfo?.serviceAmount} Serviços`}
            </p>
          </div>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>Banhista/tosador</p>
            <p className='scheduling-details-text-content'>
              {renderServiceWorker()}
            </p>
          </div>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>
              Observações do atendimento{' '}
              <span data-html2canvas-ignore>(aparecerá para o cliente)</span>
            </p>
            <textarea
              id='scheduling-details-textarea'
              rows='4'
              value={scheduleInfo?.notes}
              readOnly={true}
            ></textarea>
          </div>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>Horário</p>
            <div className='schedules-info'>
              {renderSchedulesInfo()}
              {renderEmptySchedules()}
            </div>
          </div>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>Leva e traz</p>
            <p className='scheduling-details-text-content'>
              {scheduleInfo?.pickUpAndTake
                ? `Sim - ${scheduleInfo?.client?.pickUpAndTakePrice}`
                : 'Não'}
            </p>
          </div>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>Pagamento pelo App</p>
            <p className='scheduling-details-text-content'>
              {scheduleInfo?.payThroughApp
                ? `Link de pagamento: www.dominio.com.br/código-de-pagamento`
                : 'Não'}
            </p>
          </div>
          <div className='scheduling-details-info-container'>
            <p className='scheduling-details-label'>
              Cancelar em caso de não pagamento{' '}
              <span>
                (se o pagamento não for realizado nos próximos 3 dias ou 24
                horas antes do atendimento, o horário será liberado)
              </span>
            </p>
            <p className='scheduling-details-text-content'>
              {scheduleInfo?.cancelIfNotPayed ? 'Sim' : 'Não'}
            </p>
          </div>
          <div
            className='scheduling-details-info-container'
            data-html2canvas-ignore
          >
            <p className='scheduling-details-label'>Status do pagamento</p>
            <p className='scheduling-details-text-content'>
              {payThroughApp
                ? 'Aguardando pagamento via Pix.'
                : 'Aguardando pagamento diretamente ao petshop.'}
            </p>
            {payThroughApp && renderPixCode()}
          </div>
          <div className='split-line'></div>
          <div className='scheduling-details-amount'>
            <p>Valor total: </p>
            {/* TODO: use service names to find their respective prices according to the pet and calculate the total value  */}
            <p>{normalizePrice(location.state?.bundlePrice)}</p>
          </div>
          <div
            className='scheduling-details-buttons-container'
            data-html2canvas-ignore
          >
            <Button
              classes='action-button-tertiary'
              text='Compartilhar Agendamento'
              onClick={() => {
                const clientName = scheduleInfo?.client?.clientName
                const petName = scheduleInfo?.pet?.petName
                const todayDate = fullDateToString(new Date())
                generateImage(
                  'scheduling-details',
                  `Confirmacao_agendamento_${clientName}_${petName}_${todayDate}`
                )
              }}
            />
            <Button
              classes='primary-button'
              text='Novo Agendamento'
              onClick={() => navigate('/consultar-horarios')}
            />
            <Button
              classes='secondary-button'
              text='Menu'
              onClick={() => navigate('/menu')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
