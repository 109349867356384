import React from 'react'

import './style.css'

export default function Button ({
  text,
  classes = '',
  onClick,
  children,
  isLoading = false,
  ...props
}) {
  return (
    <button
      className={'button ' + classes}
      onClick={onClick}
      {...(isLoading && { disabled: true })}
      {...props}
    >
      {isLoading ? (
        <div className='spinner'></div>
      ) : (
        <>
          {children}
          {text}
        </>
      )}
    </button>
  )
}
