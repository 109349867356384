import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { normalizeCPF } from '../../../../services/utils/masks'
import cpfValidator from '../../../../services/utils/validators/cpfValidator'

import './style.css'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import { updateClientCpf } from '../../../../services/api/apiCalls'

const validationSchema = object({
  cpf: string()
    .required('CPF é um campo obrigatório')
    .test('is-valid-cpf', 'CPF inválido', value => cpfValidator(value))
})

export default function AddClientCpfPopup ({
  closeFunction,
  clientId,
  authToken,
  setClientHasCpf,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) })

  const cpfValue = watch('cpf')

  useEffect(() => {
    setValue('cpf', normalizeCPF(cpfValue))
  }, [cpfValue, setValue])

  const handleUpdateClientCpf = async data => {
    setIsLoading(true)
    const { cpf } = data

    try {
      await updateClientCpf(
        authToken,
        { cpf: cpf.replace(/[\D]/g, '') },
        clientId
      )
      toast.success('CPF cadastrado com sucesso!')
      setClientHasCpf(cpf)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      closeFunction()
    }
  }

  return (
    <Popup
      closeFunction={closeFunction}
      closeOnClickOut={false}
      hasCloseButton={false}
    >
      <h3 className='add-client-cpf-popup-title'>Informe o CPF do cliente!</h3>
      <p className='add-client-cpf-popup-details'>
        Insira o CPF do cliente para completar o cadastro e conseguir gerar a
        chave pix.
      </p>
      <form
        onSubmit={handleSubmit(handleUpdateClientCpf)}
        className='add-client-cpf-form'
      >
        <div className='add-client-cpf-popup-input-field'>
          <label htmlFor='cpf-inpupt'>CPF</label>
          <Input
            placeholder='000.000.000-00'
            name='cpf'
            type='text'
            isValid={errors.cpf === undefined}
            {...register('cpf')}
          />
          <div>
            <span className='add-client-cpf-popup-field-error'>
              {errors?.cpf?.message}
            </span>
          </div>
        </div>
        <div className='add-client-cpf-buttons-container'>
          <Button
            type='button'
            text='Cancelar'
            classes='alert-button'
            onClick={closeFunction}
          />
          <Button
            text='Cadastrar CPF'
            classes='primary-button'
            isLoading={isLoading}
          />
        </div>
      </form>
    </Popup>
  )
}
