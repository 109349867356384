import React from 'react'
import ContentLoader from 'react-content-loader'

export default function SkeletonCard ({
  style,
  height = '100vh',
  rx = '0',
  ry = '0',
  ...props
}) {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={'100%'}
      backgroundColor='#dbdbdb'
      foregroundColor='#ecebeb'
      style={style}
      title='Carregando...'
    >
      <rect x='0' y='0' rx={rx} ry={ry} width='100%' height={height} />
    </ContentLoader>
  )
}
