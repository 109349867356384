import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import ClientForm from '../ClientForm'
import Button from '../../../components/Button'
import { updatePetshopClient } from '../../../services/api/apiCalls'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { toast } from 'react-toastify'
import {
  bodySizeToString,
  furSizeToString
} from '../../../services/utils/helpers/petHelpers'

export default function EditClientPage () {
  const authToken = useSelector(selectCurrentToken)

  const [client, setClient] = useState()
  const { clientId } = useParams()
  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (location.state) {
      const {
        additionalPhoneNumber,
        address,
        auth,
        clientPetshopInfo,
        id,
        name,
        pets,
        phoneNumber,
        email
      } = location.state.client
      const canScheduleThroughApp = clientPetshopInfo[0].canScheduleThroughApp
        ? true
        : false
      const notes = clientPetshopInfo?.[0]?.notes
      const pickUpAndTakePrice = address?.[0]?.deliveryValue
      const pickUpAndTakeTime = address?.[0]?.deliveryTime

      const formattedPets = pets.map(pet => {
        const { bodySize, furSize } = pet
        return {
          ...pet,
          bodySize: {
            label: bodySizeToString(bodySize).replace(/^\w/, c =>
              c.toUpperCase()
            ),
            value: bodySize
          },
          furSize: {
            label: furSizeToString(furSize).replace(/^\w/, c =>
              c.toUpperCase()
            ),
            value: furSize
          }
        }
      })

      const clientData = {
        id,
        additionalPhoneNumber,
        address: address[0],
        auth,
        email,
        name,
        pets: formattedPets,
        phoneNumber,
        notes,
        canScheduleThroughApp,
        pickUpAndTakePrice,
        pickUpAndTakeTime
      }
      setClient(clientData)
    }
  }, [location.state])

  const [isLoading, setIsLoading] = useState(false)

  const handleEditClient = async data => {
    setIsLoading(true)
    const {
      additionalPhoneNumber,
      canScheduleThroughApp,
      email,
      name,
      notes,
      phoneNumber,
      pickUpAndTakePrice,
      pickUpAndTakeTime
    } = data

    const pets = data.pets.map(pet => {
      delete pet.clientId
      delete pet.petshopId
      delete pet.createdAt
      delete pet.updatedAt
      return {
        ...pet,
        bodySize: pet.bodySize.value,
        furSize: pet.furSize.value
      }
    })

    const user = {
      email,
      name,
      phoneNumber: phoneNumber.replace(/[\D]/g, ''),
      additionalPhoneNumber: additionalPhoneNumber?.replace(/[\D]/g, '')
    }

    const deliveryValue = parseInt(pickUpAndTakePrice.replace(/[\D]/g, ''))

    const address = {
      ...data.address,
      deliveryTime: parseInt(pickUpAndTakeTime),
      deliveryValue
    }

    const { cep, streetNumber } = address

    address.cep = cep?.replace(/[\D]/g, '')
    address.streetNumber = parseInt(streetNumber)

    delete address.userId
    delete address.petshopId
    delete address.createdAt
    delete address.updatedAt

    const clientInfo = {
      notes,
      canScheduleThroughApp
    }
    const client = {
      user,
      ...(address.cep && { address }),
      pets,
      clientInfo
    }

    try {
      await updatePetshopClient(authToken, client, clientId)
      toast.success('Cliente atualizado com sucesso!')
      navigate('/meus-clientes')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='edit-client-page-container'>
      <div className='edit-client-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Editar Cliente'
          buttonText='Voltar'
          navigateTo='meus-clientes'
        />
        <div className='edit-client-content-header'>
          <h3>Dados</h3>
          <Button
            classes='action-button-tertiary'
            text='Serviços'
            onClick={() =>
              navigate('servicos', {
                state: {
                  client: location.state.client,
                  ...(location.state?.history && {
                    history: location.state.history
                  })
                }
              })
            }
          />
        </div>
        <div className='edit-client-content'>
          <ClientForm
            formSubmitFunction={data => handleEditClient(data)}
            action='edit'
            defaultFormValues={client}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
