export const normalizePhoneNumber = value => {
  if (!value) return ''

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})(-)(\d)(\d{4})/, '$1$3$2$4')
    .replace(/(-\d{4})(\d)/, '$1')
}

export const normalizeCNPJ = value => {
  if (!value) return ''

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d)/, '$1')
}

export const normalizeCPF = value => {
  if (!value) return ''

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d)/, '$1')
}

export const normalizeCEP = value => {
  if (!value) return ''

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})(\d)/, '$1')
}

export const normalizePrice = value => {
  value = String(value)
  if (!value) return ''

  value = value.replace(/[\D]+/g, '')
  const length = value.length

  if (value === '00' || value === '') return ''

  if (length === 1) value = '.0' + value
  else if (length === 2) value = '.' + value
  else {
    const cents = value.substr(length - 2)
    const currency = value.substr(0, length - 2).replace(/0*/, '')
    value = currency + '.' + cents
  }
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const normalizeDuration = value => {
  if (!value) return ''
  value = value.replace(/[\D]/g, '')
  if (value === '') return ''
  return value.replace(/(\d+)/, '$1 min')
}

export const normalizePercentage = value => {
  value = String(value)
  if (!value) return ''
  value = value.replace(/[\D]/g, '')
  if (value === '') return ''
  if (parseInt(value) > 100) return '100'
  return value.replace(/(\d+)/, '$1%')
}

export const normalizeDate = value => {
  if (!value) return ''

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})(\d)/, '$1')
}

export const normalizeCard = value => {
  if (!value) return ''

  return value
    .replace(/[\D]/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/( \d{4})(\d)/, '$1 $2')
    .replace(/( \d{4})(\d)/, '$1 $2')
    .replace(/( \d{4})(\d)/, '$1')
}

export const normalizeTime = value => {
  if (!value) return ''

  let removeZeros = true

  if (/^0/.test(value)) {
    if (value.length === 1 || /^00/.test(value.replace(':', ''))) {
      removeZeros = false
    }
  }

  const formattedValue = value
    .replace(/[\D]/g, '')
    .replace(removeZeros ? /^0/ : /[\D]/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(:\d{2})(\d)/, '$1')
    .replace(/(\d{1})(\d{1})(:)(\d{1})$/, '$1:$2$4')

  let [hours, minutes] = formattedValue.split(':')

  if (hours < 10 && hours > 0 && minutes < 59) {
    hours = '0' + hours
  }

  // normalize time
  // if (hours > 23) {
  //   hours = '23'
  // }
  // if (minutes > 59 && hours >= 10) {
  //   minutes = '59'
  // }

  if (hours && minutes) return hours + ':' + minutes

  return formattedValue
}
