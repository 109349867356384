import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../features/auth/authSlice'

const NoAuthOnly = () => {
  const token = useSelector(selectCurrentToken)

  return token ? <Navigate to='/menu' /> : <Outlet />
}
export default NoAuthOnly
