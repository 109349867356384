import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import ServiceForm from '../ServiceForm'
import { toServiceValues } from '../../../services/utils/helpers/toServiceValues'
import { formatValue } from '../../../services/utils/helpers/formatValue'

import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { updatePetshopService } from '../../../services/api/apiCalls'
import { toast } from 'react-toastify'

// turns serviceValues into three objects one for prices, one for durations and one for the ids
const transformServiceValues = serviceValues => {
  const servicePrices = {}
  const serviceDuration = {}
  const serviceValuesIds = {}

  serviceValues.forEach(serviceValue => {
    const { bodySize, furSize, value, time, id } = serviceValue

    if (!servicePrices[bodySize]) {
      servicePrices[bodySize] = {}
      serviceDuration[bodySize] = {}
      serviceValuesIds[bodySize] = {}
    }

    servicePrices[bodySize][furSize] = formatValue(value)
    serviceDuration[bodySize][furSize] = `${time} min`
    serviceValuesIds[bodySize][furSize] = id
  })
  return { serviceDuration, servicePrices, serviceValuesIds }
}

// turns serviceSteps into two objects one for steps and one the details
const transformServiceSteps = serviceSteps => {
  const steps = []
  let lastStep = {}

  const defaultFirstStep = 'Recebimento do Pet'
  const defaultLastStep = 'Retirada do Pet pelo Tutor/ Pet entregue ao Tutor'

  serviceSteps
    .filter(serviceStep => !(serviceStep.name === defaultFirstStep))
    .forEach((serviceStep, index) => {
      const { id, name, rankNumber } = serviceStep
      if (serviceStep.name === defaultLastStep) {
        lastStep = { id, name, rankNumber, notRender: true }
      } else {
        steps[index] = { id, name, rankNumber }
      }
    })

  return [steps, lastStep]
}

export default function EditServicePage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const [service, setService] = useState()
  /*
    TODO: if there is no service data in the route state, call the backend to fetch the service
    const { serviceId } = useParams()
   */
  const location = useLocation()
  const navigate = useNavigate()

  const [serviceValuesIds, setServiceValuesIds] = useState()
  const [stepsRemoved, setStepsRemoved] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [lastStandatStep, setLastStandartStep] = useState({})

  useEffect(() => {
    if (location?.state?.service) {
      const {
        id,
        name,
        description,
        serviceValues,
        serviceSteps
      } = location.state.service
      const {
        serviceDuration,
        servicePrices,
        serviceValuesIds
      } = transformServiceValues(serviceValues)
      const [steps, lastStep] = transformServiceSteps(serviceSteps)

      const newService = {
        id,
        name,
        description,
        serviceDuration,
        servicePrices,
        steps
      }
      setLastStandartStep(lastStep)
      setService(newService)
      setServiceValuesIds(serviceValuesIds)
    } else {
      navigate('/meus-servicos')
    }
  }, [location?.state, navigate])

  const handleEditService = async data => {
    setIsLoading(true)
    const {
      id,
      name,
      description,
      serviceDuration,
      servicePrices,
      steps
    } = data
    const serviceInfo = { id, name, description }
    const serviceValues = toServiceValues(
      serviceDuration,
      servicePrices,
      serviceValuesIds
    )
    const serviceSteps = steps.map((step, index) => ({
      ...step,
      rankNumber: index + 2,
      ...(step.id && { isUpdate: true })
    }))
    const lastStep = {
      ...lastStandatStep,
      isUpdate: true,
      rankNumber: parseInt(steps.length + 2)
    }
    delete lastStep.notRender
    const service = {
      serviceInfo,
      serviceValues,
      serviceSteps: [...serviceSteps, ...stepsRemoved, lastStep]
    }
    try {
      await updatePetshopService(authToken, service, id)
      toast.success('Serviço atualizado com sucesso!')
      navigate('/meus-servicos')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='edit-service-page-container'>
      <div className='edit-service-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Editar Serviço'
          buttonText='Voltar'
          navigateTo='meus-servicos'
        />
        <div className='edit-service-content'>
          <ServiceForm
            formSubmitFunction={data => handleEditService(data)}
            defaultFormValues={service}
            setStepsRemoved={setStepsRemoved}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
