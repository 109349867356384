import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { object, string, array, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import Button from '../../../components/Button'
import DayScheduleCard from '../../../components/DayScheduleCard'
import { dayPTToEN } from '../../../services/utils/helpers/dayOfTheWeek'
import ScheduleByWeekPopup from '../../../components/ScheduleByWeekPopup'
import ConfirmationPopup from '../../../components/ConfirmationPopup'
import { convertDayToNumber } from '../../../services/utils/helpers/dayOfTheWeek'
import { hoursToMinutes } from '../../../services/utils/helpers/hoursToMinutes'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import {
  getResponsiblePersonAvailablePeriod,
  getResponsiblePersonNotAvailablePeriod,
  getWorkerAvailablePeriod,
  getWorkerNotAvailablePeriod,
  updateResponsiblePersonAvailablePeriod,
  updateWorkerAvailablePerdiod
} from '../../../services/api/apiCalls'
import { toast } from 'react-toastify'
import {
  selectPetshopMenuState,
  setMenuState
} from '../../../features/petshop/petshopSlice'
import SkeletonText from '../../../skeletonComponents/Text'
import SkeletonCard from '../../../skeletonComponents/Card'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'
import SkeletonButton from '../../../skeletonComponents/Button'
import { hourValidator } from '../../../services/utils/validators/hourValidator'

const daysWeek = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo'
]

// maps the weekDay to the corresponding day of the week
const dayOfTheWeek = number => {
  if (number === 1) return dayPTToEN(daysWeek[6])
  return dayPTToEN(daysWeek[number - 2])
}

// Check if there is a conflict between the schedules.
function periodsOverlap (periods) {
  for (let i = 0; i < periods.length; i++) {
    for (let j = i + 1; j < periods.length; j++) {
      if (
        periods[i].end >= periods[j].start &&
        periods[i].start <= periods[j].end
      ) {
        return false
      }
    }
  }
  return true
}

const scheduleDefaultObject = object({
  periodStart: string()
    .required('Campo Obrigatório')
    .test('periodStartBeforePeriodEnd', 'Horário inconsistente.', function (
      periodStart
    ) {
      const { periodEnd } = this.parent
      const periodStartMinutes = hoursToMinutes(periodStart)
      const periodEndMinutes = hoursToMinutes(periodEnd)
      if (isNaN(periodEndMinutes)) return true

      return periodEndMinutes > periodStartMinutes
    })
    .test('formatInconsistent', 'Horário inválido.', function (periodStart) {
      return hourValidator(periodStart)
    }),
  periodEnd: string()
    .required('Campo Obrigatório')
    .test('periodStartBeforePeriodEnd', 'Horário inconsistente.', function (
      periodEnd
    ) {
      const { periodStart } = this.parent
      const periodStartMinutes = hoursToMinutes(periodStart)
      const periodEndMinutes = hoursToMinutes(periodEnd)
      if (isNaN(periodStartMinutes)) return true

      return periodEndMinutes > periodStartMinutes
    })
    .test('formatInconsistent', 'Horário inválido.', function (periodEnd) {
      return hourValidator(periodEnd)
    }),
  id: number()
    .notRequired()
    .nullable()
})

const scheduleDefaultCollection = array()
  .of(scheduleDefaultObject)
  .test('noPeriodOverlap', 'Choque de horários!', value => {
    if (!value) {
      return true
    }

    const periods = value.map(period => {
      const periodStartMinutes = hoursToMinutes(period.periodStart)
      const periodEndMinutes = hoursToMinutes(period.periodEnd)
      return { start: periodStartMinutes, end: periodEndMinutes }
    })

    return periodsOverlap(periods)
  })

const validationSchema = object({
  monday: scheduleDefaultCollection,
  tuesday: scheduleDefaultCollection,
  wednesday: scheduleDefaultCollection,
  thursday: scheduleDefaultCollection,
  friday: scheduleDefaultCollection,
  saturday: scheduleDefaultCollection,
  sunday: scheduleDefaultCollection
})

// adapts information from the backend to feed the frontend
const handleData = data => {
  const newSchedule = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }
  data?.forEach((period, index) => {
    const { id, periodStart, periodEnd } = period
    const weekDay = dayOfTheWeek(period.weekDay)
    const dayScheduleLength = newSchedule[weekDay].length
    newSchedule[weekDay][dayScheduleLength] = { id, periodStart, periodEnd }
  })
  return newSchedule
}

// format data to suit the data expected by the backend
const formatSchedule = data => {
  const periods = []
  for (const dayOfWeek in data) {
    const scheduleArray = data[dayOfWeek]
    const weekDay = convertDayToNumber(dayOfWeek)
    scheduleArray.forEach(currentSchedule => {
      const hasId = currentSchedule.id ? true : false
      const schedule = {
        ...currentSchedule,
        weekDay,
        ...(hasId ? { isUpdate: true } : {})
      }
      periods.push(schedule)
    })
  }
  return periods
}

export default function ConfigureSchedulesPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const navigate = useNavigate()
  const location = useLocation()
  const { workerId } = useParams()

  const dispatch = useDispatch()
  const menuState = useSelector(selectPetshopMenuState)

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    getValues,
    control,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    }
  })

  const fieldArray = [
    useFieldArray({ control, name: 'monday' }),
    useFieldArray({ control, name: 'tuesday' }),
    useFieldArray({ control, name: 'wednesday' }),
    useFieldArray({ control, name: 'thursday' }),
    useFieldArray({ control, name: 'friday' }),
    useFieldArray({ control, name: 'saturday' }),
    useFieldArray({ control, name: 'sunday' })
  ]

  const [showScheduleByWeekPopup, setShowScheduleByWeekPopup] = useState(false)
  const [changePagePopup, setChangePagePopup] = useState(false)

  const [deletedSchedules, setDeletedSchedules] = useState([])

  const [isScheduleModified, setIsScheduleModified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [schedule, setSchedule] = useState([])
  const [notAvailableSchedule, setNotAvailableSchedule] = useState([])

  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    if (!location?.state) {
      navigate('/horarios-atendimento')
    }
  }, [location, navigate])

  useEffect(() => {
    async function getAvailablePeriod () {
      if (!authToken) return
      const { availablePeriod, notAvailablePeriod } = location?.state

      setIsPageLoading(true)

      if (availablePeriod) {
        setIsPageLoading(false)
        let newSchedule = handleData(availablePeriod)
        reset(newSchedule)
        setSchedule(availablePeriod)
        setNotAvailableSchedule(notAvailablePeriod)
        return
      }

      const { isResponsiblePerson } = location.state

      try {
        const response = isResponsiblePerson
          ? await getResponsiblePersonAvailablePeriod(authToken)
          : await getWorkerAvailablePeriod(authToken, workerId)
        const notAvailableResponse = isResponsiblePerson
          ? await getResponsiblePersonNotAvailablePeriod(authToken)
          : await getWorkerNotAvailablePeriod(authToken, workerId)
        const data = response.data
        setSchedule(data)
        setNotAvailableSchedule(notAvailableResponse.data)

        let newSchedule = handleData(data)
        reset(newSchedule)

        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getAvailablePeriod()
  }, [authToken, reset, location?.state, workerId])

  const renderSchedule = () => {
    return daysWeek.map((day, index) => {
      const weekDay = dayPTToEN(day)
      const { fields, append, remove } = fieldArray[index]
      return (
        <DayScheduleCard
          title={day}
          key={day}
          className='mb-4'
          register={register}
          setValue={setValue}
          fields={fields}
          append={append}
          remove={remove}
          day={weekDay}
          dailySchedule={watch(`${weekDay}`)}
          setIsScheduleModified={() => setIsScheduleModified(true)}
          errors={errors}
          clearErrors={clearErrors}
          setDeletedSchedules={setDeletedSchedules}
          getValues={getValues}
        />
      )
    })
  }

  const handleChangeAvailablePeriod = async data => {
    if (!isDirty) {
      toast.warning('Faça uma alteração para poder submeter os horários!')
      return
    }

    const { isResponsiblePerson } = location.state

    setIsLoading(true)
    const periods = formatSchedule(data)
    try {
      const response = isResponsiblePerson
        ? await updateResponsiblePersonAvailablePeriod(authToken, {
            periods: [...periods, ...deletedSchedules]
          })
        : await updateWorkerAvailablePerdiod(
            authToken,
            { periods: [...periods, ...deletedSchedules] },
            workerId
          )

      const schedules = JSON.parse(response.request?.response)
      const newSchedules = handleData(schedules)
      reset(newSchedules)
      if (!menuState || menuState?.index < 1) {
        dispatch(setMenuState({ menuState: { state: 'services', index: 1 } }))
      }
      setIsScheduleModified(false)
      reset(getValues())
      setSchedule(schedules)
      toast.success('Horários atualizados com sucesso!')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderSkeleton = () => {
    return (
      <>
        <div className='configure-schedules-content-header'>
          <h3>
            <SkeletonText
              lines={1}
              height={'22px'}
              width='64px'
              svgWidth='64px'
              rx='8'
              ry='8'
              style={{ borderRadius: '8px' }}
            />
          </h3>
          <SkeletonText
            lines={1}
            height={'32px'}
            width='160px'
            svgWidth='160px'
            style={{ borderRadius: '16px', marginRight: '4vw' }}
          />
        </div>
        <p className='schedule-message'>
          <SkeletonText lines={2} height={'17px'} width='100%' />
        </p>
        <div className='days-week'>
          {renderComponentNTimes(3, SkeletonCard, {
            height: '120px',
            rx: '10',
            ry: '10',
            style: { borderRadius: '10px' }
          })}
        </div>
        <div className='buttons-container'>
          {renderComponentNTimes(2, SkeletonButton, {
            height: '49px',
            style: { borderRadius: '25px' }
          })}
        </div>
      </>
    )
  }

  return (
    <div className='configure-schedules-page-container'>
      <div className='configure-schedules-page-inner-container'>
        {showScheduleByWeekPopup && (
          <ScheduleByWeekPopup
            closeSchedulePopup={() => setShowScheduleByWeekPopup(false)}
            daysWeek={daysWeek}
            availablePeriod={schedule}
            notAvailablePeriod={notAvailableSchedule}
            name={location?.state?.name}
          />
        )}
        {changePagePopup && (
          <ConfirmationPopup
            closeFunction={() => setChangePagePopup(false)}
            confirmationAction={() => {
              const { name } = location.state
              navigate('indisponibilidade', {
                state: {
                  name,
                  availablePeriod: schedule,
                  notAvailablePeriod: notAvailableSchedule,
                  isResponsiblePerson: location.state?.isResponsiblePerson
                }
              })
            }}
          >
            <p className='change-popup-title'>
              <strong>Tem certeza</strong> que deseja continuar? <br /> <br />
              <span>
                Você inseriu algumas informações que ainda não foram salvas e{' '}
                <strong>serão perdidas</strong> caso continue.
              </span>
            </p>
          </ConfirmationPopup>
        )}
        <MenuHeader
          classes='menu-with-icon'
          title='Horários de Atendimento'
          buttonText='Voltar'
          navigateTo='horarios-atendimento'
          confirmToBack={isScheduleModified}
        />
        <div className='configure-schedules-content'>
          {isPageLoading ? (
            renderSkeleton()
          ) : (
            <>
              <div className='configure-schedules-content-header'>
                <h3>Geral</h3>
                <Button
                  classes='action-button-tertiary'
                  text='Indisponibilidade'
                  onClick={() => {
                    if (isScheduleModified) {
                      setChangePagePopup(true)
                      return
                    }
                    const { name, isResponsiblePerson } = location.state
                    navigate('indisponibilidade', {
                      state: {
                        name,
                        availablePeriod: schedule,
                        notAvailablePeriod: notAvailableSchedule,
                        isResponsiblePerson
                      }
                    })
                  }}
                />
              </div>
              <p className='schedule-message'>
                Adicione os horários em que{' '}
                <strong>{location?.state?.name}</strong> irá atender e salve no
                final da página.
                <br />
                <br />
                <i>
                  (ex: das <strong>09h às 12h</strong> e das{' '}
                  <strong>14h às 18h</strong>)
                </i>
              </p>
              <form onSubmit={handleSubmit(handleChangeAvailablePeriod)}>
                <div className='days-week'>
                  {!isPageLoading
                    ? renderSchedule()
                    : renderComponentNTimes(4, SkeletonCard, {
                        height: '120px',
                        rx: '10',
                        ry: '10',
                        style: { borderRadius: '10px' }
                      })}
                </div>
                <div className='configure-schedules-buttons-container'>
                  <Button
                    type='button'
                    classes='secondary-button'
                    text='Ver Horários'
                    onClick={() => {
                      if (schedule.length > 0) {
                        setShowScheduleByWeekPopup(true)
                      } else {
                        toast.warning(
                          'Primeiro adicione ao menos um horário para acessar os horários disponíveis!'
                        )
                      }
                    }}
                  />
                  <Button
                    classes='primary-button'
                    text='Salvar'
                    isLoading={isLoading}
                  />
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
