const sizeOptions = [
  { value: 'small', label: 'Pequeno' },
  { value: 'medium', label: 'Médio' },
  { value: 'large', label: 'Grande' },
  { value: 'special', label: 'Especial' },
  { value: 'other', label: 'Outro' }
]

const furOptions = [
  { value: 'short', label: 'Curto' },
  { value: 'long', label: 'Longo' }
]

export { sizeOptions, furOptions }
