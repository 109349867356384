import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/Button'
import Header from '../../components/Header'

import './style.css'

export default function InitialPage (props) {
  const navigate = useNavigate()

  return (
    <div className='initial-page-container bg-color-identity-1'>
      <div className='initial-page-inner-container'>
        <Header />
        <div className='content-container'>
          <div className='app-description'>
            <p>
              <strong>Facilite</strong> a sua gestão de agendamentos e foque no
              que realmente importa: nos <strong>pets!</strong>
            </p>
          </div>

          <div className='free-test-container'>
            <span>
              Cadastre agora e teste <strong>gratuitamente</strong> por 15 dias!
            </span>
          </div>
          <div className='initial-page-buttons-container'>
            <Button
              text='Cadastrar e Testar Gratuitamente'
              classes='primary-button'
              onClick={() => navigate('/cadastro')}
            />
            <Button
              text='Já tenho Conta. Fazer Login'
              classes='secondary-button'
              onClick={() => navigate('/entrar')}
            />
          </div>
          <div className='more-info'>
            <span>Quer saber mais sobre nossos planos?</span>
            <span>
              <a href='/planos'>Clique aqui</a> e descubra as vantagens de usar
              o gepety!
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
