import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { sendChangePasswordEmail } from '../../../../services/api/apiCalls'

import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { ReactComponent as UserIcon } from '../../../../assets/images/icons/user.svg'
import { ReactComponent as ArrowBackIcon } from '../../../../assets/images/icons/arrow-back.svg'
import './style.css'

const validationSchema = object({
  email: string()
    .email('Formato incorreto de email!')
    .required('E-mail é campo obrigatório')
})

export default function SendRecoveryEmail ({ onSent, ...props }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  async function handleSendPasswordRecoveryEmail (data) {
    try {
      setIsLoading(true)
      await sendChangePasswordEmail({
        email: data.email
      })
      toast.success('E-mail de redefinição de senha enviado!')
      onSent(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  function goBack () {
    navigate(-1)
  }

  return (
    <div className='identification-container'>
      <div className='forget-password-header'>
        <div className='return-label' onClick={goBack}>
          <ArrowBackIcon />
          <span>Voltar</span>
        </div>
        <div className='header-title'>Esqueci a Senha</div>
      </div>
      <div className='forget-password-content'>
        <p>
          Esqueceu a sua senha? <strong>Tudo bem!</strong>
          <br />
          Digite seu <strong>e-mail cadastrado</strong> que iremos{' '}
          <strong>enviar instruções</strong> para criar uma nova:
        </p>
      </div>
      <form onSubmit={handleSubmit(handleSendPasswordRecoveryEmail)}>
        <div className='email-container'>
          <Input
            placeholder='E-mail'
            name='email'
            type='text'
            isValid={errors.email === undefined}
            {...register('email')}
          >
            <UserIcon className='user-icon' />
          </Input>
          <span className='field-error'>{errors?.email?.message}</span>
        </div>
        <Button
          text='Redefinir Senha'
          classes='primary-button'
          isLoading={isLoading}
        />
      </form>
    </div>
  )
}
