import React, { useState } from 'react'

import './style.css'
import { ReactComponent as BackwardIcon } from '../../assets/images/icons/backward.svg'
import { ReactComponent as ForwardIcon } from '../../assets/images/icons/forward.svg'
import Popup from '../Popup'
import Card from '../Card'
import Input from '../Input'
import { dateToString } from '../../services/utils/helpers/dateToString'
import { weeksBuilder } from '../../services/utils/helpers/weeksBuilder'
import { add } from 'date-fns'
import { hoursToMinutes } from '../../services/utils/helpers/hoursToMinutes'

const maxNumberOfWeeks = 9

const buildWeekSchedule = weeklySchedule => {
  const weeks = weeksBuilder(maxNumberOfWeeks)
  const weeksSchedule = weeks.map(week => {
    return { ...week, schedule: weeklySchedule }
  })
  return weeksSchedule
}

export default function ScheduleByWeekPopup ({
  closeSchedulePopup,
  daysWeek,
  availablePeriod,
  notAvailablePeriod,
  name,
  ...props
}) {
  const [currentWeek, setCurrentWeek] = useState(0)

  const weeks = buildWeekSchedule(availablePeriod)

  const renderSchedule = (schedule, day) => {
    //timeInterval stores start and end time of the day's service time
    return schedule.map(timeInterval => {
      const { periodStart, periodEnd, id } = timeInterval

      return (
        <div key={id} className='schedule-item'>
          <div className='schedule-item-content'>
            <Input
              type='text'
              classes={`floating-input card-input`}
              value={periodStart}
              disabled
            />
            <p>às</p>
            <Input
              type='text'
              classes={`floating-input card-input`}
              value={periodEnd}
              disabled
            />
          </div>
        </div>
      )
    })
  }

  const renderUnavailabilitySchedule = () => {
    const weekSchedule = weeks[currentWeek].schedule

    const startOfCurrentWeek = weeks[currentWeek].startOfWeek

    return daysWeek.map((day, index) => {
      const scheduleOfTheDay = weekSchedule
        .filter(schedule => schedule.weekDay === (index + 2) % 7)
        // checks if there is a clash between the registered times and times of unavailability
        .filter(schedule => {
          const { weekDay } = schedule
          const scheduleDate = add(startOfCurrentWeek, {
            days: (weekDay + 5) % 7
          })

          let available = true

          notAvailablePeriod.forEach(period => {
            if (!available) return

            const dateText = period.day.slice(0, 10).replace(/-/g, '/')
            const periodDate = new Date(dateText)

            // check if the schedules are on the same day
            if (!(periodDate > scheduleDate) && !(periodDate < scheduleDate)) {
              const notAvailableStart = hoursToMinutes(period.periodStart)
              const notAvailableEnd = hoursToMinutes(period.periodEnd)
              const intervalStart = hoursToMinutes(schedule.periodStart)
              const intervalEnd = hoursToMinutes(schedule.periodEnd)

              // check if the times collide
              if (
                (notAvailableStart >= intervalStart &&
                  notAvailableStart < intervalEnd) ||
                (notAvailableEnd <= intervalEnd &&
                  notAvailableEnd < intervalStart)
              ) {
                available = false
              }
            }
          })
          return available
        })

      return (
        scheduleOfTheDay.length > 0 && (
          <Card key={day} classes='schedule-card'>
            <div className='card-header'>
              <h3>{day}</h3>
            </div>
            <div className='card-content'>
              {renderSchedule(scheduleOfTheDay, day)}
            </div>
          </Card>
        )
      )
    })
  }

  return (
    <Popup closeFunction={closeSchedulePopup} className='popup'>
      <h3 className='schedule-popup-title'>Horários Atendimento {name}</h3>

      <div className='schedule-pagination'>
        <BackwardIcon
          onClick={() =>
            setCurrentWeek(current => {
              if (current === 0) return current
              return current - 1
            })
          }
        />
        <span>
          Semana {dateToString(weeks[currentWeek].startOfWeek)} -{' '}
          {dateToString(weeks[currentWeek].endOfWeek)}
        </span>
        <ForwardIcon
          onClick={() => {
            setCurrentWeek(current => {
              if (current === weeks.length - 1) return current
              return current + 1
            })
          }}
        />
      </div>
      <div className='days-week-schedule'>{renderUnavailabilitySchedule()}</div>
    </Popup>
  )
}
