import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import WorkerForm from '../WorkerForm'

export default function WorkerAddEdit () {
  const { workerId } = useParams()
  const location = useLocation()

  const [worker, setWorker] = useState()

  useEffect(() => {
    if (workerId && location.state) {
      setWorker(location.state.worker)
    }
  }, [workerId, location.state])

  return (
    <div className='worker-crud-page-container'>
      <div className='worker-crud-page-inner-container'>
        <MenuHeader
          buttonText='Voltar'
          title='Dados Colaborador'
          classes='floating-left'
          navigateTo='meus-colaboradores'
        />
        <div className='worker-crud-content'>
          <WorkerForm worker={worker} />
        </div>
      </div>
    </div>
  )
}
