import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { object, string, date } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import {
  normalizePhoneNumber,
  normalizePrice
} from '../../services/utils/masks'
import {
  bodySizeToString,
  furSizeToString
} from '../../services/utils/helpers/petHelpers'
import {
  getScheduledBillingPixCode,
  startScheduledCustomerService
} from '../../services/api/apiCalls'
import { isWorker } from '../../services/utils/helpers/authHelper'

import './style.css'
import { ReactComponent as DownwardIcon } from '../../assets/images/icons/downward.svg'
import { ReactComponent as UpwardIcon } from '../../assets/images/icons/upward.svg'
import { ReactComponent as WhatsappIcon } from '../../assets/images/icons/whatsapp.svg'
import Card from '../Card'
import Button from '../Button'
import { createWhatsappLink } from '../../services/utils/helpers/createWhatsappLink'
import MapsOrWazeSelectionPopup from '../MapsOrWazeSelectionPopup'
import FreeSchedulesPopup from '../FreeSchedulesPopup'
import CancelAppointmentPopup from '../CancelAppointmentPopup'

const validationSchema = object({
  schedule: object({
    day: string().required('Nenhum horário foi selecionado.'),
    date: date().required('Nenhum horário foi selecionado.'),
    hour: string().required('Nenhum horário foi selecionado.')
  })
})

export default function AppointmentDropdown ({
  appointment,
  identifier,
  authToken,
  auth,
  petshopId,
  setRefreshNextAppointments,
  ...props
}) {
  const [showDetails, setShowDetails] = useState(false)
  const [showRedirectPopup, setShowRedirectPopup] = useState(false)
  const [showReschedulePopup, setShowReschedulePopup] = useState(false)
  const [showCancellationPopup, setShowCancellationPopup] = useState(false)
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const isAppointmentPaid =
    appointment?.billingStatus === 'paid' ||
    appointment?.bundleBillingStatus === 'paid'

  const navigate = useNavigate()

  const { handleSubmit, setValue, watch, resetField } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const startServiceButton = () => {
    const { selectedWeek, selectedDay } = identifier
    return !appointment?.steps ? (
      <Button
        isLoading={isButtonLoading}
        text={'Iniciar Atendimento'}
        classes='action-button-tertiary start-service-button'
        onClick={async () => {
          try {
            setIsButtonLoading(true)
            const response = await startScheduledCustomerService(
              authToken,
              appointment.id,
              isWorker(auth) ? petshopId : undefined
            )
            const steps = response.data.scheduleClientServiceSteps
            navigate(`/proximos-atendimentos/${appointment?.id}}`, {
              state: { selectedWeek, selectedDay, appointment, steps }
            })
          } catch (error) {
            console.error(error)
          } finally {
            setIsButtonLoading(false)
          }
        }}
      />
    ) : (
      <Button
        text={'Continuar Atendimento'}
        classes='action-button-tertiary start-service-button'
        onClick={() => {
          // step's returnAt and completedAt attributes are not returned in UTC time zone
          const steps = JSON.parse(appointment.steps)
          // adding to returnedAt and completedAt UTC time zone
          const stepsISO = steps.map(step => {
            const { returnedAt, completedAt } = step
            return {
              ...step,
              completedAt: completedAt ? completedAt + 'Z' : null,
              returnedAt: returnedAt ? returnedAt + 'Z' : null
            }
          })
          navigate(`/proximos-atendimentos/${appointment?.id}}`, {
            state: { selectedWeek, selectedDay, appointment, steps: stepsISO }
          })
        }}
      />
    )
  }

  const schedule = watch('schedule')

  const handleReschedule = schedule => {
    //TODO: Reschedule
    setShowReschedulePopup(false)
  }

  const [pixCodeValue, setPixCodeValue] = useState()
  const [isPixButtonLoading, setIsPixButtonLoading] = useState(false)

  const generatePixCode = async () => {
    setIsPixButtonLoading(true)
    try {
      const response = await getScheduledBillingPixCode(authToken, {
        scheduledClientServiceId: appointment.id
      })
      setPixCodeValue(response.data.pixCode)
    } catch (error) {
      console.error(error)
    } finally {
      setIsPixButtonLoading(false)
    }
  }

  useEffect(() => {
    const { pixCode, bundlePixCode } = appointment

    const pix = bundlePixCode ? bundlePixCode : pixCode
    if (pix) {
      setPixCodeValue(pix)
    }
  }, [appointment])

  const renderPixCode = () => {
    const { payThroughApp } = appointment

    if (!payThroughApp) return

    if (!pixCodeValue) {
      return (
        <Button
          text='Gerar Código Pix'
          classes='action-button-tertiary generate-pix-code-button'
          onClick={generatePixCode}
          isLoading={isPixButtonLoading}
        />
      )
    }

    return (
      <div>
        <p className='appointment-text pix-text'>
          <strong>Código Pix:</strong>
          <br />
          <br />
          {pixCodeValue}
        </p>
        <Button
          text={'Copiar'}
          classes='action-button-tertiary copy-pix-button'
          onClick={() => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(pixCodeValue)
              toast.success('Código copiado!')
            } else {
              toast.error('Erro ao copiar Pix.')
            }
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Card classes='appointment-dropdown'>
        <div
          className='appointment-card-header'
          onClick={() => setShowDetails(current => !current)}
        >
          <div className='header-content'>
            <h3>
              {appointment?.startTime} - {appointment?.endTime}{' '}
              {!identifier && ' - Detalhes do Atendimento'}
            </h3>
            {!showDetails &&
              identifier &&
              !appointment?.cancellationMessage &&
              startServiceButton()}
          </div>
          {showDetails ? <UpwardIcon /> : <DownwardIcon />}
        </div>
        <div className='appointment-content'>
          <p className='appointment-text appointment-client-number'>
            <strong>Cliente:</strong> {appointment?.clientName}{' '}
            <a
              href={createWhatsappLink(appointment?.clientPhoneNumber)}
              target='_blank'
              rel='noreferrer'
            >
              <u>
                ({normalizePhoneNumber(appointment?.clientPhoneNumber)})
                <WhatsappIcon />
              </u>
            </a>
          </p>
          <p className='appointment-text'>
            <strong>Pet:</strong> {appointment?.petName} (porte{' '}
            {bodySizeToString(appointment?.petBodySize)} e pelo{' '}
            {furSizeToString(appointment?.petFurSize)})
          </p>
          {showDetails && (
            <p className='appointment-text'>
              <strong>Observações do pet:</strong>{' '}
              {appointment?.petNotes
                ? appointment?.petNotes
                : 'Sem observações'}
              .
            </p>
          )}
          <p className='appointment-text'>
            <strong>Serviço:</strong> {appointment?.serviceName}{' '}
            <strong>
              {appointment?.deliveryValue ? 'com' : 'sem'} leva e traz.
            </strong>
          </p>
          <p className='appointment-text'>
            <strong>Observações do Serviço:</strong>{' '}
            {appointment?.notes ? appointment?.notes : 'Sem observações'}.
          </p>
          {showDetails && (
            <>
              <p className='appointment-text'>
                <strong>Endereço:</strong>{' '}
                {appointment?.cep ? (
                  <>
                    {appointment?.street}, n° {appointment?.streetNumber},{' '}
                    {appointment?.city} - {appointment?.uf} - {appointment?.cep}{' '}
                    -{' '}
                    <u
                      className='calculate-route'
                      onClick={() => setShowRedirectPopup(true)}
                    >
                      Clique aqui para calcular rota
                    </u>
                  </>
                ) : (
                  'Sem endereço cadastrado.'
                )}
              </p>
              <p className='appointment-text'>
                <strong>Pagamento:</strong>{' '}
                {appointment?.payThroughApp
                  ? isAppointmentPaid
                    ? 'Pago pelo app.'
                    : 'Aguardando pagamento.'
                  : 'Pagamento fora do app.'}
              </p>
              <p className='appointment-text'>
                <strong>Valor Total:</strong>{' '}
                {normalizePrice(appointment?.totalValue)}
              </p>
              {/* <p className='appointment-text'>
                <strong>Cliente Confirmou:</strong>{' '}
                {appointment?.clientConfirmationTime
                  ? `Sim, em ${appointment?.clientConfirmationTime}`
                  : 'Não.'}
              </p> */}
              {renderPixCode()}
              {appointment?.cancellationMessage && (
                <p className='appointment-text'>
                  <strong>Atendimento Cancelado:</strong> "
                  {appointment.cancellationMessage}"
                </p>
              )}

              {identifier && !appointment?.cancellationMessage && (
                <div
                  className={`appointment-buttons ${
                    appointment?.started ? 'appointment-buttons-flex-end' : ''
                  }`}
                >
                  {!appointment?.started && (
                    <Button
                      text='Cancelar'
                      classes='alert-button reschedule-button'
                      onClick={() => {
                        setShowCancellationPopup(true)
                        setSelectedAppointmentId(appointment?.id)
                      }}
                    />
                  )}
                  {startServiceButton()}
                </div>
              )}
            </>
          )}
        </div>
      </Card>
      {showRedirectPopup && (
        <MapsOrWazeSelectionPopup
          address={{
            city: appointment?.city,
            cep: appointment?.cep,
            street: appointment?.street,
            streetNumber: appointment?.streetNumber,
            district: appointment?.district,
            uf: appointment?.uf
          }}
          closeFunction={() => setShowRedirectPopup(false)}
        />
      )}
      {showReschedulePopup && (
        <form onSubmit={handleSubmit(handleReschedule)}>
          <FreeSchedulesPopup
            closeFunction={() => setShowReschedulePopup(false)}
            selectedClientPet={{
              client: {
                id: appointment.clientId,
                name: appointment.clientName,
                phoneNumber: appointment.clientPhoneNumber
              },
              pet: {
                name: appointment.petName,
                breed: appointment.petBreed,
                bodySize: appointment.petBodySize,
                furSize: appointment.petFurSize,
                notes: appointment.petNotes,
                id: appointment.petId
              }
            }}
            setValue={setValue}
            resetField={resetField}
            field='schedule'
            selectedSchedule={schedule}
            handleSubmit={false}
            buttonTextPopup='Selecionar Novo Horário'
            params={{
              serviceId: appointment.serviceId,
              bodySize: appointment.petBodySize,
              furSize: appointment.petFurSize
            }}
          />
        </form>
      )}
      {showCancellationPopup && (
        // <form onSubmit={handleSubmit(handleCancellation)}>
        <CancelAppointmentPopup
          setRefreshNextAppointments={setRefreshNextAppointments}
          appointmentId={selectedAppointmentId}
          authToken={authToken}
          closeFunction={() => {
            setShowCancellationPopup(false)
          }}
          cancellationReason={'PetshopCancellation'}
          customMessage={'Cliente solicitou cancelamento do serviço.'}
        />
        // </form>
      )}
    </>
  )
}
