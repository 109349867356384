import React, { useState } from 'react'

import './style.css'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import ConfirmationPopup from '../ConfirmationPopup'

export default function MenuHeader ({
  children,
  classes,
  title,
  buttonText,
  navigateTo,
  navigateOptions = {},
  confirmToBack = false,
  ...props
}) {
  const navigate = useNavigate()
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)

  return (
    <div className={'menu-header ' + classes}>
      {showConfirmPopup && (
        <ConfirmationPopup
          closeFunction={() => setShowConfirmPopup(false)}
          confirmationAction={() => {
            if (parseInt(navigateTo) === -1) {
              navigate(-1, navigateOptions)
            } else {
              navigate(`/${navigateTo}`, navigateOptions)
            }
          }}
        >
          <p className='change-popup-title'>
            <strong>Tem certeza</strong> que deseja continuar? <br /> <br />
            <span>
              Você inseriu algumas informações que ainda não foram salvas e{' '}
              <strong>serão perdidas</strong> caso continue.
            </span>
          </p>
        </ConfirmationPopup>
      )}
      {children}
      <h3 className='menu-title'>{title}</h3>
      <Button
        text={buttonText}
        classes='action-button btn-sm'
        onClick={() => {
          if (!confirmToBack) {
            if (parseInt(navigateTo) === -1) {
              navigate(-1, navigateOptions)
            } else {
              navigate(`/${navigateTo}`, navigateOptions)
            }
          } else {
            setShowConfirmPopup(true)
          }
        }}
      />
    </div>
  )
}
