const dayPTToEN = day => {
  if (day === 'Segunda') return 'monday'
  if (day === 'Terça') return 'tuesday'
  if (day === 'Quarta') return 'wednesday'
  if (day === 'Quinta') return 'thursday'
  if (day === 'Sexta') return 'friday'
  if (day === 'Sábado') return 'saturday'
  if (day === 'Domingo') return 'sunday'
}

const dayENToPT = day => {
  if (day === 'monday') return 'Segunda'
  if (day === 'tuesday') return 'Terça'
  if (day === 'wednesday') return 'Quarta'
  if (day === 'thursday') return 'Quinta'
  if (day === 'friday') return 'Sexta'
  if (day === 'saturday') return 'Sábado'
  if (day === 'sunday') return 'Domingo'
}

const convertDayToNumber = day => {
  if (day === 'sunday') return 1
  if (day === 'monday') return 2
  if (day === 'tuesday') return 3
  if (day === 'wednesday') return 4
  if (day === 'thursday') return 5
  if (day === 'friday') return 6
  if (day === 'saturday') return 7
}

export { dayPTToEN, dayENToPT, convertDayToNumber }
