import React, { useRef, useEffect } from 'react'

import './style.css'

export default function SelectAppPopup ({ children, closeFunction, ...props }) {
  function useOutsideAlerter (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeFunction()
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className='select-app-popup'>
      <div className='select-app-popup-container' ref={wrapperRef}>
        <h4 className='select-app-title'>Abrir com</h4>
        <div className='select-app-content'>{children}</div>
      </div>
    </div>
  )
}
