import React, { useEffect, useState } from 'react'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import OwnerForm from '../OwnerForm'

import { useSelector } from 'react-redux'
import { selectPetshopResponsiblePerson } from '../../../features/petshop/petshopSlice'

export default function OwnerAddEdit () {
  const responsiblePerson = useSelector(selectPetshopResponsiblePerson)

  const [responsiblePersonFormData, setResponsiblePersonFormData] = useState({})

  useEffect(() => {
    if (responsiblePerson) {
      const { name, cpf, phoneNumber } = responsiblePerson
      setResponsiblePersonFormData({
        name,
        cpf,
        phoneNumber
      })
    }
  }, [responsiblePerson])

  return (
    <div className='owner-crud-page-container'>
      <div className='owner-crud-page-inner-container'>
        <MenuHeader
          buttonText='Voltar'
          title='Dados Responsável'
          classes='floating-left'
          navigateTo='minha-conta'
        />
        <div className='owner-crud-content'>
          <OwnerForm owner={responsiblePersonFormData} />
        </div>
      </div>
    </div>
  )
}
