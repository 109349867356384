const furSizeToString = furSize => {
  if (furSize === 'short') return 'curto'
  if (furSize === 'long') return 'longo'
}

const stringToFurSize = furSizeString => {
  furSizeString = furSizeString.toLowerCase()
  if (furSizeString === 'curto') return 'short'
  if (furSizeString === 'longo') return 'long'
}

const bodySizeToString = bodySize => {
  if (bodySize === 'small') return 'pequeno'
  if (bodySize === 'medium') return 'médio'
  if (bodySize === 'large') return 'grande'
  if (bodySize === 'special') return 'especial'
  if (bodySize === 'other') return 'outro'
}

const stringToBodySize = bodySizeString => {
  bodySizeString = bodySizeString.toLowerCase()
  if (bodySizeString === 'pequeno') return 'small'
  if (bodySizeString === 'médio') return 'medium'
  if (bodySizeString === 'grande') return 'large'
  if (bodySizeString === 'especial') return 'special'
  if (bodySizeString === 'outro') return 'other'
  return undefined
}

export { furSizeToString, bodySizeToString, stringToFurSize, stringToBodySize }
