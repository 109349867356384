import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import MenuHeader from '../../../../components/MenuHeader'
import PackageForm from '../PackageForm'

import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../../features/auth/authSlice'
import { createPetshopBundle } from '../../../../services/api/apiCalls'
import { toast } from 'react-toastify'

export default function AddPackagePage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const handleAddPackage = async data => {
    setIsLoading(true)
    const { discountPercentage, maxInstallments, serviceAmount } = data
    const bundle = { serviceAmount }
    bundle.discountPercentage = parseInt(
      discountPercentage.replace(/[\D]/g, '')
    )
    bundle.maxInstallments = parseInt(maxInstallments.value)

    try {
      await createPetshopBundle(authToken, bundle)
      toast.success('Pacato criado com sucesso!')
      navigate('/meus-servicos/meus-pacotes')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='add-package-page-container'>
      <div className='add-package-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Adicionar Pacote'
          buttonText='Voltar'
          navigateTo='meus-servicos/meus-pacotes'
        />
        <div className='add-package-content'>
          <PackageForm
            formSubmitFunction={data => handleAddPackage(data)}
            action='add'
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
