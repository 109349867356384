import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'

import authReducer from '../features/auth/authSlice'
import petshopReducer from '../features/petshop/petshopSlice'
import planReducer from '../features/plan/planSlice'
import workerReducer from '../features/worker/workerSlice'

const persistConfig = {
  key: 'auth',
  storage
}

const rootReducer = combineReducers({
  petshop: petshopReducer,
  auth: authReducer,
  plan: planReducer,
  worker: workerReducer
})

const appReducer = (state, action) => {
  if (action.type === REHYDRATE) {
    localStorage.setItem('isPageRefreshed', '1')
  }
  return rootReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, appReducer)

export const store = configureStore({
  reducer: {
    reducer: persistedReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)
