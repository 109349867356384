import React, { forwardRef } from 'react'

import './style.css'

const Card = (
  { onClick, children, description, classes = '', ...props },
  ref
) => {
  return (
    <div className={'card-container ' + classes} onClick={onClick} ref={ref}>
      {children}
    </div>
  )
}

export default forwardRef(Card)
