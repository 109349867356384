import React, { useState } from 'react'

import EmailSent from './EmailSent'
import SendRecoveryEmail from './SendRecoveryEmail'
import Header from '../../../components/Header'

import './style.css'

export default function ForgetPasswordPage (props) {
  const [emailSent, setEmailSent] = useState(false)

  return (
    <div className='forget-password-page-container bg-color-identity-1'>
      <div className='forget-password-page-inner-container'>
        <Header />
        {emailSent ? (
          <EmailSent />
        ) : (
          <SendRecoveryEmail onSent={resp => setEmailSent(resp)} />
        )}
      </div>
    </div>
  )
}
