import React, { useRef, useEffect } from 'react'

import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import './style.css'

export default function Popup ({
  children,
  canClose = true,
  closeOnClickOut = true,
  closeFunction,
  className = 'popup',
  hasCloseButton = true,
  ...props
}) {
  function useOutsideAlerter (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          closeOnClickOut &&
          canClose
        ) {
          closeFunction()
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const closeButton = () => (
    <div className='close-btn' onClick={closeFunction}>
      <CloseIcon />
    </div>
  )
  return (
    <div className={className}>
      <div className={`${className}-inner`} ref={wrapperRef}>
        {canClose && hasCloseButton ? closeButton() : ''}
        {children}
      </div>
    </div>
  )
}
