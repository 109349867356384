const handleServerError = error => {
  const messageError = error?.response?.data?.displayMessage
  if (messageError) {
    return messageError
  } else {
    if (error?.response?.status >= 500)
      return 'Erro interno, tente novamente em alguns minutos. Se o problema persistir entre em contato com o suporte.'
    if (error?.response?.status === 409) {
      if (error?.response?.data?.data?.constraint === 'user_email_unique')
        return 'O e-mail já está em uso.'
    }
    if (error?.response?.status === 401) {
      if (error?.response?.data?.message === 'Old or used token.') {
        return 'Token já utilizado ou expirado. Solicite um novo e-mail de recuperação de senha e tente novamente.'
      }
      return 'Sessão expirada.'
    }
  }
  return 'Erro.'
}

export default handleServerError
