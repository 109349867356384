import React from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import Button from '../../../components/Button'

export default function PrivacyTermsPage (props) {
  const navigate = useNavigate()

  function goBack () {
    navigate(-1)
  }

  return (
    <div className='privacy-terms-page-container bg-color-identity-1'>
      <div className='privacy-terms-page-inner-container'>
        <div className='privacy-terms-header'>
          <Button
            text='Voltar'
            classes='action-button'
            onClick={() => goBack()}
          />
          <div className='privacy-terms-header-title-container'>
            <h1>Políticas de Privacidade</h1>
          </div>
        </div>
        <div className='privacy-terms-content'>
          <p>
            Esta política detalha as informações utilizadas pelos aplicativos
            Gepety a fim de proporcionar uma boa experiência aos nossos usuários
            e uma evolução constante na qualidade de nossos serviços.
          </p>
          <br />
          <h2>Quais informações coletamos?</h2>
          <br />
          <p>
            Para fornecer nossos serviços precisamos coletar informações tanto
            de pessoas jurídicas quanto de pessoas físicas, dependendo do escopo
            do aplicativo utilizado. Essas informações são recolhidas no momento
            do cadastro de cada usuário.
          </p>
          <br />
          <h2>Como usamos essas informações?</h2>
          <br />
          <p>Usamos as informações coletadas para:</p>
          <br />
          <ul>
            <li>Realizar o controle dos usuários cadastrados;</li>
            <li>Aprimorar a qualidade dos serviços oferecidos;</li>
            <li>
              Comunicação com usuários (e-mails de recuperação de senha,
              confirmações de pagamento, eventuais comunicações de marketing,
              informações sobre atualizações dos nossos termos e políticas e
              respostas a solicitações de suporte);
            </li>
          </ul>
          <br />
          <h2>Como essas informações são compartilhadas?</h2>
          <br />
          <p>
            Todas as informações coletadas são mantidas internamente. Não
            negociamos nem repassamos as informações coletadas para terceiros.
            Entretanto, utilizamos serviços externos para nos auxiliar com a
            correção de problemas que podem ocorrer com nossos próprios
            serviços.
          </p>
          <br />
          <p>
            Podemos também utilizar plataformas de análise de tráfego como
            Google Analytics para nos auxiliar a entender o fluxo e interação de
            nossos usuários com nossas plataformas a fim de melhorar o
            desempenho de nossos serviços. É importante ressaltar que todos
            esses dados recolhidos são genéricos e não de um único usuário.
          </p>
          <br />
          <h2>Como excluir essas informações dos aplicativos?</h2>
          <br />
          <p>
            Quando cadastra e insere seus dados nas nossas plataformas,
            entendemos que o usuário está de acordo com nossos termos e que
            concordou com o recolhimento dessas informações.
          </p>
          <br />
          <p>
            Mas caso tenha mudado de ideia e deseje excluir suas informações,
            basta entrar em contato conosco através do e-mail
            contato@gepety.com.br. É importante ressaltar que ao excluir suas
            informações, o acesso de um usuário ao aplicativo fica
            impossibilitado, sendo disponível novamente somente após a
            realização de um novo cadastro.
          </p>
          <br />
          <h2>Sobre os cookies</h2>
          <br />
          <p>
            Os cookies são utilizados para melhorar a experiência de nossos
            usuários durante o uso dos nossos aplicativos. Se preferir, o
            usuário pode utilizar a navegação anônima de seu navegador para que
            os cookies não sejam armazenados no seu computador de maneira
            permanente, porém suas informações de navegação ainda assim serão
            coletadas.
          </p>
          <br />

          <h2>Sobre futuras mudanças nessa política</h2>
          <br />
          <p>
            Notificaremos nossos usuários sobre eventuais alterações nesta
            política de privacidade e daremos sempre a oportunidade de continuar
            utilizando nossos serviços ou não após a análise da mesma.
          </p>

          <br />
        </div>
      </div>
    </div>
  )
}
