import React from 'react'

import './style.css'
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg'
import { ReactComponent as UnCheckIcon } from '../../assets/images/icons/unCheck.svg'
import Card from '../Card'

function CheckboxCard ({
  checkboxLabel,
  checkboxValue,
  optionSelected,
  checkFunction,
  ...props
}) {
  return (
    <Card
      classes={
        (optionSelected?.value === checkboxValue ? 'card-checked ' : '') +
        'custom-checkbox-card'
      }
    >
      <input type='checkbox' id='custom-checkbox' />
      <label
        htmlFor='custom-checkbox'
        className='custom-checkbox-container'
        onClick={() => checkFunction()}
      >
        {optionSelected?.value === checkboxValue ? (
          <CheckIcon />
        ) : (
          <UnCheckIcon />
        )}
        <span className='checkbox-title'>{checkboxLabel}</span>
      </label>
    </Card>
  )
}

export default CheckboxCard
