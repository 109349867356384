export const searchClientsByNameOrPhone = (clients, term) => {
  const searchClientsByName = [...clients].filter(client => {
    //name without accentuation
    const clearName = client.name
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase()
    //term without accentuation
    const clearTerm = term
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase()

    return clearName.includes(clearTerm)
  })

  let searchResult

  if (searchClientsByName.length > 0) {
    searchResult = searchClientsByName
  } else {
    searchResult = [...clients].filter(client => {
      return client.phoneNumber.includes(term)
    })
  }
  return searchResult
}
