import React, { useState, useRef, useEffect } from 'react'

import './style.css'
import { ReactComponent as TrashIcon } from '../../assets/images/icons/trash.svg'
import { ReactComponent as AlertIcon } from '../../assets/images/icons/alert.svg'
import { ReactComponent as RemoveIcon } from '../../assets/images/icons/remove.svg'
import { ReactComponent as RemoveRoundedIcon } from '../../assets/images/icons/removeRounded.svg'
import Button from '../Button'

export default function TwoStepsRemove ({
  removeFunction,
  icon = 'trash',
  ...props
}) {
  const [removeState, setRemoveState] = useState('initial')

  useEffect(() => {
    setInterval(() => {
      setRemoveState('initial')
    }, 5000)
  }, [])

  function useOutsideAlerter (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setRemoveState('initial')
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className='remove-flow' ref={wrapperRef}>
      {removeState === 'initial' ? (
        icon === 'trash' ? (
          <TrashIcon
            className='schedule-trash-icon'
            onClick={() => setRemoveState('confirm')}
          />
        ) : icon === 'remove' ? (
          <RemoveIcon
            className='schedule-trash-icon'
            onClick={() => setRemoveState('confirm')}
          />
        ) : (
          icon === 'removeRounded' && (
            <RemoveRoundedIcon
              className='schedule-trash-icon'
              onClick={() => setRemoveState('confirm')}
            />
          )
        )
      ) : (
        <Button
          classes='alert-button'
          text='Clique p/ apagar'
          onClick={() => removeFunction()}
          type='button'
        >
          <AlertIcon />
        </Button>
      )}
    </div>
  )
}
