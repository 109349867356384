import API from './Api'

// Locals APIs for test
// import { localAPI, localAPI2 } from './Api'

const login = data => API.post('/auth/local', data)

const signUp = data => API.post('/backend/petshop', data)

const getPetshopInfo = authToken =>
  API.get('/backend/petshop', {
    headers: {
      Authorization: authToken
    }
  })

const updatePetshopInfo = (authToken, data) =>
  API.patch('/backend/petshop', data, {
    headers: {
      Authorization: authToken
    }
  })

const createResponsiblePerson = (authToken, data) =>
  API.post('/backend/petshop/responsible-person', data, {
    headers: {
      Authorization: authToken
    }
  })

const updateResponsiblePerson = (authToken, data) =>
  API.patch('/backend/petshop/responsible-person', data, {
    headers: {
      Authorization: authToken
    }
  })

const getResponsiblePersonLimited = (authToken, petshopId) =>
  API.get('/backend/petshop/concise-responsible-person', {
    headers: {
      Authorization: authToken
    },
    params: { petshopId }
  })

const getResponsiblePersonAvailablePeriod = authToken =>
  API.get('/schedule/available/period/petshop/responsible-person', {
    headers: {
      Authorization: authToken
    }
  })

const updateResponsiblePersonAvailablePeriod = (authToken, data) =>
  API.put('/schedule/available/period/petshop/responsible-person', data, {
    headers: {
      Authorization: authToken
    }
  })

const getResponsiblePersonNotAvailablePeriod = authToken =>
  API.get('/schedule/available/not/period/petshop/responsible-person', {
    headers: {
      Authorization: authToken
    }
  })
const updateResponsiblePersonNotAvailablePeriod = (authToken, data) =>
  API.put('/schedule/available/not/period/petshop/responsible-person', data, {
    headers: {
      Authorization: authToken
    }
  })

const getWorkerAvailablePeriod = (authToken, workerId) =>
  API.get(`/schedule/available/period/worker/${workerId}`, {
    headers: {
      Authorization: authToken
    }
  })

const updateWorkerAvailablePerdiod = (authToken, data, workerId) =>
  API.put(`schedule/available/period/worker/${workerId}`, data, {
    headers: {
      Authorization: authToken
    }
  })

const getWorkerNotAvailablePeriod = (authToken, workerId) =>
  API.get(`/schedule/available/not/period/worker/${workerId}`, {
    headers: {
      Authorization: authToken
    }
  })

const updateWorkerNotAvailablePeriod = (authToken, data, workerId) =>
  API.put(`/schedule/available/not/period/worker/${workerId}`, data, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopServices = (authToken, petshopId) =>
  API.get('/petshop/service', {
    headers: {
      Authorization: authToken
    },
    params: { petshopId }
  })

const updatePetshopService = (authToken, data, serviceId) =>
  API.patch(`/petshop/service/${serviceId}`, data, {
    headers: {
      Authorization: authToken
    }
  })

const createService = (authToken, data) =>
  API.post('/petshop/service', data, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopBundles = (authToken, petshopId) =>
  API.get('/petshop/bundle', {
    headers: {
      Authorization: authToken
    },
    params: { petshopId }
  })

const updatePetshopBundle = (authToken, data, bundleId) =>
  API.patch(`petshop/bundle/${bundleId}`, data, {
    headers: {
      Authorization: authToken
    }
  })

const createPetshopBundle = (authToken, data) =>
  API.post(`petshop/bundle`, data, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopClientList = (authToken, petshopId) =>
  API.get('backend/client/list', {
    headers: {
      Authorization: authToken
    },
    params: { petshopId }
  })

const getAvailablePeriodsForService = (authToken, params) =>
  API.get('/schedule/available/period/service/petshop', {
    headers: {
      Authorization: authToken
    },
    params: params
  })

const createClientServiceScheduling = (authToken, data, petshopId) =>
  API.post('/petshop/schedule/client-service', data, {
    headers: {
      Authorization: authToken
    },
    params: { petshopId }
  })

const createPetshopClient = (authToken, data) =>
  API.post('/backend/client', data, {
    headers: {
      Authorization: authToken
    }
  })

const updatePetshopClient = (authToken, data, clientId) =>
  API.patch(`/backend/client/${clientId}`, data, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopConfiguration = authToken =>
  API.get('/backend/petshop/configuration', {
    headers: {
      Authorization: authToken
    }
  })

const updatePetshopConfiguration = (authToken, data) =>
  API.patch('/backend/petshop/configuration', data, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopWorkers = authToken =>
  API.get('/backend/worker/list', {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopWorkersLimited = (authToken, petshopId) =>
  API.get('/backend/worker/concise-list', {
    headers: {
      Authorization: authToken
    },
    params: { petshopId }
  })

const createPetshopWorker = (authToken, data) =>
  API.post('/backend/worker', data, {
    headers: {
      Authorization: authToken
    }
  })

const updatePetshopWorker = (authToken, data, workerId) =>
  API.patch(`/backend/worker/${workerId}`, data, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopNextAppointments = (authToken, params) =>
  API.get('/petshop/schedule/client-service/list', {
    headers: {
      Authorization: authToken
    },
    params: params
  })

const getPetshopNextAppointmentsForWorker = (authToken, params) =>
  API.get('/petshop/schedule/client-service/list/worker', {
    headers: {
      Authorization: authToken
    },
    params: params
  })

const getClientServiceHistory = (authToken, clientId) =>
  API.get(`/petshop/schedule/client-service/client/${clientId}`, {
    headers: {
      Authorization: authToken
    }
  })

const getPetshopPlan = authToken =>
  API.get('/subscription/current', {
    headers: {
      Authorization: authToken
    }
  })

const getPlans = () => API.get('/subscription/plan/list')

const startScheduledCustomerService = (
  authToken,
  scheduledServiceId,
  petshopId
) =>
  API.post(
    `/petshop/schedule/client-service-step/${scheduledServiceId}`,
    null,
    {
      headers: {
        Authorization: authToken
      },
      params: { petshopId }
    }
  )

const customerServiceNextStep = (
  authToken,
  data,
  scheduledServiceId,
  stepId,
  petshopId
) =>
  API.patch(
    `/petshop/schedule/client-service/${scheduledServiceId}/client-service-step/${stepId}/completed`,
    data,
    {
      headers: {
        Authorization: authToken
      },
      params: { petshopId }
    }
  )

const customerServicePreviousStep = (
  authToken,
  data,
  scheduledServiceId,
  stepId,
  petshopId
) =>
  API.patch(
    `/petshop/schedule/client-service/${scheduledServiceId}/client-service-step/${stepId}/returned`,
    data,
    {
      headers: {
        Authorization: authToken
      },
      params: { petshopId }
    }
  )

const deleteBundle = (authToken, bundleId) =>
  API.delete(`/petshop/bundle/${bundleId}`, {
    headers: {
      Authorization: authToken
    }
  })

const deleteService = (authToken, serviceId) =>
  API.delete(`/petshop/bundle/${serviceId}`, {
    headers: {
      Authorization: authToken
    }
  })

const resetPasswordWithToken = (authToken, data) =>
  API.post('/auth/reset/submit', data, {
    headers: {
      Authorization: authToken
    }
  })

const sendChangePasswordEmail = data => API.post('/auth/change/request', data)

const getWorkerInfo = authToken =>
  API.get('/backend/worker', {
    headers: {
      Authorization: authToken
    }
  })

const workerGetPetshopPlan = ({ authToken, petshopId }) =>
  API.get(`/subscription/worker/petshop/${petshopId}/current`, {
    headers: {
      Authorization: authToken
    }
  })

const createPaymentCustomer = authToken =>
  API.post('/payment/pagarme/customer', null, {
    headers: {
      Authorization: authToken
    }
  })

const createCustomerCard = (authToken, data) =>
  API.post('/payment/pagarme/card', data, {
    headers: {
      Authorization: authToken
    }
  })

const getCustomerCards = authToken =>
  API.get('/payment/pagarme/card', {
    headers: {
      Authorization: authToken
    }
  })

const createSubscription = (authToken, data) =>
  API.post('/subscription/new', data, {
    headers: {
      Authorization: authToken
    }
  })

const updateClientCpf = (authToken, data, clientId) =>
  API.patch(`/backend/client/${clientId}/cpf`, data, {
    headers: {
      Authorization: authToken
    }
  })

const getScheduledBillingPixCode = (authToken, data) =>
  API.post('/petshop/service-billing/petshop', data, {
    headers: {
      Authorization: authToken
    }
  })
const cancelScheduledClientService = ({
  authToken,
  scheduledClientServiceId,
  data
}) =>
  API.post(
    `/petshop/schedule/client-service/${scheduledClientServiceId}/cancel`,
    data,
    {
      headers: {
        Authorization: authToken
      }
    }
  )

export {
  login,
  signUp,
  getPetshopInfo,
  updatePetshopInfo,
  createResponsiblePerson,
  getResponsiblePersonAvailablePeriod,
  updateResponsiblePersonAvailablePeriod,
  getResponsiblePersonNotAvailablePeriod,
  updateResponsiblePersonNotAvailablePeriod,
  getPetshopServices,
  updatePetshopService,
  createService,
  getPetshopBundles,
  updatePetshopBundle,
  createPetshopBundle,
  getPetshopClientList,
  getAvailablePeriodsForService,
  createClientServiceScheduling,
  createPetshopClient,
  updatePetshopClient,
  getPetshopConfiguration,
  updatePetshopConfiguration,
  getPetshopWorkers,
  createPetshopWorker,
  updatePetshopWorker,
  getWorkerAvailablePeriod,
  getWorkerNotAvailablePeriod,
  updateWorkerAvailablePerdiod,
  updateWorkerNotAvailablePeriod,
  getPetshopNextAppointments,
  getClientServiceHistory,
  getPetshopPlan,
  getPlans,
  startScheduledCustomerService,
  customerServiceNextStep,
  customerServicePreviousStep,
  deleteBundle,
  deleteService,
  resetPasswordWithToken,
  sendChangePasswordEmail,
  getWorkerInfo,
  workerGetPetshopPlan,
  updateResponsiblePerson,
  getPetshopWorkersLimited,
  getResponsiblePersonLimited,
  createPaymentCustomer,
  createCustomerCard,
  getCustomerCards,
  createSubscription,
  getPetshopNextAppointmentsForWorker,
  updateClientCpf,
  getScheduledBillingPixCode,
  cancelScheduledClientService
}
