import React from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import Popup from '../../../components/Popup'
import Button from '../../../components/Button'
import {
  calculeTotalValue,
  periodCycle
} from '../../../services/utils/helpers/planHelper'
import { normalizePrice } from '../../../services/utils/masks'

export default function ConfirmBuyPopup ({
  planDetails,
  closeFunction,
  createSubscription,
  setPopupState,
  popupState,
  isConfirmationButtonLoading,
  ...props
}) {
  const navigate = useNavigate()

  const monthlyPlan = planDetails?.period?.periodCycle === 'monthly'

  const renderPopupContent = () => {
    const { period, plan } = planDetails
    const { label, value } = periodCycle[period?.periodCycle]
    return popupState === 'not-confirmed' ? (
      <>
        <h3 className='confirm-buy-title'>Finalizar Compra</h3>
        <p className='confirm-buy-content'>
          Você está prestes a realizar o pagamento do{' '}
          <strong>
            {plan?.name} - {label}!
          </strong>
          <br />
          <br />O pagamento será feito em {renderInstallmentsText()}
          <br />
          <br />
          Após <strong>{renderRenewalInterval(value)}</strong>, realizaremos a
          mesma cobrança de maneira <strong>automática</strong>, sem que você
          precise se preocupar com assinar novamente!
          <br />
          <br />
          Você pode cancelar sua assinatura a qualquer momento entrando em
          contato com nosso suporte.
        </p>
        <div className='confirm-buy-buttons'>
          <Button
            text='Cancelar'
            classes='alert-button'
            onClick={closeFunction}
          />
          <Button
            text='Comprar'
            classes='alert-button confirm-buy-button'
            onClick={() => createSubscription()}
            isLoading={isConfirmationButtonLoading}
          />
        </div>
      </>
    ) : (
      <>
        <h3 className='confirm-buy-title'>Compra Finalizada!</h3>
        <p className='confirm-buy-content'>
          Você finalizou sua compra!
          <br />
          <br />
          Pode ser que demore um <strong>tempinho</strong> para que ela seja{' '}
          <strong>aprovada</strong> pela nossa empresa parceira.
          <br />
          <br />
          Enquanto isso, vamos <strong>liberar</strong> o app para você já poder{' '}
          <strong>aproveitar</strong> os <strong>benefícios</strong> e começar a{' '}
          <strong>economizar</strong> seu tempo!
          <br />
          <br />
          Se tiver algum problema no seu pagamento, pode ficar{' '}
          <strong>tranquilo</strong> que <strong>iremos</strong> entrar em{' '}
          <strong>contato.</strong>
          <br />
          <br />
          Bons atendimentos!
        </p>
        <Button
          text='Continuar'
          classes='primary-button continue-button'
          onClick={() => {
            navigate('/menu', { state: { signedPlan: true } })
          }}
        />
      </>
    )
  }

  const renderInstallmentsText = () => {
    if (monthlyPlan) {
      return (
        <>
          <strong>1 parcela</strong> de{' '}
          <strong>{normalizePrice(planDetails?.period?.price)}.</strong>
        </>
      )
    }

    const { value } = periodCycle[planDetails?.period?.periodCycle]
    const price = planDetails?.period?.price
    return (
      <>
        <strong>{value} parcelas</strong> de{' '}
        <strong>{normalizePrice(planDetails?.period?.price)}</strong>,
        totalizando{' '}
        <strong>
          {' '}
          {calculeTotalValue(price, planDetails?.period?.periodCycle)}
        </strong>
      </>
    )
  }

  const renderRenewalInterval = months => {
    if (parseInt(months) === 1) {
      return '1 mês'
    }
    //will change when integrating
    return `${months} meses`
  }

  return (
    <Popup closeFunction={closeFunction} className='confirm-buy-popup popup'>
      {renderPopupContent()}
    </Popup>
  )
}
