import React, { useState, useEffect } from 'react'

import './style.css'
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg'
import Input from '../Input'

export default function SearchField ({
  onSearchSubmit,
  clearResults,
  placeholder,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    if (searchTerm !== '') {
      onSearchSubmit(searchTerm)
    } else {
      clearResults()
    }
    // eslint-disable-next-line
  }, [searchTerm])

  return (
    <Input
      placeholder={placeholder}
      type='text'
      name='search-bar'
      onChange={e => setSearchTerm(e.target.value)}
      value={searchTerm}
    >
      <SearchIcon />
    </Input>
  )
}
