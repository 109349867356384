import React from 'react'

import './style.css'
import Button from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'

export default function EmailSent (props) {
  const navigate = useNavigate()

  function goBack () {
    navigate(-1)
  }

  return (
    <div className='email-sent-container'>
      <div className='email-sent-header'>Esqueci a Senha</div>
      <div className='email-sent-content'>
        <span>E-mail enviado com sucesso!</span>
        <span>
          <strong>Aguarde alguns instantes</strong> e confira sua caixa de
          mensagens.
        </span>
        <span className='email-sent-warning-message'>
          Se não receber nenhum e-mail dentro de 2 minutos, verifique sua{' '}
          <strong>caixa de spam</strong>!
        </span>
        <Button text='Voltar' classes='primary-button' onClick={goBack} />
      </div>
    </div>
  )
}
