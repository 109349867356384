import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import './style.css'
import Button from '../../../components/Button'
import MenuHeader from '../../../components/MenuHeader'
import ServiceCard from '../../../components/DropdownCard'
import SearchField from '../../../components/SearchField'

import { useSelector } from 'react-redux'
import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../../features/auth/authSlice'
import {
  deleteService,
  getPetshopServices
} from '../../../services/api/apiCalls'
import { normalizePrice } from '../../../services/utils/masks'
import SkeletonButton from '../../../skeletonComponents/Button'
import SkeletonText from '../../../skeletonComponents/Text'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'
import { toast } from 'react-toastify'
import { selectWorkerInfo } from '../../../features/worker/workerSlice'
import { isWorker } from '../../../services/utils/helpers/authHelper'

const toBodySizeText = bodySize => {
  if (bodySize === 'small') return 'Pequeno'
  if (bodySize === 'medium') return 'Médio'
  if (bodySize === 'large') return 'Grande'
  if (bodySize === 'special') return 'Especial'
  if (bodySize === 'other') return 'Outro'
}

const toFurSizeText = furSize => {
  if (furSize === 'short') return 'curto'
  return 'longo'
}

export default function MyServicesPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const navigate = useNavigate()
  const location = useLocation()

  const [services, setServices] = useState([])
  const [packages, setPackages] = useState([])
  const [servicesToShow, setServicesToShow] = useState([])
  const [servicesNotFound, setServicesNotFound] = useState(false)

  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    async function getServices () {
      if (!authToken) return

      setIsPageLoading(true)

      const locationState = location.state

      if (locationState?.packages?.length > 0) {
        setPackages(locationState.packages)
      }

      if (locationState?.services?.length > 0) {
        setServices(locationState.services)
        setServicesToShow(locationState.services)
        setIsPageLoading(false)
        return
      }

      try {
        const petshopId = workerInfo?.petshops[0]?.id
        const response = await getPetshopServices(authToken, petshopId)
        setServices(response.data)
        setServicesToShow(response.data)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getServices()
  }, [authToken, location.state, workerInfo])

  const handleRemoveService = async serviceId => {
    try {
      await deleteService(authToken, serviceId)
      setServices(curr => curr.filter(service => service.id !== serviceId))
      setServicesToShow(curr =>
        curr.filter(service => service.id !== serviceId)
      )
      toast.success('Service removido com sucesso!')
    } catch (error) {
      console.error(error)
    }
  }

  const renderSteps = steps => {
    return steps
      .sort((a, b) =>
        a.rankNumber < b.rankNumber ? -1 : a.rankNumber > b.rankNumber ? 1 : 0
      )
      .map(step => step.name)
      .join(', ')
  }

  const renderServices = () => {
    return servicesToShow.map((service, index) => {
      return (
        <ServiceCard
          title={service.name}
          key={service.id}
          removeFunction={() => handleRemoveService(service.id)}
          editFunction={() =>
            navigate(`editar-servico/${service.id}`, { state: { service } })
          }
          removalConfirmationPopup={true}
          objectRemovedMessage={`o serviço "${service.name}"`}
          {...(isWorker(auth) && { readOnly: true })}
        >
          <div className='service-card-info'>
            {service.description && (
              <p className='service-description'>{service.description}</p>
            )}
            {service?.serviceValues?.map(serviceValue => {
              const value = normalizePrice(serviceValue.value)
              return (
                <div key={serviceValue.id}>
                  <p className='service-info'>
                    <strong>
                      {toBodySizeText(serviceValue.bodySize)} pelo{' '}
                      {toFurSizeText(serviceValue.furSize)}:
                    </strong>{' '}
                    {value}, {serviceValue.time}min
                  </p>
                </div>
              )
            })}
            <p className='service-steps'>
              <strong>Etapas: </strong>
              {service?.serviceSteps && renderSteps(service.serviceSteps)}.
            </p>
          </div>
        </ServiceCard>
      )
    })
  }

  //temporary while there is no backend integration
  const searchFunction = term => {
    const newServices = [...services].filter(service => {
      //name without accentuation
      const clearName = service.name
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
      //term without accentuation
      const clearTerm = term
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()

      return clearName.includes(clearTerm)
    })
    if (newServices.length !== 0) {
      setServicesToShow(newServices)
      setServicesNotFound(false)
    } else {
      setServicesNotFound(true)
    }
  }

  const renderServicePageContent = () => {
    return (
      <div>
        <SearchField
          onSearchSubmit={searchFunction}
          clearResults={() => {
            setServicesToShow(services)
            setServicesNotFound(false)
          }}
          placeholder='Buscar Serviço'
        />
        <div className='my-services-content-header'>
          <h3>Serviços</h3>
          <Button
            classes='action-button-tertiary'
            text='Pacotes'
            onClick={() =>
              navigate('meus-pacotes', { state: { services, packages } })
            }
          />
        </div>
        {!servicesNotFound && (
          <p className='service-page-explanation-message'>
            Clique em um serviço para expandir e ver os detalhes.
          </p>
        )}

        {servicesNotFound ? (
          <p className='not-found-message'>
            Nenhum serviço correspondente à sua busca foi encontrado.
          </p>
        ) : (
          renderServices()
        )}
      </div>
    )
  }

  const renderSkeleton = () => {
    return (
      <div className='my-services-content'>
        <SkeletonButton height={'35px'} style={{ borderRadius: '30px' }} />
        <div className='my-services-content-header'>
          <SkeletonText
            height={'22px'}
            svgWidth='74px'
            width='74px'
            style={{ borderRadius: '8px', marginRight: '18%' }}
          />
          <SkeletonText
            height={'32px'}
            svgWidth='160px'
            width='160px'
            style={{ borderRadius: '16px' }}
          />
        </div>
        <SkeletonText
          height={'17px'}
          x={'25%'}
          rx='8'
          ry='8'
          style={{ borderRadius: '8px', marginBottom: 'min(4vh, 24px)' }}
        />
        {renderComponentNTimes(5, SkeletonButton, {
          style: { borderRadius: '10px', marginBottom: 'min(3vh, 24px)' },
          height: '49px'
        })}
        <SkeletonButton height={'47px'} style={{ borderRadius: '25px' }} />
      </div>
    )
  }

  return (
    <div className='my-services-page-container'>
      <div className='my-services-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Meus Serviços'
          buttonText='Menu'
          navigateTo='menu'
        />
        {isPageLoading ? (
          renderSkeleton()
        ) : (
          <div className='my-services-content'>
            <div className='services-container'>
              {services?.length > 0 ? (
                renderServicePageContent()
              ) : (
                <p className='empty-services'>
                  Você ainda não tem serviços. Clique no botão abaixo para
                  adicionar.
                </p>
              )}
            </div>
            <Button
              text='Adicionar Serviço'
              classes='primary-button fixed-button'
              onClick={() => navigate('novo-servico')}
            />
          </div>
        )}
      </div>
    </div>
  )
}
