import React, { useEffect, useMemo, useState } from 'react'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import './style.css'
import cpfValidator from '../../../services/utils/validators/cpfValidator'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import {
  normalizeCPF,
  normalizePhoneNumber
} from '../../../services/utils/masks'
import { updateResponsiblePerson } from '../../../services/api/apiCalls'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import { toast } from 'react-toastify'
import { setResponsiblePerson } from '../../../features/petshop/petshopSlice'

const validationSchema = object({
  name: string().required('Informe o nome do responsável.'),
  cpf: string()
    .required('Informe o CPF do responsável')
    .test('is-cpf-valid', 'CPF inválido', value => cpfValidator(value)),
  phoneNumber: string()
    .required('Informe o telefone do colaborador.')
    .min(14, 'Formato incorreto')
})

//TODO: bring information from the backend about the person responsible
export default function OwnerForm ({ owner, ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const dispatch = useDispatch()

  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return owner
      }, [owner])
    }
  })

  useEffect(() => {
    reset(owner)
  }, [owner, reset])

  const [isLoading, setIsLoading] = useState(false)

  const phone = watch('phoneNumber')

  //masking the phone field
  useEffect(() => {
    if (phone) setValue('phoneNumber', normalizePhoneNumber(phone))
  }, [setValue, phone])

  const cpf = watch('cpf')

  //masking the cpf field
  useEffect(() => {
    if (cpf) setValue('cpf', normalizeCPF(cpf))
  }, [setValue, cpf])

  const handleFormSubmission = async data => {
    if (!isDirty) {
      toast.warning('Nenhuma mudança detectada!')
      return
    }

    const phoneNumber = data.phoneNumber.replace(/[\D]/g, '')
    try {
      setIsLoading(true)
      const response = await updateResponsiblePerson(authToken, {
        phoneNumber,
        ...data
      })
      toast.success('Dados alterados com sucesso!')
      dispatch(setResponsiblePerson({ responsiblePerson: response.data }))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmission)} className='owner-form'>
      <div className='owner-input-container'>
        <label htmlFor='name-input' className='owner-input-title'>
          Nome
        </label>
        <div>
          <Input
            type='text'
            placeholder='Nome Responsável'
            isValid={errors?.name === undefined}
            {...register('name')}
          />
          <span
            className={errors?.name?.message ? 'add-client-field-error' : ''}
          >
            {errors?.name?.message}
          </span>
        </div>
      </div>
      <div className='owner-input-container'>
        <label htmlFor='cpf-input' className='owner-input-title'>
          CPF
        </label>
        <div>
          <Input
            type='text'
            placeholder='Nome Responsável'
            isValid={errors?.cpf === undefined}
            {...register('cpf')}
          />
          <span
            className={errors?.cpf?.message ? 'add-client-field-error' : ''}
          >
            {errors?.cpf?.message}
          </span>
        </div>
      </div>
      <div className='owner-input-container'>
        <label htmlFor='phoneNumber-input' className='owner-input-title'>
          Telefone
        </label>
        <div>
          <Input
            type='text'
            placeholder='Nome Responsável'
            isValid={errors?.phoneNumber === undefined}
            {...register('phoneNumber')}
          />
          <span
            className={
              errors?.phoneNumber?.message ? 'add-client-field-error' : ''
            }
          >
            {errors?.phoneNumber?.message}
          </span>
        </div>
      </div>
      <Button text='Salvar' classes='primary-button' isLoading={isLoading} />
    </form>
  )
}
