export const validateCard = cardNumber => {
  if (!cardNumber) return false

  if (cardNumber.replace(/[\D]/g, '').length < 14) return false

  const card = cardNumber?.replace(/\D/g, '')
  const cardNumberArray = card?.split('')
  // applying luhn algorithm
  const sum = [...cardNumberArray]
    ?.reverse()
    ?.map(value => parseInt(value))
    ?.reduce((accumulator, num, index) => {
      if (index % 2 === 0) return accumulator + parseInt(num)
      let double = parseInt(num) * 2
      double = double > 9 ? double - 9 : double
      return accumulator + double
    })

  return sum % 10 === 0 ? true : false
}
