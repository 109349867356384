import { createSlice } from '@reduxjs/toolkit'

const planSlice = createSlice({
  name: 'plan',
  initialState: { currentPlan: null },
  reducers: {
    setCurrentPlan: (state, action) => {
      const { currentPlan } = action.payload
      state.currentPlan = currentPlan
    },
    removeCurrentPlan: state => {
      state.currentPlan = null
    }
  }
})

export const { setCurrentPlan, removeCurrentPlan } = planSlice.actions

export default planSlice.reducer

export const selectCurrentPlan = state => state.reducer.plan.currentPlan
