import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { object, string, bool, array, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { normalizePrice } from '../../../services/utils/masks'
import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../../features/auth/authSlice'
import {
  createClientServiceScheduling,
  getPetshopBundles,
  getPetshopWorkersLimited,
  getResponsiblePersonLimited
} from '../../../services/api/apiCalls'
import {
  selectPetshopInfo,
  selectPetshopResponsiblePerson
} from '../../../features/petshop/petshopSlice'
import { selectWorkerInfo } from '../../../features/worker/workerSlice'
import { isWorker } from '../../../services/utils/helpers/authHelper'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'

import './style.css'
import MenuHeader from '../../../components/MenuHeader'
import Card from '../../../components/Card'
import CustomSelect from '../../../components/CustomSelect'
import { dayENToPT } from '../../../services/utils/helpers/dayOfTheWeek'
import { dateToString } from '../../../services/utils/helpers/dateToString'
import Button from '../../../components/Button'
import BooleanCheckbox from '../../../components/BooleanCheckbox'
import FreeSchedulesPopup from '../../../components/FreeSchedulesPopup'
import FeesPopup from '../../../components/FeesPopup'
import SkeletonText from '../../../skeletonComponents/Text'
import SkeletonButton from '../../../skeletonComponents/Button'
import ConfirmationPopup from '../../../components/ConfirmationPopup'
import AddClientCpfPopup from './AddClientCpfPopup'

const validationSchema = object({
  notes: string(),
  pickUpAndTake: bool()
    .required()
    .default(false),
  payThroughApp: bool()
    .required()
    .default(false),
  cancelIfNotPayed: bool()
    .required()
    .default(false),
  bundle: object({
    label: string().required('É preciso selecionar o número de serviços.'),
    value: object({
      serviceAmount: number(),
      discountPercentage: number().nullable(),
      maxInstallments: number().nullable(),
      id: number().nullable()
    })
  }).default({
    label: 'Nenhum - Serviço Unitário',
    value: { serviceAmount: 1, id: null, discountPercentage: 0 }
  }),
  scheduleTimes: array()
    .of(
      object({
        startTime: string().required('Nenhum horário foi selecionado.'),
        endTime: string().required('Nenhum horário foi selecionado.'),
        selectedDay: string().required('Nenhum horário foi selecionado.'),
        workerId: string().nullable()
      })
    )
    .test(
      'unique-schedule-times',
      'Os horários selecionados devem ser únicos.',
      function (scheduleTimes) {
        const values = new Set() // creates a Set to store unique values
        for (let i = 0; i < scheduleTimes.length; i++) {
          const { startTime, endTime, selectedDay } = scheduleTimes[i]
          const value = `${startTime}-${endTime}-${selectedDay}` // combines attribute values into a single string
          if (values.has(value)) {
            return false // returns false if there is a duplicate value
          }
          values.add(value)
        }
        return true // returns true if all values are unique
      }
    )
})

// Comparison function for sorting
function compareScheduleTimes (a, b) {
  // Compare the "selectedDay" properties first
  if (a.selectedDay < b.selectedDay) {
    return -1 // a should be sorted before b
  }
  if (a.selectedDay > b.selectedDay) {
    return 1 // b should be sorted before a
  }

  // If the "selectedDay" properties are equal, compare the "startTime" properties
  if (a.startTime < b.startTime) {
    return -1 // a should be sorted before b
  }
  if (a.startTime > b.startTime) {
    return 1 // b should be sorted before a
  }

  // If both properties are equal, the order is considered the same
  return 0 // a and b are considered equal in terms of sorting
}

export default function Scheduling ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const responsiblePerson = useSelector(selectPetshopResponsiblePerson)
  const petshopInfo = useSelector(selectPetshopInfo)
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const location = useLocation()
  const navigate = useNavigate()

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    resetField,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return { scheduleTimes: [location?.state?.data?.appointmentTime] }
      }, [location])
    }
  })

  // checks if the selected client already has the cpf registered
  const [clientHasCpf, setClientHasCpf] = useState(
    location.state?.selectedClientPet?.client?.cpf
  )

  // stores bundles available for purchase
  const [bundleOptions, setBundleOptions] = useState([])

  // parameters to be passed to query available schedules
  const [params, setParams] = useState({
    serviceId: '',
    bodySize: '',
    furSize: ''
  })

  // popup display controllers
  const [showTimeShiftPopup, setShowTimeShiftPopup] = useState(false)
  const [showFeesPopup, setShowFeesPopup] = useState(false)
  const [showAddCpfPopup, setShowAddCpfPopup] = useState(false)

  // schedule information
  const [schedulingData] = useState(location.state.data)

  // total scheduling price
  const [bundlePrice, setBundlePrice] = useState(0)

  // controls form button state
  const [isLoading, setIsLoading] = useState(false)

  const bundleValue = watch('bundle')?.value

  const schedules = watch('scheduleTimes')
  const payThroughApp = watch('payThroughApp')

  const [isSchedulesUpdated, setIsSchedulesUpdated] = useState(false)

  // worker information available to other workers
  const [workersRestrictedInfo, setWorkersRestrictedInfo] = useState()
  // responsible person information available to workers
  const [
    responsiblePersonRestrictedInfo,
    setResponsiblePersonRestrictedInfo
  ] = useState()

  // controls skeleton display
  const [isPageLoading, setIsPageLoading] = useState(true)

  // checks if the bundle data is in memory, otherwise it makes a request to the backend
  useEffect(() => {
    async function getWorkersInfo () {
      try {
        setIsPageLoading(true)
        // Temporary. Considering that a worker has only one petshop
        const petshopId = workerInfo?.petshops[0]?.id

        const workersResponse = await getPetshopWorkersLimited(
          authToken,
          petshopId
        )
        setWorkersRestrictedInfo(workersResponse.data)

        const responsibleResponse = await getResponsiblePersonLimited(
          authToken,
          petshopId
        )
        setResponsiblePersonRestrictedInfo(responsibleResponse.data)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    async function getBundles () {
      if (!authToken) return

      if (location.state?.bundle?.length > 0) {
        setBundleOptions(location.state.bundle)
        return
      }

      try {
        setIsPageLoading(true)
        const petshopId = isWorker(auth)
          ? workerInfo?.petshops[0]?.id
          : undefined
        const bundleResponse = await getPetshopBundles(authToken, petshopId)
        const buildBundleOptions = bundleResponse.data.map(bundle => {
          const {
            serviceAmount,
            id,
            maxInstallments,
            discountPercentage
          } = bundle
          return {
            value: { serviceAmount, id, maxInstallments, discountPercentage },
            label: `${serviceAmount} serviços`
          }
        })
        setBundleOptions([
          {
            value: {
              serviceAmount: 1,
              id: null,
              maxInstallments: null,
              discountPercentage: 0
            },
            label: 'Nenhum - Serviço Unitário'
          },
          ...buildBundleOptions
        ])
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getBundles()

    if (isWorker(auth)) {
      getWorkersInfo()
    }

    const { service, furSize, bodySize } = location.state?.data
    setParams(curr => ({
      ...curr,
      serviceId: service?.value,
      furSize: furSize?.value,
      bodySize: bodySize?.value
    }))
  }, [authToken, location.state, auth, workerInfo])

  // loads the time received via route status
  useEffect(() => {
    let {
      selectedDay,
      periodEnd,
      periodStart,
      workerId
    } = location.state?.data?.appointmentTime
    reset({
      scheduleTimes: [
        { selectedDay, endTime: periodEnd, startTime: periodStart, workerId }
      ]
    })
  }, [location, reset])

  // prevents direct access to the page
  useEffect(() => {
    if (location.state === null) {
      navigate('/consultar-horarios')
    }
  }, [location, navigate])

  // TODO: code referring to approach with multiple services, to be implemented in the future
  // const renderServices = () => {
  //   return schedulingData?.services?.map(service => {
  //     return (
  //       <p className='scheduling-card-text' key={service}>
  //         {service}
  //       </p>
  //     )
  //   })
  // }

  useEffect(() => {
    if (isSchedulesUpdated) {
      schedules.sort(compareScheduleTimes)
      setIsSchedulesUpdated(false)
    }
  }, [isSchedulesUpdated, schedules])

  // render service hours
  const renderPackageServiceSchedule = useCallback(() => {
    const renderAppointmentTime = appointmentTime => {
      const { startTime, endTime, selectedDay } = appointmentTime
      const date = new Date(selectedDay.slice(0, 10).replace(/-/g, '/'))
      const day = format(date, 'EEEE').toLowerCase()

      return `${dayENToPT(day)} - ${dateToString(
        date
      )} - ${startTime} - ${endTime}`
    }

    return schedules?.map((schedule, index) => {
      return (
        <div className='scheduling-date-hour' key={index}>
          <p className='scheduling-appointment-time'>
            {renderAppointmentTime(schedule)}
          </p>
          <div className='bundle-service-buttons'>
            {schedules?.length > 1 && (
              <Button
                type='button'
                text='Remover'
                classes='alert-button change-date-hour-button'
                onClick={() => {
                  schedules?.splice(index, 1)
                  setValue(`scheduleTimes`, schedules)
                }}
              />
            )}
            <Button
              type='button'
              text='Alterar'
              classes='action-button-tertiary change-date-hour-button'
              onClick={() => setShowTimeShiftPopup({ serviceNumber: index })}
            />
          </div>
        </div>
      )
    })
  }, [schedules, setValue])

  // while there are services without defined schedules render the button to add them
  const renderNewServiceSchedule = useCallback(() => {
    if (
      bundleValue?.serviceAmount === schedules?.length ||
      !bundleValue ||
      !schedules
    )
      return
    const serviceNumber = schedules.length
    return (
      <div className='scheduling-date-hour'>
        <p className='scheduling-appointment-time'>
          Horário {serviceNumber + 1} - Não definido
        </p>
        <Button
          type='button'
          text='Adicionar'
          classes='action-button-tertiary change-date-hour-button'
          onClick={() => setShowTimeShiftPopup({ serviceNumber })}
        />
      </div>
    )
  }, [bundleValue, schedules])

  const pickUpAndTake = watch('pickUpAndTake')

  const calculateServicePrice = useCallback(() => {
    const serviceDetails = location.state?.serviceDetails
    const { serviceId, furSize, bodySize } = params

    // finds the current service in the list of service details
    const currService = serviceDetails?.find(
      service => service.id === serviceId
    )

    // finds the current service value for the specified fur and body size
    const serviceValue = currService?.serviceValues?.find(
      serviceValue =>
        serviceValue?.furSize === furSize && serviceValue?.bodySize === bodySize
    )

    // get the current service price for the specified fur and body size
    const servicePrice = parseInt(serviceValue?.value)
    const bundleAmount = bundleValue?.serviceAmount
      ? parseInt(bundleValue.serviceAmount)
      : 1
    const discount = bundleValue?.discountPercentage
      ? parseInt(bundleValue.discountPercentage)
      : 0
    const deliveryValue = pickUpAndTake
      ? location.state.selectedClientPet.client?.address?.[0]?.deliveryValue
      : 0

    const bundlePrice = Math.round(
      servicePrice * bundleAmount * ((100 - discount) / 100) +
        deliveryValue * bundleAmount
    )

    return bundlePrice
  }, [location.state, bundleValue, params, pickUpAndTake])

  useEffect(() => {
    setBundlePrice(calculateServicePrice())
  }, [bundleValue, calculateServicePrice])

  // handles form submission
  const handleCreateScheduling = async data => {
    const schedulingInformations = {
      ...data,
      ...location.state.selectedClientPet,
      service: location.state.data.service
    }

    const {
      client,
      notes,
      scheduleTimes,
      pet,
      bundle,
      service,
      payThroughApp,
      cancelIfNotPayed,
      pickUpAndTake
    } = schedulingInformations
    const serviceId = parseInt(service.value)
    const clientId = parseInt(client.id)
    const petId = parseInt(pet.id)
    const bundleId =
      parseInt(bundle.value.serviceAmount) > 1 ? bundle.value.id : null
    const serviceAmount = parseInt(bundle.value.serviceAmount)
    const deliveryValue = pickUpAndTake ? client.address[0].deliveryValue : null
    //const workerId = worker.value === null ? null : parseInt(worker.value)

    const scheduleInfo = {
      serviceId,
      clientId,
      petId,
      ...(notes && { notes: notes }),
      bundleId,
      serviceAmount,
      payThroughApp,
      cancelIfNotPayed,
      deliveryValue
    }

    try {
      setIsLoading(true)
      const petshopId = isWorker(auth) ? workerInfo?.petshops[0]?.id : undefined
      const response = await createClientServiceScheduling(
        authToken,
        {
          scheduleInfo,
          scheduleTimes
        },
        petshopId
      )
      toast.success('Agendamento criado com sucesso!')

      const scheduledServiceId = response.data?.scheduledClientService?.[0]?.id

      navigate('/detalhes-agendamento', {
        state: {
          scheduleInfo,
          scheduleTimes,
          bundlePrice,
          selectedClientPet: location.state.selectedClientPet,
          service: schedulingData.service,
          scheduleWorkers: scheduleWorkers(),
          scheduledServiceId
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getWorkerName = schedule => {
    if (isWorker(auth)) {
      return schedule?.workerId === null
        ? responsiblePersonRestrictedInfo?.name
        : workersRestrictedInfo?.find(
            worker => worker?.id === parseInt(schedule?.workerId)
          )?.name
    } else {
      return schedule.workerId === null
        ? responsiblePerson?.name
        : petshopInfo.workers.find(
            worker => worker.id === parseInt(schedule.workerId)
          )?.name
    }
  }

  const renderServiceWorker = () => {
    return schedules?.map((schedule, index) => {
      const workerName = getWorkerName(schedule)
      return (
        <span key={index}>
          {schedules.length === 1
            ? `${workerName}`
            : `${index + 1}° Serviço - ${workerName}`}
        </span>
      )
    })
  }

  const scheduleWorkers = () => {
    return schedules?.map(schedule => getWorkerName(schedule))
  }

  const [showCancelIfNotPayedPopup, setShowCancelIfNotPayedPopup] = useState(
    false
  )

  const checkIfShouldShowCancelIfNotPayedPopup = () => {
    const payThroughApp = watch('payThroughApp')
    return payThroughApp
  }

  const renderCancelIfNotPayedPopup = () => {
    return (
      <ConfirmationPopup
        closeFunction={() => setShowCancelIfNotPayedPopup(false)}
        defaultButtons={false}
      >
        <div className='change-popup-title'>
          <p className='exit-popup-title'>
            Cancelamento automático permitido somente para pagamentos pelo App!
          </p>
          <span style={{ textAlign: 'center' }}>
            Essa opção de cancelamento automático só é permitida para{' '}
            <strong>pagamentos realizados pelo App</strong>!
            <br />
            <br />
            Ative antes de prosseguir!
            <br />
            <br />
            Se ainda não estiver com o pagamento pelo App configurado, entre em
            contato com nossa equipe de suporte!
          </span>
        </div>
        <div className='change-page-popup-buttons exit-popup-buttons'>
          <Button
            classes='primary-button btn-sm green-cancel-button'
            text='Continuar'
            onClick={() => setShowCancelIfNotPayedPopup(false)}
          />
        </div>
      </ConfirmationPopup>
    )
  }

  const renderScheduleForm = () => (
    <form
      className='scheduling-content'
      onSubmit={handleSubmit(handleCreateScheduling)}
    >
      <Card classes='scheduling-card'>
        <h3 className='scheduling-card-title'>Serviços</h3>
        <div className='scheduling-card-content'>
          {/* {renderServices()} */}
          <p className='scheduling-card-text'>{schedulingData.service.label}</p>
        </div>
      </Card>
      <Card classes='scheduling-card'>
        <h3 className='scheduling-card-title'>Pet/Cliente</h3>
        <div className='scheduling-card-content'>
          <p className='scheduling-card-text'>{schedulingData?.clientPet}</p>
        </div>
      </Card>
      <div className='scheduling-input-container scheduling-select-bundle'>
        <label className='scheduling-input-label'>Pacote</label>
        <Controller
          name='bundle'
          control={control}
          render={({ field }) => (
            <CustomSelect
              field={field}
              options={bundleOptions}
              placeholder='Selecione número de Serviços'
              defaultValue={{
                label: 'Nenhum - Serviço Unitário',
                value: 1
              }}
            />
          )}
        />
        <span
          className={
            errors?.bundle?.label?.message ? 'scheduling-field-error' : ''
          }
        >
          {errors?.bundle?.label?.message}
        </span>
      </div>
      <div className='scheduling-input-container scheduling-select-employee'>
        <label className='scheduling-input-label'>Banhista/tosador</label>
        <p className='scheduling-details-text-content'>
          {renderServiceWorker()}
        </p>
      </div>
      <div className='scheduling-input-container'>
        <label
          htmlFor='attendance-description'
          className='scheduling-input-label'
        >
          Observações do atendimento <span>(aparecerá para o cliente)</span>
        </label>
        <textarea id='attendance-description' {...register('notes')} rows='4' />
      </div>
      <div className='scheduling-services-appointment-time-container'>
        <label className='scheduling-input-label'>
          Horário{' '}
          {bundleValue?.serviceAmount > 1 && (
            <span>(deixe em branco para o cliente selecionar)</span>
          )}
        </label>
        {renderPackageServiceSchedule()}
        {schedules?.length < bundleValue?.serviceAmount &&
          renderNewServiceSchedule()}
        <span
          className={
            errors?.scheduleTimes?.message ? 'scheduling-field-error' : ''
          }
        >
          {errors?.scheduleTimes?.message}
        </span>
      </div>
      <div className='scheduling-input-container'>
        <label className='scheduling-input-label'>Leva e Traz</label>
        <BooleanCheckbox
          id='pick-and-take-custom-checkbox'
          className='scheduling-checkbox'
          firstOption='Não'
          secondOption={`Sim ${
            location.state?.selectedClientPet?.client?.address?.[0]
              ?.deliveryValue
              ? ' - '
              : ''
          } ${normalizePrice(
            location.state.selectedClientPet.client?.address?.[0]?.deliveryValue
          )}`}
          setValue={setValue}
          field='pickUpAndTake'
        />
      </div>
      <div className='scheduling-input-container'>
        <label className='scheduling-input-label'>
          Pagamento pelo App{' '}
          <span>
            <u
              className='text-clickable'
              onClick={() => setShowFeesPopup(true)}
            >
              (ver taxas)
            </u>
          </span>
        </label>
        <BooleanCheckbox
          id='app-payment-custom-checkbox'
          className='scheduling-checkbox'
          firstOption='Não'
          secondOption='Sim'
          setValue={setValue}
          field='payThroughApp'
        />
      </div>
      <div className='scheduling-input-container'>
        <label className='scheduling-input-label'>
          Cancelar em caso de não pagamento{' '}
          <span>
            (se o pagamento não for realizado nos próximos 3 dias ou 24 horas
            antes do atendimento, o horário será liberado)
          </span>
        </label>
        <BooleanCheckbox
          id='cancel-if-no-payment-custom-checkbox'
          className='scheduling-checkbox'
          firstOption='Não'
          secondOption='Sim'
          secondOptionAction={
            !checkIfShouldShowCancelIfNotPayedPopup() &&
            (() => setShowCancelIfNotPayedPopup(true))
          }
          setValue={setValue}
          field='cancelIfNotPayed'
        />
      </div>
      <div className='amount'>
        <p>Valor total: </p>
        <p>{normalizePrice(bundlePrice)}</p>
      </div>
      <Button
        text='Realizar Agendamento'
        classes='primary-button'
        isLoading={isLoading}
        {...(payThroughApp &&
          !clientHasCpf && {
            type: 'button',
            onClick: () => setShowAddCpfPopup(true)
          })}
      />
    </form>
  )

  const renderSkeleton = () => {
    const labelSkeleton = (
      <SkeletonText
        height='17px'
        width='100px'
        rx='8'
        ry='8'
        style={{ borderRadius: '8px' }}
      />
    )
    const textSkeleton = (
      <SkeletonText
        height='15px'
        width='128px'
        rx='8'
        ry='8'
        style={{ borderRadius: '8px', margin: '4px 0 0 min(4vh, 18px)' }}
      />
    )

    const radioOptionSkeleton = () => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '11px 50px',
          columnGap: '6px',
          marginLeft: '16px'
        }}
      >
        <SkeletonButton height='11px' style={{ borderRadius: '50%' }} />
        <SkeletonText
          height='13px'
          width='32px'
          rx='8'
          ry='8'
          style={{ borderRadius: '8px' }}
        />
      </div>
    )

    const radioSkeleton = () => (
      <div>
        {labelSkeleton}
        <div style={{ display: 'flex', rowGap: '6px' }}>
          {renderComponentNTimes(2, radioOptionSkeleton)}
        </div>
      </div>
    )

    return (
      <div className='scheduling-content'>
        {renderComponentNTimes(2, SkeletonButton, {
          height: '72px',
          style: {
            borderRadius: '10px'
          }
        })}
        <div>
          {labelSkeleton}
          <SkeletonButton
            height='38px'
            style={{ borderRadius: '20px', marginTop: '8px' }}
          />
        </div>
        <div>
          {labelSkeleton}
          {textSkeleton}
        </div>
        <div>
          {labelSkeleton}
          <SkeletonButton
            height='86px'
            style={{ borderRadius: '20px', marginTop: '8px' }}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          {labelSkeleton}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 56px' }}>
            {textSkeleton}
            <SkeletonButton height='23px' style={{ borderRadius: '8px' }} />
          </div>
        </div>
        {renderComponentNTimes(3, radioSkeleton)}
        <SkeletonButton
          height='47px'
          style={{ borderRadius: '25px', marginTop: '12px' }}
        />
      </div>
    )
  }

  return (
    <div className='scheduling-page-container'>
      <div className='scheduling-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Agendamento'
          buttonText='Voltar'
          navigateTo='consultar-horarios'
          navigateOptions={{
            state: { ...location.state, bundle: bundleOptions }
          }}
          confirmToBack={isDirty}
        />
        {isPageLoading ? renderSkeleton() : renderScheduleForm()}
      </div>
      {showTimeShiftPopup && (
        <FreeSchedulesPopup
          closeFunction={() => setShowTimeShiftPopup(false)}
          resetField={resetField}
          setValue={setValue}
          selectedClientPet={location.state.selectedClientPet}
          selectedSchedule={schedules[showTimeShiftPopup.serviceNumber]}
          field={`scheduleTimes[${showTimeShiftPopup.serviceNumber}]`}
          params={params}
          handleSubmit={true}
          schedules={schedules}
          setIsSchedulesUpdated={() => setIsSchedulesUpdated(true)}
        />
      )}
      {showFeesPopup && (
        <FeesPopup closeFunction={() => setShowFeesPopup(false)} />
      )}
      {showAddCpfPopup && (
        <AddClientCpfPopup
          authToken={authToken}
          clientId={location.state?.selectedClientPet?.client.id}
          closeFunction={() => setShowAddCpfPopup(false)}
          setClientHasCpf={setClientHasCpf}
        />
      )}
      {showCancelIfNotPayedPopup && renderCancelIfNotPayedPopup()}
    </div>
  )
}
