import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import SelectEmployeePage from './containers/PetShopSchedule/SelectEmployeePage'
import ForgotPasswordPage from './containers/PetshopAccess/ForgotPasswordPage'
import ChangePasswordPage from './containers/PetshopAccess/ChangePasswordPage'
import InitialPage from './containers/InitialPage'
import LoginPage from './containers/PetshopAccess/LoginPage'
import MenuPage from './containers/MenuPage'
import NextAppointmentsPage from './containers/NextAppointmentsPage'
import SignUpPage from './containers/PetshopAccess/SignUpPage'
import ConfigureSchedulesPage from './containers/PetShopSchedule/ConfigureSchedulesPage'
import UnavailabilityPage from './containers/PetShopSchedule/UnavailabilityPage'
import MyServicesPage from './containers/PetshopServices/MyServicesPage'
import AddServicePage from './containers/PetshopServices/AddServicePage'
import EditServicePage from './containers/PetshopServices/EditServicePage'
import MyPackagePage from './containers/PetshopServices/ServicePackage/MyPackagePage'
import AddPackagePage from './containers/PetshopServices/ServicePackage/AddPackagePage'
import EditPackagePage from './containers/PetshopServices/ServicePackage/EditPackagePage'
import MyClientsPage from './containers/PetshopClient/MyClientsPage'
import AddClientPage from './containers/PetshopClient/AddClientPage'
import EditClientPage from './containers/PetshopClient/EditClientPage'
import ClientServicesPage from './containers/PetshopClient/ClientServicesPage'
import ConsultFreeSchedule from './containers/Agenda/ConsultFreeSchedule'
import Scheduling from './containers/Agenda/Scheduling'
import SchedulingDetails from './containers/Agenda/SchedulingDetails'
import AppointmentStepsPage from './containers/AppointmentStepsPage'
import GeneralSettingsPage from './containers/GeneralSettingsPage'
import MyPlanPage from './containers/MyPlanPage'
import PlansPage from './containers/PlansPage'
import PaymentePage from './containers/PaymentPage'
import MyAccountPage from './containers/MyAccountPage'
import WorkersPage from './containers/PetshopWorkers/WorkersPage'
import WorkerAddEdit from './containers/PetshopWorkers/WorkerAddEdit'
import OwnerAddEdit from './containers/PetshopOwner/OwnerAddEdit'
import PetshopCompanyInformations from './containers/PetshopCompanyInformations'
import ConditionTermsPage from './containers/AppTerms/ConditionTermsPage'
import PrivacyTermsPage from './containers/AppTerms/PrivacyTermsPage'

import RequireAuth from './components/RequireAuth'
import NoAuthOnly from './components/NoAuthOnly'
import ScrollToTop from './components/ScrollToTop'

export default function Router (props) {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route>
          <Route path='/planos' element={<PlansPage />} />
          <Route path='/termos/condicoes' element={<ConditionTermsPage />} />
          <Route path='/termos/privacidade' element={<PrivacyTermsPage />} />
        </Route>
        <Route element={<NoAuthOnly />}>
          <Route path='/' element={<InitialPage />} />
          <Route path='/entrar' element={<LoginPage />} />
          <Route path='/recuperar-senha' element={<ForgotPasswordPage />} />
          <Route path='/criar-senha' element={<ChangePasswordPage />} />
          <Route path='/cadastro' element={<SignUpPage />} />
          <Route path='*' element={<InitialPage />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path='/menu' element={<MenuPage />} />
          <Route
            path='/proximos-atendimentos'
            element={<NextAppointmentsPage />}
          />
          <Route
            path='/proximos-atendimentos/:appointmentId'
            element={<AppointmentStepsPage />}
          />
          <Route
            path='/horarios-atendimento'
            element={<SelectEmployeePage />}
          />
          <Route
            path='/horarios-atendimento/:workerId'
            element={<ConfigureSchedulesPage />}
          />
          <Route
            path='/horarios-atendimento/:workerId/indisponibilidade'
            element={<UnavailabilityPage />}
          />
          <Route path='/meus-servicos' element={<MyServicesPage />} />
          <Route
            path='/meus-servicos/novo-servico'
            element={<AddServicePage />}
          />
          <Route
            path='/meus-servicos/editar-servico/:serviceId'
            element={<EditServicePage />}
          />
          <Route
            path='/meus-servicos/meus-pacotes'
            element={<MyPackagePage />}
          />
          <Route
            path='/meus-servicos/meus-pacotes/novo-pacote'
            element={<AddPackagePage />}
          />
          <Route
            path='/meus-servicos/meus-pacotes/editar-pacote/:packageId'
            element={<EditPackagePage />}
          />
          <Route path='/meus-clientes' element={<MyClientsPage />} />
          <Route
            path='/meus-clientes/novo-cliente'
            element={<AddClientPage />}
          />
          <Route
            path='/meus-clientes/editar-cliente/:clientId'
            element={<EditClientPage />}
          />
          <Route
            path='/meus-clientes/editar-cliente/:clientId/servicos'
            element={<ClientServicesPage />}
          />
          <Route path='/consultar-horarios' element={<ConsultFreeSchedule />} />
          <Route path='/agendamento' element={<Scheduling />} />
          <Route path='/detalhes-agendamento' element={<SchedulingDetails />} />
          <Route path='/configuracoes' element={<GeneralSettingsPage />} />
          <Route path='/meu-plano' element={<MyPlanPage />} />
          <Route path='/planos' element={<PlansPage />} />
          <Route path='/pagamento' element={<PaymentePage />} />
          <Route path='/minha-conta' element={<MyAccountPage />} />
          <Route path='/meus-colaboradores' element={<WorkersPage />} />
          <Route
            path='/meus-colaboradores/adicionar'
            element={<WorkerAddEdit />}
          />
          <Route
            path='/meus-colaboradores/editar/:workerId'
            element={<WorkerAddEdit />}
          />
          <Route path='/dados-responsavel' element={<OwnerAddEdit />} />
          <Route
            path='/dados-empresa'
            element={<PetshopCompanyInformations />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
