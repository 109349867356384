export const createGoogleMapsLink = (originAdress, destAddress) => {
  //TODO: get petshop address
  const fakeOrigin =
    'Av.+Prof.+Moraes+Rego,+1235+-+Cidade+Universitária,+Recife+-+PE,+50670-901'
  const destination = `${destAddress?.street?.replace(' ', '+')},+${
    destAddress?.streetNumber
  }+-+${destAddress?.district?.replace(' ', '+')},+${destAddress?.city?.replace(
    ' ',
    '+'
  )}+-+${destAddress?.uf?.replace(' ', '+')},+${destAddress?.cep}`
  const url = `https://www.google.com/maps/dir/?api=1&origin=${fakeOrigin}&destination=${destination}`
  return url
}
