import React, { useState, useCallback } from 'react'

import './style.css'
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg'
import { ReactComponent as UnCheckIcon } from '../../../assets/images/icons/unCheck.svg'
import Popup from '../../Popup'
import Button from '../../Button'
import { add, getDay } from 'date-fns'

const week = {
  monday: {
    copy: false,
    title: 'Segunda'
  },
  tuesday: {
    copy: false,
    title: 'Terça'
  },
  wednesday: {
    copy: false,
    title: 'Quarta'
  },
  thursday: {
    copy: false,
    title: 'Quinta'
  },
  friday: {
    copy: false,
    title: 'Sexta'
  },
  saturday: {
    copy: false,
    title: 'Sábado'
  },
  sunday: {
    copy: false,
    title: 'Domingo'
  }
}

export default function CopyPopup ({
  title,
  close,
  addDaySchedule,
  daySchedule,
  append,
  date,
  getValues,
  ...props
}) {
  // stores the days of the week and indicates in which the received schedule will be pasted
  // Object.entries was used to be able to use the map function, since it is not possible to map with objects. So we'll have something like this [['monday', {copy:false, title:'Monday'}], ...]
  const [days, setDays] = useState(Object.entries(week))

  const renderCheckUncheckIcon = value => {
    return value ? <CheckIcon /> : <UnCheckIcon />
  }

  // function that has a day as a parameter and changes the value of the copy property of that day
  const handleChangeCheckbox = useCallback(day => {
    setDays(prevDays => {
      const newDays = [...prevDays]
      const index = newDays.indexOf(day)
      newDays[index] = [day[0], { copy: !day[1].copy, title: day[1].title }]
      return newDays
    })
  }, [])

  // Go through the array of days and check which days the schedule should be pasted into
  const copySchedule = () => {
    const newDate = date ? new Date(date) : undefined
    //// converts to 0-6, where Monday is 0
    const dayNumber = date ? (getDay(newDate) + 6) % 7 : undefined

    days.forEach((day, index) => {
      if (day[1].copy) {
        if (date && index !== dayNumber) {
          const newDay = add(newDate, { days: index - dayNumber })
          daySchedule.forEach(schedule => {
            append({ ...schedule, day: newDay.toISOString() })
          })
        } else {
          // remove id from copied schedules
          const copySchedule = daySchedule.map(schedule => {
            const { periodStart, periodEnd } = schedule
            return { periodStart, periodEnd }
          })
          // get current schedules to avoid overwriting
          const currentDaySchedules = getValues()[`${day[0]}`] || []
          const schedules = [...currentDaySchedules, ...copySchedule]
          addDaySchedule(`${day[0]}`, schedules, { shouldDirty: true })
        }
      }
    })
  }

  return (
    <Popup
      className='popup-inline'
      hasCloseButton={false}
      closeFunction={close}
    >
      <h6 className='copy-popup-title'>Copiar Horários para: </h6>
      <div className='copy-days'>
        {/* day variable format is this ['day_of_the_week', {copy:false/true, title:'day_of_the_week_in_portuguese'}] */}
        {days.map(day => (
          <div
            className='day-check'
            key={day[0]}
            onClick={() => handleChangeCheckbox(day)}
          >
            <span>{renderCheckUncheckIcon(day[1].copy)}</span>
            <p>{day[1].title}</p>
          </div>
        ))}
        <Button
          type='button'
          classes='action-button copy-popup-button'
          text='Copiar'
          onClick={() => {
            copySchedule()
            close()
          }}
        />
      </div>
    </Popup>
  )
}
