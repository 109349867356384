import React from 'react'
import CookieConsent from 'react-cookie-consent'

import './style.css'

export default function Cookies ({ ...props }) {
  return (
    <CookieConsent
      buttonText='Concordo e Aceito'
      disableStyles={true}
      buttonClasses='action-button-secondary button'
      containerClasses='cookies-consent-content'
      contentClasses='cookies-message'
    >
      Este site usa cookies e dados pessoais de acordo com nossa{' '}
      <a href='/termos/privacidade'>Política de Privacidade</a>.
    </CookieConsent>
  )
}
