import React, { forwardRef, useEffect, useState } from 'react'

import './style.css'
import Button from '../../../../../components/Button'
import Card from '../../../../../components/Card'
import { petsToString } from '../../../../../services/utils/helpers/petsToString'
import { addressToString } from '../../../../../services/utils/helpers/addressToString'
import { formatValue } from '../../../../../services/utils/helpers/formatValue'
import { normalizePhoneNumber } from '../../../../../services/utils/masks'

const ClientPetCard = forwardRef(
  (
    {
      client,
      resetCards,
      setCardIdentifier,
      cardIdentifier,
      changeClientPetSelected,
      ...props
    },
    ref
  ) => {
    const [contentState, setContentState] = useState('client-info')

    //put the card in the initial state('client-info')
    useEffect(() => {
      if (resetCards) setContentState('client-info')
    }, [resetCards])

    //changes the state of the card when the parent indicates that the cardIdentifier is the same as the client's email used to build the card
    useEffect(() => {
      if (cardIdentifier === client.email) setContentState('client-pets')
      else setContentState('client-info')
    }, [cardIdentifier, client])

    useEffect(() => {
      if (contentState === 'client-pets') {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [contentState, ref])

    const [selectedPet, setSelectedPet] = useState('')

    const selectClientPet = pet => {
      changeClientPetSelected({ client, pet })
    }

    const renderPets = pets => {
      return pets.map((pet, index) => {
        return (
          <SelectPet
            pet={pet}
            key={index}
            selectedPet={selectedPet}
            unselect={selectedPet && selectedPet === pet.name}
            changeSelectedPet={petId => setSelectedPet(petId)}
            selectClientPet={selectClientPet}
          />
        )
      })
    }

    //change the value of the card identifier present in the parent component
    const changeContentState = () => {
      setCardIdentifier(client.email)
      changeClientPetSelected()
      setSelectedPet()
    }

    return (
      <Card
        classes={
          (contentState === 'client-pets' ? 'card-selected ' : '') +
          'client-pet-card'
        }
        ref={ref}
      >
        <div className='client-pet-card-header'>
          <h3 className='client-pet-card-title'>{client.name}</h3>
          {contentState === 'client-info' && (
            <Button
              text='Selecionar Cliente'
              classes='action-button-tertiary client-pet-select-button'
              onClick={() => changeContentState()}
            />
          )}
        </div>
        <div className='client-pet-card-content'>
          {contentState === 'client-info' ? (
            <>
              <p className='client-pet-info'>
                <strong>Telefone:</strong>{' '}
                {normalizePhoneNumber(client.phoneNumber)}
              </p>
              <p className='client-pet-info'>
                <strong>Pet(s):</strong> {petsToString(client.pets)}
              </p>
              <p className='client-pet-info'>
                <strong>Endereço:</strong>{' '}
                {client?.address?.[0]
                  ? addressToString(client.address[0])
                  : 'Sem endereço cadastrado.'}
              </p>
              <p className='client-pet-info'>
                <strong>E-mail:</strong> {client.email}
              </p>
              <p className='client-pet-info'>
                <strong>Leva e traz: </strong>
                {client?.address ? (
                  <>
                    {formatValue(client?.address?.[0]?.deliveryValue)} -{' '}
                    {client?.address?.[0]?.deliveryTime} minutos
                  </>
                ) : (
                  'Leva e traz não cadastrado.'
                )}
              </p>
            </>
          ) : (
            <div className='client-pet-pets'>{renderPets(client.pets)}</div>
          )}
        </div>
      </Card>
    )
  }
)

export default ClientPetCard

function SelectPet ({
  pet,
  selectedPet,
  unselect,
  changeSelectedPet,
  selectClientPet,
  ...props
}) {
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    if (unselect) setIsSelected(false)
  }, [unselect])

  useEffect(() => {
    if (selectedPet === pet.id) setIsSelected(true)
    else setIsSelected(false)
  }, [selectedPet, pet])

  return (
    <div className='client-pet'>
      <p
        className={
          (selectedPet && selectedPet !== pet.id
            ? 'client-pet-unselected '
            : '') + 'client-pet-info'
        }
      >
        <strong>Pet: </strong>
        {petsToString([pet])}
      </p>
      <Button
        classes={
          (isSelected ? 'action-button' : 'action-button-tertiary') +
          ' client-pet-select-button'
        }
        text={isSelected ? 'Selecionado' : 'Selecionar Pet'}
        onClick={() => {
          changeSelectedPet(pet.id)
          selectClientPet(pet)
        }}
      />
    </div>
  )
}
