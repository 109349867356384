import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { differenceInDays, isSameDay } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'

import {
  logOut,
  selectCurrentToken,
  selectCurrentAuth
} from '../../features/auth/authSlice'
import {
  removePetshop,
  selectPetshopMenuState,
  selectPetshopInfo,
  selectPetshopResponsiblePerson,
  setMenuState
} from '../../features/petshop/petshopSlice'
import {
  selectWorkerInfo,
  removeWorker
} from '../../features/worker/workerSlice'
import {
  getPetshopPlan,
  workerGetPetshopPlan
} from '../../services/api/apiCalls'
import {
  removeCurrentPlan,
  setCurrentPlan,
  selectCurrentPlan
} from '../../features/plan/planSlice'
import SkeletonCard from '../../skeletonComponents/Card'
import { renderComponentNTimes } from '../../services/utils/helpers/renderComponentNTimes'
import { isPetshop, isWorker } from '../../services/utils/helpers/authHelper'
import { openWhatsappSupport } from '../../services/utils/helpers/createWhatsappLink'

import './style.css'
import { ReactComponent as SetEventIcon } from '../../assets/images/icons/setEvent.svg'
import { ReactComponent as GeneralServicesIcon } from '../../assets/images/icons/generalServices.svg'
import { ReactComponent as AgendaIcon } from '../../assets/images/icons/agenda.svg'
import { ReactComponent as ClientIcon } from '../../assets/images/icons/client.svg'
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg'
import { ReactComponent as ReceiptIcon } from '../../assets/images/icons/receipt.svg'
import { ReactComponent as PlanIcon } from '../../assets/images/icons/plan.svg'
import { ReactComponent as AccountIcon } from '../../assets/images/icons/account.svg'
import { ReactComponent as SupportIcon } from '../../assets/images/icons/support.svg'
import { ReactComponent as ExitIcon } from '../../assets/images/icons/exit.svg'
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg'
import Button from '../../components/Button'
import Card from '../../components/Card'
import InitialPopup from './InitialPopup'
import MenuPagePopup from './MenuPagePopup'
import MenuHeader from '../../components/MenuHeader'
import SkeletonButton from '../../skeletonComponents/Button'
import ConfirmationPopup from '../../components/ConfirmationPopup'

export default function MenuPage (props) {
  const [showInvoicePopup, setShowInvoicePopup] = useState(false)

  const defaultAvailableFeatures = [
    {
      id: 0,
      description: 'Configurar Horários de Atendimento',
      icon: () => <SetEventIcon />,
      navigateTo: '/horarios-atendimento',
      available: true,
      nextStep: 'Finalize a configuração de Horários para desbloquear',
      state: 'initial',
      isWorkerView: false
    },
    {
      id: 1,
      description: 'Meus Serviços',
      icon: () => <GeneralServicesIcon />,
      navigateTo: '/meus-servicos',
      available: false,
      nextStep: 'Adicione um serviço para desbloquear',
      state: 'services',
      isWorkerView: true
    },
    {
      id: 2,
      description: 'Fazer Agendamento',
      icon: () => <AgendaIcon />,
      navigateTo: '/consultar-horarios',
      available: false,
      nextStep: 'Consulte um horário livre na agenda para desbloquear',
      state: 'agenda',
      isWorkerView: true
    },
    {
      id: 3,
      description: 'Clientes',
      icon: () => <ClientIcon />,
      navigateTo: '/meus-clientes',
      available: false,
      nextStep: 'Acesse seus clientes para desbloquear',
      state: 'clients',
      isWorkerView: true
    },
    {
      id: 4,
      description: 'Configurações Gerais',
      icon: () => <SettingsIcon />,
      navigateTo: '/configuracoes',
      available: false,
      state: 'complete',
      isWorkerView: false
    },
    {
      id: 5,
      description: 'Notas Fiscais (indisponível - clique e saiba mais)',
      icon: () => <ReceiptIcon />,
      clickableAction: () => setShowInvoicePopup(true),
      navigateTo: '/',
      available: false,
      state: 'complete',
      isWorkerView: false
    },
    {
      id: 6,
      description: 'Meu Plano',
      icon: () => <PlanIcon />,
      navigateTo: '/meu-plano',
      available: false,
      state: 'complete',
      isWorkerView: false
    },
    {
      id: 7,
      description: 'Minha Conta',
      icon: () => <AccountIcon />,
      navigateTo: '/minha-conta',
      available: false,
      state: 'complete',
      isWorkerView: true
    }
  ]

  const authToken = useSelector(selectCurrentToken)

  const currentPlan = useSelector(selectCurrentPlan)

  const workerInfo = useSelector(selectWorkerInfo)

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const petshopInfo = useSelector(selectPetshopInfo)
  const responsiblePerson = useSelector(selectPetshopResponsiblePerson)
  const auth = useSelector(selectCurrentAuth)

  const [popup, setPopup] = useState()
  const [availableFeatures, setAvailableFeatures] = useState([
    ...defaultAvailableFeatures
  ])
  const [nextStep, setNextStep] = useState()
  const [featuresLocked, setFeaturesLocked] = useState()
  const [trialPeriod, setTrialPeriod] = useState()

  const [isPageLoading, setIsPageLoading] = useState(false)

  const [showExitPopup, setShowExitPopup] = useState(false)
  const [showSupportPopup, setShowSupportPopup] = useState(false)

  useEffect(() => {
    if (currentPlan) {
      if (currentPlan.planPeriodIdentifier !== 1) {
        dispatch(setMenuState({ menuState: { state: 'complete', index: 4 } }))
        return
      }

      const validUntilDay = new Date(
        currentPlan.validUntilDay.replace('-', '/')
      )
      const today = new Date()
      if (isSameDay(validUntilDay, today)) {
        setTrialPeriod(0)
      } else if (today < validUntilDay) {
        setTrialPeriod(parseInt(differenceInDays(validUntilDay, today)))
      } else {
        setTrialPeriod(-1)
      }
    }
  }, [currentPlan, dispatch])

  const menuState = useSelector(selectPetshopMenuState)

  useEffect(() => {
    setAvailableFeatures(curr => {
      if (!menuState) return curr

      return curr.map(menuOption => {
        return {
          ...menuOption,
          available:
            (menuOption.id <= menuState?.index ||
              menuState.state === 'complete') &&
            (isWorker(auth) ? menuOption.isWorkerView : true)
        }
      })
    })
  }, [menuState, auth])

  useEffect(() => {
    const featuresLockedNumber = availableFeatures.filter(obj => !obj.available)
      .length
    setFeaturesLocked(featuresLockedNumber)

    const state = menuState ? menuState.state : 'initial'

    availableFeatures
      .filter(obj => obj.available)
      .forEach(card => {
        if (card.state === state) setNextStep(card.nextStep)
      })
    //setAvailableFeatures will be used here
  }, [availableFeatures, menuState])

  function renderAvailableFeatures () {
    return availableFeatures
      .filter(obj => obj.available)
      .map(card => (
        <Card
          onClick={() =>
            card.clickableAction
              ? card.clickableAction()
              : navigate(card.navigateTo)
          }
          key={card.id}
        >
          <div className='card-icon'>{card.icon()}</div>
          <p className='card-description'>{card.description}</p>
        </Card>
      ))
  }

  useEffect(() => {
    async function getPlan () {
      setIsPageLoading(true)
      try {
        let response
        if (isPetshop(auth)) {
          response = await getPetshopPlan(authToken)
        } else {
          // For now the worker will have only one petshop
          response = await workerGetPetshopPlan({
            authToken,
            petshopId: workerInfo?.petshops[0]?.id
          })
        }
        dispatch(setCurrentPlan({ currentPlan: response.data }))
      } catch (error) {
        console.error(error)
      } finally {
        setIsPageLoading(false)
      }
    }

    // logic to fetch from the backend only when the page is refreshed
    const isPageRefreshed = localStorage.getItem('isPageRefreshed')
    if (isPageRefreshed === '1') {
      getPlan()
      localStorage.setItem('isPageRefreshed', '0')
    }
  }, [authToken, dispatch, auth, workerInfo?.petshops])

  const daysRemainingMessage = () => {
    if (trialPeriod > 0) {
      return (
        <>
          Você tem mais {trialPeriod} {trialPeriod > 1 ? 'dias' : 'dia'} de
          teste.
        </>
      )
    } else if (trialPeriod === 0) {
      return (
        <>
          <strong>Último</strong> dia de teste! Assine um plano para não perder
          o acesso ao aplicativo.
        </>
      )
    } else if (trialPeriod === -1) {
      return (
        <>
          Teste <strong>expirado</strong>, assine para continuar usando o
          aplicativo.
        </>
      )
    }
  }

  const renderDaysRemainingCard = () => {
    return (
      <Card classes='days-remaining-card'>
        <p className='days-remaining-card-text'>{daysRemainingMessage()}</p>
        <Button
          text='Clique aqui e veja nossos Planos!'
          classes='action-button-tertiary'
          onClick={() => navigate('/planos')}
        />
      </Card>
    )
  }

  const renderMenuMessages = () => {
    return (
      <>
        {trialPeriod !== undefined && renderDaysRemainingCard()}
        {featuresLocked !== 0 && (
          <p>
            Você tem mais{' '}
            <strong>{featuresLocked} funcionalidades ocultas</strong>. Para
            visualizá-las, <strong>continue cadastrando</strong> as informações
            no aplicativo.{' '}
          </p>
        )}
      </>
    )
  }

  const renderInvoicePopup = () => {
    return (
      <ConfirmationPopup
        closeFunction={() => setShowInvoicePopup(false)}
        defaultButtons={false}
      >
        <div className='menu-change-popup-title'>
          <p className='exit-popup-title'>
            Tem interesse em emitir notas fiscais?
          </p>
          <span>
            No momento <b>não</b> possuímos o serviço de{' '}
            <b>emissão de notas fiscais</b> pois ainda não tivemos uma{' '}
            <b>quantidade mínima de interessados</b>.
          </span>
          <span>
            Se tiver interesse em emitir notas fiscais pelo app, clique no botão
            abaixo para conversar com nossa equipe!
          </span>
        </div>
        <div className='change-page-popup-buttons exit-popup-buttons'>
          <Button
            classes='alert-button btn-with-icon'
            text='Cancelar'
            onClick={() => setShowInvoicePopup(false)}
          />
          <Button
            classes='primary-button btn-sm green-cancel-button'
            text='Tenho interesse em Notas Fiscais'
            onClick={() => openWhatsappSupport()}
          />
        </div>
      </ConfirmationPopup>
    )
  }

  const renderSupportPopup = () => {
    return (
      <ConfirmationPopup
        closeFunction={() => setShowSupportPopup(false)}
        defaultButtons={false}
      >
        <div className='menu-change-popup-title'>
          <p className='exit-popup-title'>Com dúvidas ou problemas técnicos?</p>
          <span>
            Clique no botão abaixo para contatar o suporte via Whatsapp para
            questões técnicas (erros do aplicativo) e outras dúvidas.
          </span>
          <span>
            Nosso horário de atendimento é das <strong>09h às 12h</strong> e das{' '}
            <strong>14h às 18h</strong>.
          </span>
        </div>
        <div className='change-page-popup-buttons exit-popup-buttons'>
          <Button
            classes='alert-button btn-with-icon'
            text='Cancelar'
            onClick={() => setShowSupportPopup(false)}
          />
          <Button
            classes='primary-button btn-sm green-cancel-button'
            text='Contatar suporte'
            onClick={() => openWhatsappSupport()}
          />
        </div>
      </ConfirmationPopup>
    )
  }

  const renderSkeleton = () => {
    return (
      <div className='menu-content'>
        <div className='menu-options'>
          {renderComponentNTimes(6, SkeletonCard, {
            style: { aspectRatio: 1.2, borderRadius: '10px' }
          })}
        </div>
        <div className='buttons-container'>
          <SkeletonButton height={'50px'} style={{ borderRadius: '10px' }} />
          <SkeletonButton
            height={'50px'}
            style={{ borderRadius: '10px' }}
            backgroundColor='#dbdbdb'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='menu-page-container'>
      <div className='menu-page-inner-container'>
        {!isPageLoading &&
        petshopInfo &&
        !responsiblePerson &&
        isPetshop(auth) ? (
          <MenuPagePopup onSent={() => setPopup('initial')} />
        ) : (
          popup === 'initial' && <InitialPopup close={() => setPopup('')} />
        )}
        <MenuHeader
          classes='floating-right'
          title='Menu'
          buttonText='Próximos Atendimentos'
          navigateTo='proximos-atendimentos'
        />
        {!isPageLoading && menuState ? (
          <div className='menu-content'>
            <div className='menu-details'>
              {isPetshop(auth) && renderMenuMessages()}
            </div>
            <div className='menu-options'>
              {renderAvailableFeatures()}
              {nextStep && (
                <Card classes='disabled-card'>
                  <div className='card-icon'>
                    <LockIcon />
                  </div>
                  <p className='card-description'>{nextStep}</p>
                </Card>
              )}
            </div>
            <div className='buttons-container'>
              <Button
                text='Suporte'
                classes='icon-button bg-color-header'
                onClick={() => {
                  setShowSupportPopup(true)
                }}
              >
                <SupportIcon />
              </Button>
              <Button
                text='Sair'
                classes='icon-button bg-color-base-2'
                onClick={() => {
                  setShowExitPopup(true)
                }}
              >
                <ExitIcon />
              </Button>
            </div>
          </div>
        ) : (
          renderSkeleton()
        )}
      </div>
      {showExitPopup && (
        <ConfirmationPopup
          closeFunction={() => setShowExitPopup(false)}
          defaultButtons={false}
        >
          <div className='menu-change-popup-title'>
            <p className='exit-popup-title'>Deseja sair do aplicativo?</p>
            <span>Clique no botão abaixo para sair do aplicativo.</span>
            <span>
              Quando quiser acessar, será necessário realizar o login novamente.
            </span>
          </div>
          <div className='change-page-popup-buttons exit-popup-buttons'>
            <Button
              classes='alert-button btn-with-icon'
              text='Sair'
              onClick={() => {
                dispatch(logOut())
                dispatch(removePetshop())
                dispatch(removeWorker())
                dispatch(removeCurrentPlan())
              }}
            />
            <Button
              classes='primary-button btn-sm green-cancel-button'
              text='Continuar no App'
              onClick={() => setShowExitPopup(false)}
            />
          </div>
        </ConfirmationPopup>
      )}
      {showInvoicePopup && renderInvoicePopup()}
      {showSupportPopup && renderSupportPopup()}
    </div>
  )
}
