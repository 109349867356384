import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'
import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../../../features/auth/authSlice'
import {
  deleteBundle,
  getPetshopBundles
} from '../../../../services/api/apiCalls'
import { toast } from 'react-toastify'

import './style.css'
import ServiceCard from '../../../../components/DropdownCard'
import Button from '../../../../components/Button'
import MenuHeader from '../../../../components/MenuHeader'
import SkeletonButton from '../../../../skeletonComponents/Button'
import SkeletonText from '../../../../skeletonComponents/Text'
import { renderComponentNTimes } from '../../../../services/utils/helpers/renderComponentNTimes'
import { selectWorkerInfo } from '../../../../features/worker/workerSlice'
import { isWorker } from '../../../../services/utils/helpers/authHelper'

export default function MyPackagePage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const navigate = useNavigate()
  const location = useLocation()

  const [packages, setPackages] = useState([])
  const [services, setServices] = useState([])

  const [isPackagesLoading, setIsPackagesLoading] = useState(false)

  useEffect(() => {
    async function getBundle () {
      if (!authToken) return

      if (location.state?.services?.length > 0) {
        setServices(location.state.services)
      }

      if (location.state?.packages?.length > 0) {
        setPackages(location.state.packages)
        return
      }

      try {
        setIsPackagesLoading(true)
        const petshopId = workerInfo?.petshops[0]?.id
        const response = await getPetshopBundles(authToken, petshopId)
        setPackages(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsPackagesLoading(false)
      }
    }

    getBundle()
  }, [authToken, location.state, workerInfo])

  const handleRemovePackage = async id => {
    try {
      await deleteBundle(authToken, id)
      setPackages(curr => curr.filter(bundle => bundle.id !== id))
      toast.success('Pacote removido com sucesso!')
    } catch (error) {
      console.error(error)
    }
  }

  const renderMyPackagesInnerHeader = () => {
    return (
      <>
        <div className='my-packages-inner-header'>
          <h3>Pacotes</h3>
          <Button
            classes='action-button-tertiary'
            text='Serviços'
            onClick={() =>
              navigate('/meus-servicos', { state: { services, packages } })
            }
          />
        </div>
      </>
    )
  }

  const renderPackages = () => {
    return packages.map(pack => {
      return (
        <ServiceCard
          title={pack.serviceAmount + ' Serviços'}
          key={pack.id}
          removeFunction={() => handleRemovePackage(pack.id)}
          editFunction={() =>
            navigate(`editar-pacote/${pack.id}`, { state: { bundle: pack } })
          }
          {...(isWorker(auth) && { readOnly: true })}
        >
          <div className='package-items'>
            <p className='package-item'>
              <strong>Desconto:</strong> {pack.discountPercentage}%
            </p>
            <p className='package-item'>
              <strong>Número máximo de parcelas:</strong> {pack.maxInstallments}
            </p>
          </div>
        </ServiceCard>
      )
    })
  }

  const renderSkeleton = () => {
    return (
      <div className='my-services-content'>
        <SkeletonText
          height={'17px'}
          width={'80%'}
          x={'10%'}
          rx='8'
          ry='8'
          style={{ borderRadius: '8px', marginBottom: 'min(4vh, 24px)' }}
        />
        {renderComponentNTimes(5, SkeletonButton, {
          style: { borderRadius: '10px', marginBottom: 'min(3vh, 24px)' },
          height: '49px'
        })}
        <SkeletonButton
          height={'47px'}
          style={{ marginTop: 24, borderRadius: '25px' }}
        />
      </div>
    )
  }

  return (
    <div className='my-package-page-container'>
      <div className='my-package-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Meus Serviços'
          buttonText='Menu'
          navigateTo='menu'
        />
        <div className='my-package-content'>
          <div className='packages-container'>
            {renderMyPackagesInnerHeader()}
            {isPackagesLoading ? (
              renderSkeleton()
            ) : (
              <>
                {packages.length !== 0 ? (
                  <>
                    <p className='package-page-explanation-message'>
                      Clique em um pacote para expandir e ver os detalhes.
                    </p>
                    {renderPackages()}
                  </>
                ) : (
                  <p className='empty-packages'>
                    Você ainda não tem pacotes. Clique no botão abaixo para
                    adicionar.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {!isPackagesLoading && (
        <Button
          text='Adicionar Pacote'
          classes='primary-button fixed-button'
          onClick={() => navigate('novo-pacote')}
        />
      )}
    </div>
  )
}
