import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import Button from '../../../components/Button'
import MenuHeader from '../../../components/MenuHeader'
import ClientCard from './ClientCard'
import SearchField from '../../../components/SearchField'
import { searchClientsByNameOrPhone } from '../../../services/utils/helpers/searchClientByNameOrPhone'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../../features/auth/authSlice'
import { getPetshopClientList } from '../../../services/api/apiCalls'
import { setMenuState } from '../../../features/petshop/petshopSlice'
import SkeletonButton from '../../../skeletonComponents/Button'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'
import { selectWorkerInfo } from '../../../features/worker/workerSlice'
import { isWorker } from '../../../services/utils/helpers/authHelper'

export default function MyClientsPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const auth = useSelector(selectCurrentAuth)

  const workerInfo = useSelector(selectWorkerInfo)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [clients, setClients] = useState([])
  const [clientsToShow, setClientsToShow] = useState([])
  const [clientsNotFound, setClientsNotFound] = useState(false)

  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    async function getClients () {
      if (!authToken) return
      setIsPageLoading(true)
      try {
        // Temporary. Considering that a worker has only one petshop
        const petshopId = workerInfo?.petshops[0]?.id

        const response = await getPetshopClientList(authToken, petshopId)
        setClients(response.data)
        setClientsToShow(response.data)

        dispatch(setMenuState({ menuState: { state: 'complete', index: 4 } }))
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getClients()
  }, [authToken, dispatch, workerInfo])

  const renderClients = () => {
    const cardReadOnly = isWorker(auth)
    return clientsToShow.map(client => {
      return (
        <ClientCard
          key={client.id}
          client={client}
          {...(cardReadOnly && { readOnly: true })}
        />
      )
    })
  }

  const searchFunction = term => {
    const searchResult = searchClientsByNameOrPhone(clients, term)
    if (searchResult.length !== 0) {
      setClientsToShow(searchResult)
      setClientsNotFound(false)
    } else {
      setClientsNotFound(true)
    }
  }

  const renderSkeleton = () => {
    return (
      <div className='my-clients-content'>
        <div className='clients-container'>
          <SkeletonButton height={'35px'} style={{ borderRadius: '30px' }} />
          {renderComponentNTimes(2, SkeletonButton, {
            height: '197px',
            style: {
              borderRadius: '10px'
            }
          })}
          <SkeletonButton height={'47px'} style={{ borderRadius: '25px' }} />
        </div>
      </div>
    )
  }

  return (
    <div className='my-clients-page-container'>
      <div className='my-clients-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Clientes'
          buttonText='Menu'
          navigateTo='menu'
        />

        {isPageLoading ? (
          renderSkeleton()
        ) : (
          <div className='my-clients-content'>
            <div className='clients-container'>
              {clients.length !== 0 ? (
                <>
                  <div>
                    <SearchField
                      onSearchSubmit={searchFunction}
                      clearResults={() => {
                        setClientsToShow(clients)
                        setClientsNotFound(false)
                      }}
                      placeholder='Buscar Cliente por nome ou telefone'
                    />
                  </div>
                  {!clientsNotFound ? (
                    renderClients()
                  ) : (
                    <p className='client-not-found'>
                      Nenhum cliente correspondente à sua busca foi encontrado.
                    </p>
                  )}
                </>
              ) : (
                <p className='empty-clients'>
                  Você ainda não tem clientes. Clique no botão abaixo para
                  adicionar.
                </p>
              )}
            </div>
            {!isWorker(auth) && (
              <Button
                text='Adicionar Cliente'
                classes='primary-button fixed-button'
                onClick={() => navigate('novo-cliente')}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
