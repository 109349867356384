const stepsState = steps => {
  let currentStep = undefined
  let previousStepId = null
  return steps.map(step => {
    const previousId = previousStepId
    const { completedAt, returnedAt } = step
    if (completedAt) {
      const state =
        returnedAt && returnedAt > completedAt ? 'initial' : 'complete'
      if (currentStep) {
        previousStepId = step.id
        return { step, state: 'upcoming', previousStepId: previousId }
      }
      if (state === 'initial') currentStep = step
      previousStepId = step.id
      return { step, state, previousStepId: previousId }
    }
    const state = currentStep ? 'upcoming' : 'initial'
    currentStep = step
    previousStepId = step.id
    return { step, state, previousStepId: previousId }
  })
}

export { stepsState }
