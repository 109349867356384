import React, { useEffect, useMemo, useState } from 'react'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import './style.css'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { normalizePhoneNumber } from '../../../services/utils/masks'
import WorkerRemoveAccessPopup from './WorkerRemoveAccessPopup'
import {
  createPetshopWorker,
  updatePetshopWorker
} from '../../../services/api/apiCalls'
import { selectCurrentToken } from '../../../features/auth/authSlice'

const validationSchema = object({
  name: string().required('Informe o nome do colaborador.'),
  phoneNumber: string()
    .required('Informe o telefone do colaborador.')
    .min(14, 'Formato incorreto'),
  email: string()
    .email('Formato inválido.')
    .required('Informe o e-mail do colaborador')
})

export default function WorkerForm ({ worker, readOnly = false, ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const navigate = useNavigate()

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return worker
      }, [worker])
    }
  })

  useEffect(() => {
    reset(worker)
  }, [worker, reset])

  const phone = watch('phoneNumber')

  //masking the phone field
  useEffect(() => {
    if (phone) setValue('phoneNumber', normalizePhoneNumber(phone))
  }, [setValue, phone])

  const [isLoading, setIsLoading] = useState(false)

  const handleFormSubmission = async data => {
    if (worker && !isDirty) {
      toast.warning('Faça alguma alteração para poder atualizar o colaborador!')
      return
    }

    setIsLoading(true)
    try {
      const { email, name, phoneNumber } = data
      const newWorker = {
        email,
        name,
        phoneNumber: phoneNumber.replace(/[\D]/g, '')
      }
      if (worker) await updatePetshopWorker(authToken, newWorker, data.id)
      else await createPetshopWorker(authToken, newWorker)

      toast.success(
        `Colaborador ${worker ? 'atualizado' : 'criado'} com sucesso!`
      )
      navigate('/meus-colaboradores')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const [showRemoveAccessPopup, setShowRemoveAccessPopup] = useState(false)

  const renderButtons = () => {
    if (readOnly) {
      return
    } else if (worker) {
      return (
        <>
          <Button
            type='button'
            text='Remover Acesso Colaborador'
            classes='alert-button'
            onClick={() => setShowRemoveAccessPopup(true)}
          />
          <Button
            text='Salvar Colaborador'
            classes='primary-button'
            isLoading={isLoading}
          />
        </>
      )
    } else {
      return (
        <Button
          text='Adicionar Colaborador'
          classes='primary-button'
          isLoading={isLoading}
        />
      )
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmission)}
        className='worker-form'
      >
        <div className='worker-input-container'>
          <label htmlFor='name-input' className='worker-input-title'>
            Nome
          </label>
          <div>
            <Input
              type='text'
              placeholder='Nome Colaborador'
              isValid={errors?.name === undefined}
              {...register('name')}
              {...(readOnly && {
                disabled: true,
                classes: 'floating-input disabled-input'
              })}
            />
            <span
              className={errors?.name?.message ? 'add-client-field-error' : ''}
            >
              {errors?.name?.message}
            </span>
          </div>
        </div>
        <div className='worker-input-container'>
          <label htmlFor='phoneNumber-input' className='worker-input-title'>
            Telefone
          </label>
          <div>
            <Input
              type='text'
              placeholder='(99) 99999-9999'
              isValid={errors?.phoneNumber === undefined}
              {...register('phoneNumber')}
              {...(readOnly && {
                disabled: true,
                classes: 'floating-input disabled-input'
              })}
            />
            <span
              className={
                errors?.phoneNumber?.message ? 'add-client-field-error' : ''
              }
            >
              {errors?.phoneNumber?.message}
            </span>
          </div>
        </div>
        <div className='worker-input-container'>
          <label htmlFor='email-input' className='worker-input-title'>
            E-mail
          </label>
          <div>
            <Input
              type='text'
              placeholder='colaborador@email.com'
              isValid={errors?.email === undefined}
              {...register('email')}
              {...(readOnly && {
                disabled: true,
                classes: 'floating-input disabled-input'
              })}
            />
            <span
              className={errors?.email?.message ? 'add-client-field-error' : ''}
            >
              {errors?.email?.message}
            </span>
          </div>
        </div>
        <div className='worker-crud-buttons-container'>{renderButtons()}</div>
      </form>
      {showRemoveAccessPopup && (
        <WorkerRemoveAccessPopup
          closeFunction={() => setShowRemoveAccessPopup(false)}
          removeFucntion={() => {
            //TODO: remove worker access
          }}
          workerName={worker?.name}
        />
      )}
    </>
  )
}
