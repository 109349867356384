const AppConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
        supportPhoneNumber: '+5516936183738',
        salesPhoneNumber: '+5516936184347',
        pagarmePublicKey: 'pk_YPzKBO8I8VikJXL8'
      }
    : {
        supportPhoneNumber: '+5516936183738',
        salesPhoneNumber: '+5516936184347',
        pagarmePublicKey: 'pk_test_eLZVZNGizuznV6zR'
      }

export default AppConfig
