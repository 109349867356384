import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getPlans } from '../../services/api/apiCalls'
import { normalizePrice } from '../../services/utils/masks'
import {
  periodCycle,
  calculateDiscount
} from '../../services/utils/helpers/planHelper'
import { openWhatsappSales } from '../../services/utils/helpers/createWhatsappLink'

import './style.css'
import Button from '../../components/Button'
import { ReactComponent as RadioCheckIcon } from '../../assets/images/icons/radioCheck.svg'
import { ReactComponent as WhatsappIcon } from '../../assets/images/icons/whatsappWhite.svg'
import MenuHeader from '../../components/MenuHeader'
import SkeletonButton from '../../skeletonComponents/Button'

// plan features
const features = [
  'Controle de Clientes',
  'Controle de Serviços',
  'Agendamento de Horários Simplificado',
  'Aplicativo para o Cliente (Android e em breve iOS)',
  'Cliente poder agendar pelo App',
  'Cliente poder pagar pelo App',
  // 'Cliente acompanhar o atendimento pelo App',
  // 'Notificações para o Cliente e solicitações de confirmação do serviço',
  'Possibilidade de adicionar taxa em caso de cancelamento do serviço'
]

export default function PlansPage ({ ...props }) {
  const navigate = useNavigate()

  const [plans, setPlans] = useState([])

  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    async function getAllPlans () {
      setIsPageLoading(true)
      try {
        const response = await getPlans()
        setPlans(response.data)
        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getAllPlans()
  }, [])

  const renderPlanFeatures = () => {
    return features?.map(feature => (
      <span key={feature} className='plan-feature-container'>
        <RadioCheckIcon />
        <p>{feature}</p>
      </span>
    ))
  }

  const calculeTotalValue = (price, period) => {
    const installments = periodCycle[period].value
    return normalizePrice(parseInt(installments) * price)
  }

  const renderPlanPeriods = (periods, plan) => {
    const monthlyPrice = periods?.find(
      period => period.periodCycle === 'monthly'
    )?.price
    return periods?.map((period, index) => {
      const monthlyPlan = period.periodCycle === 'monthly'
      const periodType = periodCycle[period.periodCycle]
      return (
        <div
          className={`modality-container ${
            index % 2 === 0 ? 'gray-background' : 'white-background'
          }`}
          key={period.id}
        >
          <h3 className='modality-title'>
            {plan.name} - {periodType.label}
          </h3>
          <p className='modality-price'>
            {monthlyPlan
              ? `${normalizePrice(period.price)}/mês`
              : `${periodType.value}x de ${normalizePrice(period.price)}`}
          </p>
          {!monthlyPlan && (
            <p className='modality-description'>
              Um total de {calculeTotalValue(period.price, period.periodCycle)}{' '}
              -{' '}
              <strong>
                {calculateDiscount(monthlyPrice, period?.price)}% de desconto
              </strong>{' '}
              com relação ao plano mensal.
            </p>
          )}
          <Button
            text={`Assinar ${periodType.label}`}
            classes='action-button-tertiary modality-button'
            onClick={() => navigate('/pagamento', { state: { plan, period } })}
          />
        </div>
      )
    })
  }

  const renderPlans = () => {
    return plans?.map(plan => {
      return (
        <div className='plan-container' key={plan.id}>
          <div className='plan-details-container'>
            <h3 className='plan-details-title'>{plan.name}</h3>
            <div className='plan-features'>{renderPlanFeatures()}</div>
            <p className='plan-options-message'>
              Escolha a <strong>melhor</strong> opção para você:
            </p>
          </div>
          {renderPlanPeriods(plan.periods, plan)}
        </div>
      )
    })
  }

  const renderSkeleton = () => {
    return (
      <div className='plans-page-content' style={{ flexGrow: '1' }}>
        <SkeletonButton
          height={'calc(100vh - 80px - min(7vh, 44px))'}
          style={{ borderRadius: '10px' }}
        />
      </div>
    )
  }

  return (
    <div className='plans-page-container'>
      <div className='plans-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Planos'
          buttonText='Voltar'
          navigateTo='-1'
        />
        {isPageLoading ? (
          renderSkeleton()
        ) : (
          <div className='plans-page-content'>
            {plans && renderPlans()}
            <div className='doubts-container gray-background'>
              <p className='doubts-title'>
                Tem alguma dúvida? <strong>Tudo bem!</strong>
              </p>
              <p className='doubts-message'>
                Clique no botão abaixo e converse com um de nossos consultores:
              </p>
              <Button
                text='Conversar com Consultor'
                classes='primary-button contact-consultant-button'
                onClick={() => openWhatsappSales()}
              >
                <WhatsappIcon />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
