import { endOfWeek, startOfWeek, add, startOfDay, set } from 'date-fns'

// given a number (n) creates an array with the next n weeks
const weeksBuilder = maxNumberOfWeeks => {
  const today = startOfDay(new Date())
  let todayUtc = set(today, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    timeZone: 'UTC'
  })
  const weeks = []
  for (let i = 0; i < maxNumberOfWeeks; i++) {
    const start = startOfWeek(todayUtc, { weekStartsOn: 1 })
    const end = startOfDay(endOfWeek(todayUtc, { weekStartsOn: 1 }))
    weeks.push({ startOfWeek: start, endOfWeek: end })
    todayUtc = add(end, { days: 1 })
  }
  return weeks
}

export { weeksBuilder }
