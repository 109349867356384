import React, { useState, useEffect, useRef, useMemo } from 'react'
import { number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'

import './style.css'
import { ReactComponent as AddIcon } from '../../../assets/images/icons/add.svg'
import { ReactComponent as ChangeIcon } from '../../../assets/images/icons/change.svg'
import { ReactComponent as RadioCheckIcon } from '../../../assets/images/icons/radioCheck.svg'
import { ReactComponent as RadioUncheckIcon } from '../../../assets/images/icons/radioUncheck.svg'
import Card from '../../../components/Card'
import MenuHeader from '../../../components/MenuHeader'
import CustomSelect from '../../../components/CustomSelect'
import Button from '../../../components/Button'
import SelectServicesPopup from './SelectServicesPopup'
import TwoStepsRemove from '../../../components/TwoStepsRemove'
import SelectClientPetPopup from './SelectClientPetPopup'
import { petsToString } from '../../../services/utils/helpers/petsToString'

import FreeSchedules from '../../../components/FreeSchedules'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import {
  getPetshopClientList,
  getPetshopServices
} from '../../../services/api/apiCalls'
import { toast } from 'react-toastify'
import {
  furOptions,
  sizeOptions
} from '../../../services/utils/helpers/petOptions'
import SkeletonText from '../../../skeletonComponents/Text'
import SkeletonButton from '../../../skeletonComponents/Button'
import { renderComponentNTimes } from '../../../services/utils/helpers/renderComponentNTimes'
import { selectWorkerInfo } from '../../../features/worker/workerSlice'

const validationSchema = object({
  bodySize: object({
    label: string().required('Porte do Pet é um campo obrigatório.'),
    value: string().required('Porte do Pet é um campo obrigatório.')
  }).nullable(),
  furSize: object({
    label: string().required('Pelo do Pet é um campo obrigatório.'),
    value: string().required('Pelo do Pet é um campo obrigatório.')
  }).nullable(),
  service: object({
    label: string().required('Serviço é um campo obrigatório.'),
    value: number().required('Serviço é um campo obrigatório.')
  }).nullable(),
  clientPet: string().required('Pet/Cliente é um campo obrigatório.'),
  appointmentTime: object({
    periodStart: string().required('Nenhum horário foi selecionado.'),
    periodEnd: string().required('Nenhum horário foi selecionado.'),
    selectedDay: string().required('Nenhum horário foi selecionado.'),
    workerId: string().nullable()
  }).required('Nenhum horário foi selecionado.')
})

export default function ConsultFreeSchedule ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const workerInfo = useSelector(selectWorkerInfo)

  const navigate = useNavigate()
  const location = useLocation()

  const [serviceOptions, setServiceOptions] = useState([])
  const [clientsList, setClientsList] = useState([])

  const [serviceDetails, setServicesDetails] = useState([])

  const [freeSchedule, setFreeSchedule] = useState([])

  const [clientType, setClientType] = useState()

  //stores selected client and pet information
  const [selectedClientPet, setSelectedClientPet] = useState()

  // controls skeleton display
  const [isPageLoading, setIsPageLoading] = useState(true)

  const {
    control,
    setValue,
    resetField,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      defaultValues: useMemo(() => {
        return location?.state?.data
      }, [location])
    }
  })

  useEffect(() => {
    reset(location?.state?.data)
    setSelectedClientPet(location?.state?.selectedClientPet)
  }, [location, reset])

  const setClientPet = ({ client, pet }) => {
    setValue(
      'furSize',
      furOptions.filter(
        fur => fur.value.toLowerCase() === pet.furSize.toLowerCase()
      )[0]
    )
    setValue(
      'bodySize',
      sizeOptions.filter(
        size => size.value.toLowerCase() === pet.bodySize.toLowerCase()
      )[0]
    )
    const clientPetText = `${client.name}: ${petsToString([pet])}`
    setValue('clientPet', clientPetText)
    setSelectedClientPet({ client, pet })
  }

  useEffect(() => {
    async function getServices () {
      if (!authToken) return

      setIsPageLoading(true)

      if (location.state?.clientsList) {
        const { freeSchedules, clientsList, serviceDetails } = location.state
        setServiceOptions(location.state.serviceOptions)
        setClientsList(clientsList)
        setFreeSchedule(freeSchedules)
        setServicesDetails(serviceDetails)
        setIsPageLoading(false)
        return
      }

      try {
        // Temporary. Considering that a worker has only one petshop
        const petshopId = workerInfo?.petshops[0]?.id

        const servicesResponse = await getPetshopServices(authToken, petshopId)
        const clientsResponse = await getPetshopClientList(authToken, petshopId)

        const services = servicesResponse.data
        const servicesOptions = services.map(service => {
          return { label: service.name, value: service.id }
        })
        setServiceOptions(servicesOptions)
        setServicesDetails(services)

        const clients = clientsResponse.data
        setClientsList(clients)

        // having the client attribute in the route state means that it came from the customer registration screen
        if (location.state?.client) {
          const state = location.state
          setClientType('registeredClient')
          const pet = state.client.pets[0]
          const client = clients.find(c => c.id === state.client.user.id)
          setClientPet({ client, pet })
          setValue('service', state.service)
        }

        setIsPageLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    getServices()
    // eslint-disable-next-line
  }, [authToken, location.state, setValue])

  //create ref to set card focus in client pet selection popup
  const currentRef = useRef()

  const [showSelectServicePopup, setShowSelectServicePopup] = useState(false)
  const [showSelectClientPetPopup, setShowSelectClientPetPopup] = useState(
    false
  )

  //shows free times for booking
  const [consultSchedule, setConsultSchedule] = useState(false)

  const renderService = () => {
    return (
      <div className='agenda-selected-service'>
        <p className='agenda-selected-service-name'>{service.label}</p>
        <TwoStepsRemove
          removeFunction={() => setValue('service')}
          icon='removeRounded'
        />
      </div>
    )
  }

  const petClient = watch('clientPet')

  //store the selected schedule
  const selectedSchedule = watch('appointmentTime')

  //controls the client's pet selection card icon
  const [clientPetIcon, setClientPetIcon] = useState('add')

  const handleFormSubmit = data => {
    navigate('/agendamento', {
      state: {
        data,
        selectedClientPet,
        freeSchedules: freeSchedule,
        serviceOptions,
        clientsList,
        bundle: location.state?.bundle,
        serviceDetails
      }
    })
  }

  const furSize = watch('furSize')
  const bodySize = watch('bodySize')
  const service = watch('service')

  useEffect(() => {
    if (furSize) {
      setValue('appointmentTime', {})
      setConsultSchedule(false)
      setFreeSchedule([])
    }
  }, [furSize, setValue])

  useEffect(() => {
    if (bodySize) {
      setValue('appointmentTime', {})
      setConsultSchedule(false)
      setFreeSchedule([])
    }
  }, [bodySize, setValue])

  useEffect(() => {
    if (service) {
      setValue('appointmentTime', {})
      setConsultSchedule(false)
      setFreeSchedule([])
    }
  }, [service, setValue])

  const renderSkeleton = () => {
    return (
      <div
        className='consult-schedule-content'
        style={{ padding: '0 min(8vw, 64px)' }}
      >
        <SkeletonText
          height={'17px'}
          width='min(60vw, 384px)'
          svgWidth='min(60vw, 384px)'
          style={{
            margin: '0 auto',
            borderRadius: '8px',
            marginBottom: '10px'
          }}
        />
        {renderComponentNTimes(2, SkeletonButton, {
          height: '40px',
          width: '80%',
          style: {
            margin: '0 auto',
            borderRadius: '10px'
          }
        })}
      </div>
    )
  }

  const selectClientTypeCard = (text, onPress, type) => {
    return (
      <Card onClick={onPress}>
        <div className='client-type-card-content'>
          {type === clientType ? <RadioCheckIcon /> : <RadioUncheckIcon />}
          <p>{text}</p>
        </div>
      </Card>
    )
  }

  const renderPageContent = () => (
    <>
      <div className='select-client-type'>
        <p className='select-client-text'>
          Selecione se o agendamento é para um cliente novo ou para um já
          cadastrado:
        </p>
        <div className='client-types-cards'>
          {selectClientTypeCard(
            'Novo Cliente',
            () => {
              setClientType('newClient')
              setConsultSchedule(false)
            },
            'newClient'
          )}
          {selectClientTypeCard(
            'Cliente Cadastrado',
            () => {
              setClientType('registeredClient')
              setConsultSchedule(false)
            },
            'registeredClient'
          )}
        </div>
      </div>
      {clientType && (
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className='consult-schedule-content'
        >
          <div className='consult-service-card-container'>
            <Card classes='consult-schedule-card'>
              <div className='consult-schedule-card-header'>
                <h3>Serviço</h3>
                <AddIcon onClick={() => setShowSelectServicePopup(true)} />
              </div>
              {service?.value ? (
                <div className='agenda-selected-services'>
                  {renderService()}
                </div>
              ) : (
                <div className='consult-schedule-card-content'>
                  <p className='consult-schedule-empty-content'>
                    Sem serviços adicionados.
                  </p>
                </div>
              )}
            </Card>
            <span
              className={
                errors?.service?.label?.message ? 'consult-field-error' : ''
              }
            >
              {errors?.service?.label?.message}
            </span>
          </div>
          {clientType === 'registeredClient' && (
            <div className='consult-schedule-card-container'>
              <Card classes='consult-schedule-card'>
                <div className='consult-schedule-card-header'>
                  <h3>Pet/Cliente</h3>
                  {clientPetIcon === 'add' ? (
                    <AddIcon
                      onClick={() => setShowSelectClientPetPopup(true)}
                    />
                  ) : (
                    <ChangeIcon
                      onClick={() => setShowSelectClientPetPopup(true)}
                    />
                  )}
                </div>
                <div className='consult-schedule-card-content'>
                  {petClient ? (
                    <div className='client-pet-container'>
                      <p className='consult-schedule-client-pet'>{petClient}</p>
                      <TwoStepsRemove
                        removeFunction={() => {
                          setValue('clientPet', undefined)
                          setClientPetIcon('add')
                          setSelectedClientPet()
                        }}
                        icon='removeRounded'
                      />
                    </div>
                  ) : (
                    <p className='consult-schedule-empty-content'>
                      Sem Pet adicionado.
                    </p>
                  )}
                </div>
              </Card>
              <span
                className={
                  errors?.clientPet?.message ? 'consult-field-error' : ''
                }
              >
                {!petClient && errors?.clientPet?.message}
              </span>
            </div>
          )}
          {(clientType === 'newClient' || selectedClientPet) && (
            <>
              <div className='consult-schedule-input-container'>
                <span className='consult-schedule-input-label'>Porte</span>
                <Controller
                  name='bodySize'
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      field={field}
                      options={sizeOptions}
                      placeholder='Selecionar Porte'
                      {...(selectedClientPet &&
                        clientType === 'registeredClient' && {
                          isDisabled: true
                        })}
                    />
                  )}
                />
                <span
                  className={
                    errors?.bodySize?.label?.message
                      ? 'consult-field-error'
                      : ''
                  }
                >
                  {!bodySize && errors?.bodySize?.label?.message}
                </span>
              </div>
              <div className='consult-schedule-input-container'>
                <span className='consult-schedule-input-label'>Pelo</span>
                <Controller
                  name='furSize'
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      field={field}
                      options={furOptions}
                      placeholder='Selecionar Pelo'
                      {...(selectedClientPet &&
                        clientType === 'registeredClient' && {
                          isDisabled: true
                        })}
                    />
                  )}
                />
                <span
                  className={
                    errors?.furSize?.label?.message ? 'consult-field-error' : ''
                  }
                >
                  {!furSize && errors?.furSize?.label?.message}
                </span>
              </div>
            </>
          )}
          {!consultSchedule && (
            <Button
              type='button'
              classes='action-button-tertiary consult-schedule-button'
              text='Consultar horários livres'
              onClick={() => {
                if (furSize && bodySize && service) {
                  setConsultSchedule(current => !current)
                } else {
                  toast.error(
                    'Preencha todos os campos para poder visualizar os horários disponíveis!'
                  )
                }
              }}
            />
          )}
          {consultSchedule && (
            <div id='schedules'>
              <FreeSchedules
                selectedClientPet={selectedClientPet}
                selectedSchedule={selectedSchedule}
                setValue={setValue}
                resetField={resetField}
                field='appointmentTime'
                serviceId={service?.value}
                furSize={furSize?.value}
                bodySize={bodySize?.value}
                freeSchedule={freeSchedule}
                setFreeSchedule={setFreeSchedule}
                resetConsultSchedule={() => setConsultSchedule(false)}
              />
              <span
                className={
                  errors?.appointmentTime?.periodEnd?.message
                    ? 'consult-field-error'
                    : ''
                }
              >
                {!selectedSchedule?.periodEnd &&
                  errors?.appointmentTime?.periodEnd?.message}
              </span>
              <Button
                text={
                  clientType === 'registeredClient'
                    ? 'Continuar para agendamento'
                    : 'Adicionar Novo Cliente'
                }
                classes='primary-button continue-scheduling-button'
                {...(clientType === 'newClient' && {
                  onClick: () => {
                    navigate('/meus-clientes/novo-cliente', {
                      state: {
                        scheduleInfo: {
                          service
                        }
                      }
                    })
                  }
                })}
                data-html2canvas-ignore
              />
            </div>
          )}
        </form>
      )}
    </>
  )

  return (
    <div className='consult-schedule-page-container'>
      <div className='consult-schedule-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Agenda'
          buttonText='Menu'
          navigateTo='menu'
        />
        {isPageLoading ? renderSkeleton() : renderPageContent()}
        {showSelectServicePopup && (
          <SelectServicesPopup
            closeFunction={() => setShowSelectServicePopup(false)}
            services={serviceOptions}
            setValue={setValue}
          />
        )}
        {showSelectClientPetPopup && (
          <SelectClientPetPopup
            closeFunction={() => setShowSelectClientPetPopup(false)}
            setClientPet={setClientPet}
            ref={currentRef}
            setClientPetIcon={setClientPetIcon}
            clientsList={clientsList}
          />
        )}
      </div>
    </div>
  )
}
