import html2canvas from 'html2canvas'

const generateImage = (divId, filename = 'horarios') => {
  html2canvas(document.querySelector(`#${divId}`), {
    backgroundColor: '#F5F5F5',
    windowWidth: 640
  }).then(async canvas => {
    const dataUrl = canvas.toDataURL()

    // Convert dataUrl into blob using browser fetch API
    const blob = await (await fetch(dataUrl)).blob()

    // Create file form the blob
    const image = new File([blob], 'canvas.png', { type: blob.type })

    // Check if the device is able to share these files then open share dialog
    if (navigator.canShare && navigator.canShare({ files: [image] })) {
      try {
        await navigator.share({
          files: [image],
          title: 'Compartilhar Hórarios'
        })
        return
      } catch (error) {
        alert('Falha ao compartilhar, será feito o download da imagem!')
      }
    } else {
      alert(
        'O dispostivo não permite o compartilhamento, será feito o download da imagem!'
      )
    }

    const link = document.createElement('a')
    link.download = `${filename}.png`
    link.href = canvas.toDataURL()
    link.click()
  })
}

export { generateImage }
