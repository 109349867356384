import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  selectCurrentAuth,
  selectCurrentToken
} from '../../features/auth/authSlice'
import { stepsState } from '../../services/utils/helpers/stepsState'
import { selectWorkerInfo } from '../../features/worker/workerSlice'

import './style.css'
import MenuHeader from '../../components/MenuHeader'
import AppointmentDropdown from '../../components/AppointmentDropdown'
import StepComponent from '../../components/StepComponent'

export default function AppointmentStepsPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const auth = useSelector(selectCurrentAuth)
  const workerInfo = useSelector(selectWorkerInfo)

  const location = useLocation()
  const navigate = useNavigate()
  const [appointment, setAppointment] = useState()
  const [steps, setSteps] = useState()

  useEffect(() => {
    if (location.state) {
      const { appointment, steps } = location.state
      setAppointment(appointment)
      setSteps(stepsState(steps))
    }
  }, [location.state])

  const renderSteps = () =>
    steps?.map((stepInfo, index) => {
      const { step, state, previousStepId } = stepInfo
      return (
        <StepComponent
          setRefreshNextAppointments={() => navigate('/proximos-atendimentos')}
          appointmentId={appointment?.id}
          authToken={authToken}
          step={step}
          stepName={step.serviceStepName}
          isFirstStep={index === 0}
          state={state}
          key={step.serviceStepName}
          stepIndex={index}
          setSteps={setSteps}
          previousStepId={previousStepId}
          auth={auth}
          petshopId={workerInfo?.petshops[0]?.id}
        />
      )
    })

  return (
    <div className='appointment-steps-page-container'>
      <div className='appointment-steps-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Atendimento Atual'
          buttonText='Voltar'
          navigateTo='proximos-atendimentos'
          navigateOptions={{ state: location.state }}
        />
        <div className='appointment-steps-content'>
          {appointment && <AppointmentDropdown appointment={appointment} />}
          <div className='appointment-steps'>
            <h3 className='appointment-steps-title'>Etapas</h3>
            {steps && renderSteps()}
          </div>
        </div>
      </div>
    </div>
  )
}
