import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './style.css'
import { ReactComponent as WhatsappIcon } from '../../../../assets/images/icons/whatsapp.svg'
import Card from '../../../../components/Card'
import Button from '../../../../components/Button'
import { petsToString } from '../../../../services/utils/helpers/petsToString'
import { addressToString } from '../../../../services/utils/helpers/addressToString'
import { createWhatsappLink } from '../../../../services/utils/helpers/createWhatsappLink'
import MapsOrWazeSelectionPopup from '../../../../components/MapsOrWazeSelectionPopup'
import {
  normalizePhoneNumber,
  normalizePrice
} from '../../../../services/utils/masks'

export default function ClientCard ({ client, readOnly = false, ...props }) {
  const [showRedirectPopup, setShowRedirectPopup] = useState(false)

  const navigate = useNavigate()

  return (
    <>
      <Card classes='client-card'>
        <div className='client-card-header'>
          <h3 className='client-card-title'>{client.name}</h3>
          {!readOnly && (
            <Button
              classes='action-button-tertiary client-edit-button'
              text='Editar'
              onClick={() =>
                navigate(`editar-cliente/${client.id}`, { state: { client } })
              }
            />
          )}
        </div>
        <div className='client-card-container'>
          <p className='client-info client-info-icon'>
            <strong>Telefone:</strong>
            <a
              href={createWhatsappLink(client.phoneNumber)}
              target='_blank'
              rel='noreferrer'
            >
              <u>
                {normalizePhoneNumber(client.phoneNumber)} <WhatsappIcon />
              </u>
            </a>
          </p>
          <p className='client-info'>
            <strong>Pet(s): </strong>
            {petsToString(client.pets)}
          </p>
          <p className='client-info'>
            <strong>Endereço: </strong>
            {client.address?.[0] ? (
              <>
                {addressToString(client.address[0])} -{' '}
                <u onClick={() => setShowRedirectPopup(true)}>
                  Clique aqui para calcular rota
                </u>
              </>
            ) : (
              'Sem endereço cadastrado.'
            )}
          </p>
          <p className='client-info'>
            <strong>E-mail: </strong>
            {client.email}{' '}
          </p>
          <p className='client-info'>
            <strong>Leva e traz: </strong>
            {client.address?.[0]?.deliveryValue &&
            client.address?.[0]?.deliveryTime ? (
              <>
                {normalizePrice(client.address?.[0]?.deliveryValue)} -{' '}
                {client.address?.[0]?.deliveryTime} minutos
              </>
            ) : (
              'Leva e traz não cadastrado.'
            )}
          </p>
        </div>
      </Card>
      {showRedirectPopup && (
        <MapsOrWazeSelectionPopup
          address={client?.address}
          closeFunction={() => setShowRedirectPopup(false)}
        />
      )}
    </>
  )
}
