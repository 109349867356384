import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import './style.css'
import MenuHeader from '../../../../components/MenuHeader'
import PackageForm from '../PackageForm'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../../features/auth/authSlice'
import { updatePetshopBundle } from '../../../../services/api/apiCalls'
import { toast } from 'react-toastify'

export default function EditPackagePage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)

  const location = useLocation()
  const navigate = useNavigate()

  const [servicePackage, setServicePackage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (location?.state?.bundle) {
      setServicePackage(location.state.bundle)
    } else {
      navigate('/meus-servicos/meus-pacotes')
    }
  }, [location?.state, navigate])

  const handleEditPackage = async data => {
    setIsLoading(true)
    try {
      const bundle = {}
      const { id, discountPercentage, maxInstallments, serviceAmount } = data
      bundle.discountPercentage = parseInt(
        discountPercentage.replace(/[\D]/g, '')
      )
      bundle.maxInstallments = parseInt(maxInstallments.value)
      bundle.serviceAmount = serviceAmount

      await updatePetshopBundle(authToken, bundle, id)
      toast.success('Pacote atualizado com sucesso!')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='edit-package-page-container'>
      <div className='edit-package-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Editar Pacote'
          buttonText='Voltar'
          navigateTo='meus-servicos/meus-pacotes'
        />
        <div className='edit-package-content'>
          <PackageForm
            formSubmitFunction={data => handleEditPackage(data)}
            defaultFormValues={servicePackage}
            action='edit'
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
