import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  calculateDiscount,
  calculeTotalValue,
  periodCycle
} from '../../services/utils/helpers/planHelper'
import { normalizeCNPJ, normalizePrice } from '../../services/utils/masks'
import { selectPetshopInfo } from '../../features/petshop/petshopSlice'
import { createCardToken } from '../../services/externalAPI/pagarme'
import {
  createPaymentCustomer,
  createCustomerCard,
  getCustomerCards,
  createSubscription
} from '../../services/api/apiCalls'
import { selectCurrentToken } from '../../features/auth/authSlice'
import { renderComponentNTimes } from '../../services/utils/helpers/renderComponentNTimes'

import './style.css'
import MenuHeader from '../../components/MenuHeader'
import Card from '../../components/Card'
import { addressToString } from '../../services/utils/helpers/addressToString'
import Button from '../../components/Button'
import CreditCardForm from '../../components/CreditCardForm'
import RadioInput from '../../components/RadioInput'
import ConfirmBuyPopup from './ConfirmBuyPopup'
import SkeletonText from '../../skeletonComponents/Text'
import SkeletonCard from '../../skeletonComponents/Card'

export default function PaymentePage ({ ...props }) {
  const petshop = useSelector(selectPetshopInfo)
  const authToken = useSelector(selectCurrentToken)

  const location = useLocation()
  const navigate = useNavigate()

  const { period, plan } = location?.state

  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState()
  const [paymentMethodController, setPaymentMethodController] = useState(
    'cards'
  )
  const [popupState, setPopupState] = useState('not-confirmed')

  const [showConfirmBuyPopup, setShowConfirmBuyPopup] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isCardButtonLoading, setIsCardButtonLoading] = useState(false)
  const [
    isConfirmationButtonLoading,
    setIsConfirmationButtonLoading
  ] = useState(false)

  useEffect(() => {
    if (location.state === null) {
      navigate('/planos')
    }
  }, [location, navigate])

  useEffect(() => {
    async function getCards () {
      setIsLoading(true)
      getCustomerCards(authToken)
        .then(res => {
          setCards(res.data)
        })
        .finally(() => setIsLoading(false))
    }
    getCards()
  }, [authToken])

  const createUserSubscription = async () => {
    setIsConfirmationButtonLoading(true)
    await createSubscription(authToken, {
      cardId: cards[selectedCard]?.id,
      periodId: period?.id
    })
      .then(res => {
        setPopupState('confirmed')
      })
      .finally(() => setIsConfirmationButtonLoading(false))
  }

  const renderPlanDetailsCard = () => {
    const monthlyPlan = period?.periodCycle === 'monthly'
    const periodType = periodCycle[period?.periodCycle]
    const monthlyPrice = plan?.periods?.find(
      period => period?.periodCycle === 'monthly'
    )?.price

    return (
      <Card classes='plan-details-card'>
        <h3 className='payment-page-card-title'>
          {plan?.name} - {periodType?.label}
        </h3>
        <p className='plan-details-price'>
          {monthlyPlan
            ? `${normalizePrice(period.price)}/mês`
            : `${periodType.value}x de ${normalizePrice(period.price)}`}
        </p>
        {!monthlyPlan && (
          <p className='plan-details-description'>
            Um total de {calculeTotalValue(period?.price, period?.periodCycle)}{' '}
            -{' '}
            <strong>
              {calculateDiscount(monthlyPrice, period?.price)}% de desconto
            </strong>{' '}
            com relação ao plano mensal.
          </p>
        )}
      </Card>
    )
  }

  const renderClientInformation = () => {
    return (
      <Card classes='client-details-card'>
        <h3 className='payment-page-card-title'>Dados de Pagamento</h3>
        <p className='payment-client-detail'>
          <strong>Nome:</strong> {petshop?.name}
        </p>
        <p className='payment-client-detail'>
          <strong>E-mail:</strong> {petshop?.email}
        </p>
        <p className='payment-client-detail'>
          <strong>CNPJ:</strong> {normalizeCNPJ(petshop?.cnpj)}
        </p>
        <p className='payment-client-detail'>
          <strong>Endereço:</strong>{' '}
          {addressToString(petshop?.address && petshop.address[0])}
        </p>
      </Card>
    )
  }

  const renderCards = () => {
    if (cards.length === 0) {
      return (
        <>
          <p className='payment-method-description'>
            Clique no botão abaixo para adicionar um cartão e continuar com a
            compra:
          </p>
        </>
      )
    }
    return (
      <>
        <p className='select-card-text'>
          Selecione um cartão existente ou adicione um novo:
        </p>
        <div className='cards-container'>
          {cards.map((card, index) => {
            return (
              <Card
                key={index}
                classes={
                  'payment-card-container ' +
                  (index === selectedCard ? 'payment-card-selected' : '')
                }
              >
                <RadioInput
                  option={`**** ${card.last4}`}
                  className='card-radio'
                  id={index}
                  currentValue={selectedCard}
                  setValue={setSelectedCard}
                />
                <p className='card-validity'>
                  {card.expMonth}/{card.expYear?.toString().slice(2, 4)}
                </p>
              </Card>
            )
          })}
        </div>
      </>
    )
  }

  const onSubmitAction = async cardData => {
    setIsCardButtonLoading(true)
    try {
      const createdCardTokenResponse = await createCardToken(cardData)

      const createdCustomerCard = await createCustomerCard(authToken, {
        cardToken: createdCardTokenResponse?.id
      })

      if (!createdCustomerCard.data?.cardAlreadyExists) {
        setCards(curr => [...curr, createdCustomerCard.data])
      }
      setSelectedCard(cards?.length)
      setPaymentMethodController('cards')
    } catch (err) {
    } finally {
      setIsCardButtonLoading(false)
    }
  }

  const renderPaymentMethod = () => {
    return (
      <Card classes='payment-method-card'>
        <h3 className='payment-page-card-title'>Método de Pagamento</h3>
        {paymentMethodController === 'cards' ? (
          <>
            {renderCards()}
            <Button
              text={
                cards?.length === 0 ? 'Adicionar Dados' : 'Adicionar Cartão'
              }
              classes='action-button-tertiary payment-add-data-button'
              isLoading={isCardButtonLoading}
              onClick={() => {
                if (cards?.length > 0) {
                  setPaymentMethodController('add-card')
                } else {
                  setIsCardButtonLoading(true)
                  createPaymentCustomer(authToken)
                    .then(() => setPaymentMethodController('add-card'))
                    .finally(() => setIsCardButtonLoading(false))
                }
              }}
            />
          </>
        ) : (
          <>
            <CreditCardForm
              isLoading={isCardButtonLoading}
              onSubmitAction={data => onSubmitAction(data)}
            />
            <Button
              text='Cancelar'
              classes='alert-button add-card-cancel-button'
              onClick={() => setPaymentMethodController('cards')}
            />
          </>
        )}
      </Card>
    )
  }

  const renderSkeleton = () => {
    return (
      <>
        <SkeletonText
          height={'20px'}
          width='80%'
          rx='8'
          ry='8'
          x='10%'
          style={{ borderRadius: '8px', margin: '12px auto' }}
        />
        <div>
          {renderComponentNTimes(3, SkeletonCard, {
            style: { aspectRatio: 10, borderRadius: '10px', marginBottom: 16 }
          })}
        </div>
      </>
    )
  }

  return (
    <div className='payment-page-container'>
      <div className='payment-page-inner-container'>
        <MenuHeader
          classes='floating-left'
          title='Pagamento'
          buttonText='Voltar'
          navigateTo='-1'
        />

        <div className='payment-page-content'>
          {isLoading ? (
            renderSkeleton()
          ) : (
            <>
              <h2 className='payment-page-title'>Plano Escolhido</h2>
              {renderPlanDetailsCard()}
              {renderClientInformation()}
              {renderPaymentMethod()}
              <Button
                text='Finalizar Compra'
                classes={`primary-button ${
                  selectedCard !== undefined ? '' : 'disabled-payment-button'
                }`}
                {...(selectedCard !== undefined && {
                  onClick: () => setShowConfirmBuyPopup(true)
                })}
              />
            </>
          )}
        </div>
      </div>
      {showConfirmBuyPopup && (
        <ConfirmBuyPopup
          planDetails={location?.state}
          closeFunction={() => setShowConfirmBuyPopup(false)}
          popupState={popupState}
          setPopupState={setPopupState}
          createSubscription={createUserSubscription}
          isConfirmationButtonLoading={isConfirmationButtonLoading}
        />
      )}
    </div>
  )
}
