import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { add, startOfDay, isAfter, isSameDay } from 'date-fns'
import { object, string, array, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'

import './style.css'
import { ReactComponent as BackwardIcon } from '../../../assets/images/icons/backward.svg'
import { ReactComponent as ForwardIcon } from '../../../assets/images/icons/forward.svg'
import MenuHeader from '../../../components/MenuHeader'
import Button from '../../../components/Button'
import DayScheduleCard from '../../../components/DayScheduleCard'
import { dateToString } from '../../../services/utils/helpers/dateToString'
import ScheduleByWeekPopup from '../../../components/ScheduleByWeekPopup'
import ConfirmationPopup from '../../../components/ConfirmationPopup'
import { hoursToMinutes } from '../../../services/utils/helpers/hoursToMinutes'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../features/auth/authSlice'
import {
  updateResponsiblePersonNotAvailablePeriod,
  updateWorkerNotAvailablePeriod
} from '../../../services/api/apiCalls'
import { toast } from 'react-toastify'
import {
  selectPetshopMenuState,
  setMenuState
} from '../../../features/petshop/petshopSlice'
import { weeksBuilder } from '../../../services/utils/helpers/weeksBuilder'
import { startOfWeek } from 'date-fns'
import { hourValidator } from '../../../services/utils/validators/hourValidator'

const daysWeek = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo'
]

const maxNumberOfWeeks = 9

// validation scheme which is an object with the following structure { periods: [{ periodStart, periodEnd, id, day }, ...] }
const validationSchema = object({
  periods: array()
    .of(
      object({
        periodStart: string()
          .required('Campo Obrigatório')
          .test(
            'periodStartBeforePeriodEnd',
            'Horário inconsistente.',
            function (periodStart) {
              const { periodEnd } = this.parent
              const periodStartMinutes = hoursToMinutes(periodStart)
              const periodEndMinutes = hoursToMinutes(periodEnd)
              if (isNaN(periodEndMinutes)) return true

              return periodEndMinutes > periodStartMinutes
            }
          )
          .test('formatInconsistent', 'Horário inválido.', function (
            periodStart
          ) {
            return hourValidator(periodStart)
          }),
        periodEnd: string()
          .required('Campo Obrigatório')
          .test(
            'periodStartBeforePeriodEnd',
            'Horário inconsistente.',
            function (periodEnd) {
              const { periodStart } = this.parent
              const periodStartMinutes = hoursToMinutes(periodStart)
              const periodEndMinutes = hoursToMinutes(periodEnd)
              if (isNaN(periodStartMinutes)) return true

              return periodEndMinutes > periodStartMinutes
            }
          )
          .test('formatInconsistent', 'Horário inválido.', function (
            periodEnd
          ) {
            return hourValidator(periodEnd)
          }),
        id: number()
          .notRequired()
          .nullable(),
        day: string().required('Campo Obrigatório')
      })
    )
    .required('Insira ao menos um horário.')
})

// select unavailable times for future dates, starting at the first day of the week we are now
const getOncomingPeriods = periods => {
  const now = startOfDay(new Date())
  // const todayUtc = set(now, {
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  //   milliseconds: 0,
  //   timeZone: 'UTC'
  // })
  const firstDayOfWeek = startOfWeek(now, { weekStartsOn: 1 })

  return periods?.filter(period => {
    const periodDay = new Date(period.day.slice(0, 10).replace(/-/g, '/'))

    return (
      isAfter(periodDay, firstDayOfWeek) || isSameDay(periodDay, firstDayOfWeek)
    )
  })
}

export default function UnavailabilityPage ({ ...props }) {
  const authToken = useSelector(selectCurrentToken)
  const menuState = useSelector(selectPetshopMenuState)

  const { workerId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { periods: [] }
  })

  const { append, remove, fields } = useFieldArray({ control, name: 'periods' })

  const [isLoading, setIsLoading] = useState(false)
  const [notAvailableSchedule, setNotAvailableSchedule] = useState()

  const { isResponsiblePerson } = location.state

  useEffect(() => {
    if (!location?.state) {
      navigate('/horarios-atendimento')
    }
  }, [location, navigate])

  useEffect(() => {
    async function getNotAvailablePeriod () {
      if (!authToken) return

      if (location?.state?.notAvailablePeriod) {
        const oncomingPeriods = getOncomingPeriods(
          location?.state?.notAvailablePeriod
        )
        const periods = { periods: oncomingPeriods }
        reset(periods)

        setNotAvailableSchedule(location?.state?.notAvailablePeriod)
        return
      }
    }
    getNotAvailablePeriod()
  }, [authToken, reset, location?.state])

  const weeks = weeksBuilder(maxNumberOfWeeks)
  const [currentWeek, setCurrentWeek] = useState(0)

  const [showScheduleByWeekPopup, setShowScheduleByWeekPopup] = useState(false)
  const [changePagePopup, setChangePagePopup] = useState(false)

  const [deletedSchedules, setDeletedSchedules] = useState([])

  const getDay = day => {
    const { startOfWeek } = weeks[currentWeek]
    const utcDate = new Date(
      Date.UTC(
        startOfWeek.getFullYear(),
        startOfWeek.getMonth(),
        startOfWeek.getDate(),
        0,
        0,
        0,
        0
      )
    )
    const date = add(utcDate, { days: day })
    return date.toISOString()
  }

  const filterFunction = day => {
    const scheduleDay = getDay(day)
    return schedule => scheduleDay === schedule.day
  }

  const renderUnavailabilitySchedule = () => {
    return daysWeek.map((day, index) => (
      <DayScheduleCard
        title={day}
        key={currentWeek + day}
        className='mb-4'
        register={register}
        append={append}
        remove={remove}
        fields={fields}
        filterFunction={filterFunction(index)}
        setDeletedSchedules={setDeletedSchedules}
        week={currentWeek}
        dailySchedule={watch('periods')}
        date={getDay(index)}
        errors={errors}
        setValue={setValue}
      />
    ))
  }

  const handleSubmitForm = async formData => {
    const periods = []
    periods.push(...deletedSchedules)
    // add the flag is Updated if it is a period to be updated
    formData.periods.forEach(period => {
      const hasId = period.id || period.id === 0 ? true : false
      const schedule = { ...period, ...(hasId ? { isUpdate: true } : {}) }
      periods.push(schedule)
    })
    // blocks form submission if there are no changes
    if (periods.length === 0 || !isDirty) {
      toast.error('Informe ao menos um horário.')
      return
    }

    try {
      setIsLoading(true)
      const data = { periods }
      const response = isResponsiblePerson
        ? await updateResponsiblePersonNotAvailablePeriod(authToken, data)
        : await updateWorkerNotAvailablePeriod(authToken, data, workerId)

      const schedules = JSON.parse(response.request?.response)
      const newSchedules = getOncomingPeriods(schedules)
      reset({ periods: newSchedules })
      setNotAvailableSchedule(newSchedules)

      if (!menuState || menuState?.index < 1) {
        dispatch(setMenuState({ menuState: { state: 'services', index: 1 } }))
      }

      reset({ periods: newSchedules })
      toast.success('Horários atualizados com sucesso!')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='unavailabitity-schedules-page-container'>
      <div className='unavailabitity-schedules-page-inner-container'>
        {showScheduleByWeekPopup && (
          <ScheduleByWeekPopup
            closeSchedulePopup={() => setShowScheduleByWeekPopup(false)}
            daysWeek={daysWeek}
            name={location?.state?.name}
            availablePeriod={location?.state?.availablePeriod}
            notAvailablePeriod={notAvailableSchedule}
          />
        )}
        {changePagePopup && (
          <ConfirmationPopup
            closeFunction={() => setChangePagePopup(false)}
            confirmationAction={() => {
              const { availablePeriod, name } = location.state
              navigate(`/horarios-atendimento/${workerId}`, {
                state: {
                  availablePeriod,
                  notAvailablePeriod: notAvailableSchedule,
                  name
                }
              })
            }}
          >
            <p className='unavailability-change-popup-title'>
              <strong>Tem certeza</strong> que deseja continuar? <br /> <br />
              <span>
                Você inseriu algumas informações que ainda não foram salvas e{' '}
                <strong>serão perdidas</strong> caso continue.
              </span>
            </p>
          </ConfirmationPopup>
        )}
        <MenuHeader
          classes='menu-with-icon'
          title='Horários de Atendimento'
          buttonText='Voltar'
          navigateTo='horarios-atendimento'
          confirmToBack={isDirty}
        />
        <div className='unavailabitity-schedules-content'>
          <div className='unavailabitity-schedules-content-header'>
            <Button
              classes='action-button-tertiary'
              text='Geral'
              onClick={() => {
                if (isDirty) {
                  setChangePagePopup(true)
                  return
                }
                const { availablePeriod, name } = location.state
                navigate(`/horarios-atendimento/${workerId}`, {
                  state: {
                    availablePeriod,
                    notAvailablePeriod: notAvailableSchedule,
                    isResponsiblePerson,
                    name
                  }
                })
              }}
            />
            <h3>Indisponibilidade</h3>
          </div>

          <div className='unavailabitily-pagination'>
            {currentWeek !== 0 && (
              <BackwardIcon
                onClick={() => setCurrentWeek(current => current - 1)}
              />
            )}
            <span>
              Semana {dateToString(weeks[currentWeek].startOfWeek)} -{' '}
              {dateToString(weeks[currentWeek].endOfWeek)}
            </span>
            {currentWeek !== maxNumberOfWeeks - 1 && (
              <ForwardIcon
                onClick={() => {
                  setCurrentWeek(current => current + 1)
                }}
              />
            )}
          </div>
          <p className='unavailabitily-schedule-message'>
            Adicione os horários em que <strong>{location?.state?.name}</strong>{' '}
            não estará disponível nessa semana e salve no final da página.
            <br />
            <br />
            <i>
              (ex: das <strong>10h às 11h</strong> no dia 09/08 terá médico)
            </i>
          </p>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className='days-week'>{renderUnavailabilitySchedule()}</div>
            <div className='configure-schedules-buttons-container'>
              <Button
                classes='secondary-button'
                text='Ver Horários'
                type='button'
                onClick={() => setShowScheduleByWeekPopup(true)}
              />
              <Button
                classes='primary-button'
                text='Salvar'
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
